import React, { ComponentProps } from 'react';
import NumberFormat from 'react-number-format';
import {
  BaseTextField,
  BaseTextFieldProps,
} from 'src/components/TextField/BaseTextField';
import { GetCurrencyFormatter } from 'src/components/Formatters';

interface NumberFormatCustomProps {
  inputRef: (
    instance: ComponentProps<typeof NumberFormat>['getInputRef'] | null,
  ) => void;
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  value: string;
}

/**
 * This method is used to get the thousand
 * seperator and decimal seperator of a number depending
 * upon browser language
 * @param locale browser preferred language
 * @param number number to format
 */
function getLocaleThousandAndDecimalSeperators(locale: string, number: number) {
  const formatter = new Intl.NumberFormat(locale, { minimumFractionDigits: 2 });
  const formatterparts = formatter.formatToParts(number);
  return {
    decimal: formatterparts?.find((part) => part.type === 'decimal')?.value, // get the decimal seperator from formatted string
    group: formatterparts?.find((part) => part.type === 'group')?.value, // get the thousand seperator from formatted string
  };
}

const NumberFormatCustom = (props: NumberFormatCustomProps) => {
  const { inputRef, onChange, name, value, ...other } = props;

  const numberSeperators = getLocaleThousandAndDecimalSeperators(
    navigator.language,
    Number(value), // passing input value
  );

  return (
    <NumberFormat
      {...other}
      value={value}
      name={name}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={numberSeperators.group}
      decimalSeparator={numberSeperators.decimal}
      isNumericString
    />
  );
};

interface CurrencyTextFieldProps {
  currency: string;
}

export const CurrencyTextField: React.FC<
  CurrencyTextFieldProps & BaseTextFieldProps
> = ({ onChange, currency, ...textFieldProps }) => {
  const handleChange = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    if (onChange) {
      onChange(event);
    }
  };
  const formatter = GetCurrencyFormatter(currency);
  const formatterOptions = formatter.formatToParts();
  const prefix =
    formatterOptions.find((opt) => opt.type === 'currency')?.value || '$';

  const textFieldInputProps = {
    InputProps: {
      inputComponent: NumberFormatCustom as any, // casting as any so that mui TextField doesn't complain
    },
    inputProps: {
      prefix,
    },
  };

  return (
    <BaseTextField
      {...textFieldProps}
      {...textFieldInputProps}
      onChange={handleChange}
    />
  );
};
