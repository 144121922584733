import React from 'react';
import { shallowEqual } from 'react-redux';
import {
  GetQuickFilterTextParams,
  ColDef,
  CellClickedEvent,
} from '@ag-grid-community/core';
import moment from 'moment';
import { ActionButtonModal } from 'src/components/Modals';
import AgGrid, {
  ClientActionRenderer,
  UserCellRenderer,
  TextCellRenderer,
  CompanyCellRenderer,
  ChipCellRenderer,
  CustomFieldsRenderer,
  CustomFieldsRendererProps,
} from 'src/components/AgGrid';
import { LocaleDateFormatter } from 'src/components/Formatters';
import { TooltipContentForStatus } from 'src/components/AgGrid/Tooltips/UserTooltips';
import * as ReduxType from 'src/store/reduxTypes';
import {
  ClientTableRowModel,
  Client,
  CustomFieldOption,
  MultiSelectCustomField,
} from 'src/store/clients/types';
import {
  DEFAULT_DATE_FORMAT,
  CLIENT_DETAILS_PAGE,
  TablePropertiesEntityTypes,
  ModuleSettingsItem,
  VisibilityRuleType,
} from 'src/constants';
import {
  deleteClient,
  inviteClient,
  updateClientUser,
  updateClientCustomFields,
} from 'src/store/clients/actions';
import {
  userTableNameComparator,
  formatStatus,
  mapStatusToSeverity,
  customFieldsComparator,
} from 'src/utils/UserUtils';
import { companyCellComparator } from 'src/utils/CompanyUtils';
import DateUtils from 'src/utils/DateUtils';
import useActionRecord from 'src/hooks/useActionRecord';
import history from 'src/history';
import { CustomFieldValueData } from 'src/components/ClientDetailsPage/clientDetailsTypes';
import { TablePropertiesContext } from 'src/context/tableSettingsContext';
import { ColumnSettingsType } from 'src/constants/componentTypes/tableTypes';
import { TableColumnSettings } from 'src/components/TableSettings';
import { useGetDomainsQuery, useGetPortalConfigQuery } from 'src/services/api';
import { UrlUtils } from 'src/utils';
import { useCustomDomains } from 'src/hooks/useCustomDomains';
import { capitalize } from 'src/utils/StringUtils';
import { createStyles, makeStyles } from '@material-ui/core';
import { alertSnackbar } from 'src/store/ui/actions';
import { isCustomFieldUsedInAppVisibility } from 'src/utils/app_visibility';
import { useAppDispatch, useAppSelector } from 'src/hooks/useStore';

const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '& .ag-root-wrapper': {
        '& .ag-row': {
          cursor: (props: { isEdit: boolean }) =>
            props.isEdit ? 'default' : 'pointer',
        },
      },
    },
  }),
);

interface CrmTableProps {
  onClientEdit: (clientData: ClientTableRowModel) => void;
  loaded: boolean;
  loading: boolean;
  isColumnsLoading: boolean;
  columnSettingsAnchorEl: HTMLElement | null;
  onColumnSettingsClose: () => void;
  setCSVColumns: React.Dispatch<string[]>;
  moduleSettings: ModuleSettingsItem[];
  onInviteClient: (clientId: string, inviteLink: string) => void;
}

// // check columnSettings for column order property -- if it does not exist on either property do not sort otherwise sort ascending
const columnSortOrderComparator = (
  a: ColDef,
  b: ColDef,
  colSettings: Record<string, ColumnSettingsType>,
) => {
  if (Object.keys(colSettings).length === 0) {
    return 0;
  }

  if (!a.field) return 0;
  if (!b.field) return 0;

  const settingsA = colSettings[a.field];
  const settingsB = colSettings[b.field];

  if (!settingsA || !settingsB) {
    return 0;
  }
  if (settingsA.order === settingsB.order) return 0;
  return settingsA.order < settingsB.order ? -1 : 1;
};

const PLACEHOLDER_COMPANY_NAME = 'No company';
export const CrmTable: React.FC<CrmTableProps> = ({
  onClientEdit,
  loaded,
  loading,
  isColumnsLoading,
  columnSettingsAnchorEl,
  onColumnSettingsClose,
  moduleSettings,
  setCSVColumns,
  onInviteClient,
}) => {
  const [rowData, setRowData] = React.useState<
    Array<Record<string, any>> | undefined
  >(loaded && !isColumnsLoading ? [] : undefined);
  const { columnSettings } = React.useContext(TablePropertiesContext);
  const { data: portalConfigurations } = useGetPortalConfigQuery();
  const areCompaniesDisabled =
    portalConfigurations?.structFields?.disableCompanies;

  const dispatch = useAppDispatch();
  const {
    allCompanies,
    clients,
    portalCustomFields,
    searchKey,
    internalUsers,
  } = useAppSelector(
    (state: ReduxType.RootState) => ({
      allCompanies: state.clients.companies,
      clients: state.clients.activeClients,
      portalCustomFields: state.clients?.clientCustomFields,
      searchKey: state.ui.searchValue,
      internalUsers: state.users.internalUsers,
    }),
    shallowEqual,
  );
  const [isEdit, setIsEdit] = React.useState(false);
  const classes = useStyles({ isEdit });
  const handleDeleteClient = (clientId: string) => {
    const deletedUser = clients.find((client) => client.id === clientId);
    if (deletedUser) {
      dispatch(deleteClient(deletedUser));
    }
  };

  const handleInviteConfirm = (clientId: string) => {
    const currentClient = clients.find((client) => client.id === clientId);

    if (currentClient) {
      dispatch(inviteClient(clientId, currentClient.fields.givenName));
    }
  };

  const { currentData, handleClickAction, handleClose, handleConfirm } =
    useActionRecord(handleDeleteClient, 'userId');

  const { currentData: inviteUser, handleClickAction: handleClickInviteBtn } =
    useActionRecord(handleInviteConfirm, 'userId');

  const { isLoading: isLoadingDomains } = useGetDomainsQuery();
  const { basePortalDomain } = useCustomDomains();

  // this handles open invite modal
  // when user clicks on invite button
  React.useEffect(() => {
    if (inviteUser && inviteUser.id && !isLoadingDomains) {
      onInviteClient(
        inviteUser.id,
        UrlUtils.GetFullUrl(`${basePortalDomain}/u/${inviteUser.data.ref}`),
      );
    }
  }, [inviteUser, isLoadingDomains]);

  if (!loaded || loading) {
    return null;
  }

  const openClientModal = (clientData: ClientTableRowModel) => {
    if (onClientEdit) {
      onClientEdit(clientData);
    }
  };

  const handleClickViewDetails = (data: any) => {
    const { userId: id } = data;
    const encodedId = encodeURIComponent(id).replace(/%20/g, '%2B');
    history.push(`${CLIENT_DETAILS_PAGE.path}?clientUserId=${encodedId}`);
  };

  /**
   * If the current table is for clients, extract the client's user id out of data of clicked cell and go to the client's details page
   * @param cellData the clicked cell data that contains
   * */
  const handleCellClicked = (cellData: CellClickedEvent) => {
    const { userId: id } = cellData.data;
    const encodedId = encodeURIComponent(id).replace(/%20/g, '%2B');
    if (!isEdit) {
      history.push(`${CLIENT_DETAILS_PAGE.path}?clientUserId=${encodedId}`);
    }
  };
  const [userColumnDefs, setUserColumnDefs] = React.useState<ColDef[]>([
    {
      headerName: 'Name',
      valueGetter: (params) =>
        params.data.avatarImageUrl + params.data.userName + params.data.email,
      field: 'userName',
      minWidth: 300,
      cellRenderer: 'userCellRenderer',
      cellRendererParams: {
        useCellData: true,
        fieldKeys: {
          nameField: 'userName',
          avatarField: 'picture',
        },
      },
      comparator: userTableNameComparator,
      sortable: true,
      getQuickFilterText(filterParams: GetQuickFilterTextParams) {
        const { userName, email, company } = filterParams.data;
        const { name: companyName } = company;
        return `${userName} ${email} ${companyName}`;
      },
    },
    {
      headerName: 'Company',
      field: 'company',
      minWidth: 200,
      cellRenderer: 'companyCellRenderer',
      sortable: true,
      comparator: companyCellComparator,
    },
    {
      headerName: 'Status',
      field: 'status',
      minWidth: 120,
      cellRenderer: 'chipCellRenderer',
      cellRendererParams: {
        mapStatusToSeverity,
      },
      headerComponentParams: {
        tooltipDescription: TooltipContentForStatus,
      },
      sortable: true,
    },
    {
      headerName: 'Creation date',
      field: 'creationDate',
      minWidth: 150,
      cellClass: 'centeredColumn',
      cellRenderer: 'textCellRenderer',
      valueFormatter: LocaleDateFormatter,
      comparator: DateUtils.dateComparator,
      getQuickFilterText(filterParams: GetQuickFilterTextParams) {
        return moment(filterParams.value).format(DEFAULT_DATE_FORMAT);
      },
      sortable: true,
      sort: 'desc',
    },
  ]);

  const handleUpdateCustomFieldValue = (
    customFieldData: CustomFieldValueData,
  ) => {
    const { updatingClient } = customFieldData;

    const updatedClientCustomFields = {
      ...updatingClient?.fields.customFields,
      [customFieldData.fieldId]: customFieldData.value,
    };
    dispatch(
      updateClientUser(
        {
          userId: updatingClient?.id,
          cognitoFirstName: updatingClient?.fields.givenName,
          customFields: updatedClientCustomFields,
          companyId: updatingClient?.fields.companyId,
          companyName: updatingClient?.fields.companyName,
        },
        { isOptimisticChange: true },
      ),
    );
  };

  async function handleUpdateCustomFieldProperty(
    customFieldProperty: CustomFieldOption,
    deletedOptionKey?: string,
    modules?: ModuleSettingsItem[],
  ) {
    // avoid updating the custom fields if a deleted option is being used for visibility config
    // to prevent weird states where a custom field is deleted but still determines visibility
    if (modules && deletedOptionKey) {
      const [isUsed, moduleInUse] = isCustomFieldUsedInAppVisibility(
        modules,
        deletedOptionKey,
        (rule, deletedCustomField) =>
          rule.ruleType === VisibilityRuleType.CustomField &&
          rule.values.includes(deletedCustomField),
      );
      if (isUsed) {
        dispatch(
          alertSnackbar({
            errorMessage:
              'This option is being used in visibility config for ' +
              moduleInUse,
          }),
        );
        return;
      }
    }

    const additionalFieldsData = {
      [customFieldProperty.id]: {
        ...customFieldProperty,
      },
    };
    await dispatch(updateClientCustomFields(additionalFieldsData));
  }

  /**
   * This function format unsearchable custom fields values
   * to searchable ones.
   * @param filterParams: filter params
   * @param customFields: portal custom fields map
   * @param key: column id
   * @returns
   */
  const getCustomFieldFilterText = (
    filterParams: GetQuickFilterTextParams,
    customFields: Record<string, CustomFieldOption>,
    key: string,
  ) => {
    const customField = customFields[key];
    // multi select cell value contains selected tags
    // ids. We need to transform them into tags label
    // so that they can be searchable.
    if (customField.type === 'multiSelect') {
      const selectedTagsIds = filterParams.data[key];
      let cellValue = '';
      if (selectedTagsIds) {
        cellValue = selectedTagsIds
          .map((tagId: string) => {
            const { options } = customField as MultiSelectCustomField;
            if (options) {
              // get whole tag option data from multi select options
              // array.
              const tag = options.find((opt) => opt.id === tagId);
              if (tag) {
                return tag.label;
              }
            }

            return null;
          })
          .filter((tagLabel: string) => tagLabel)
          .join('');
      }

      return cellValue;
    }
    return filterParams.value;
  };

  /**
   * Update base table columns when custom fields and crm table column settings
   * are provided so that
   * 1. any custom fields for portal are added to table
   * 2. all columns should have `hide` set to true/false based on the column settings
   */
  React.useEffect(() => {
    const columnDefs = [...userColumnDefs];
    const customFields = portalCustomFields?.additionalFields;
    if (customFields) {
      // for all custom fields that are not already defined in table columns
      // add to the list of columns with custom fields
      Object.keys(customFields).forEach((key) => {
        const colDefIndex = columnDefs.findIndex((col) => col.field === key);
        // when custom field column  does not exist, add its column definition
        if (colDefIndex === -1) {
          columnDefs.push({
            // each customField cell should have all custom field values
            // to ensure changing a value in any cell will send the updated data
            valueGetter: (params) =>
              params.data.company.id +
              params.data.status +
              params.data.userName +
              params.data.email +
              params.data.avatarImageUrl +
              Object.keys(customFields)
                .map((field) => params.data[field])
                .join(''),
            suppressKeyboardEvent: (params) => {
              const { event } = params;
              // AG-GRID responds to keyboard interactions from the user
              // as well as emitting events when key presses happen
              // on the grid cells. Since we're not using its built-in
              // edit cell feature and CTRL+A shortcut is only handled when
              // cell editing state is true, we need to suppress
              // their event and delegate it to be fired by the quick text.
              if (event.keyCode === 65 && (event.ctrlKey || event.metaKey)) {
                return true;
              }
              return false;
            },
            // for custom fields columns, capitalize the first letter of the column name
            headerName: capitalize(customFields[key].name),
            cellRendererParams: {
              customFieldData: customFields[key],
              onCustomFieldValueUpdated: handleUpdateCustomFieldValue,
              onCustomFieldPropertyUpdated: (
                property: MultiSelectCustomField,
                deletedOptionKey?: string,
              ) =>
                handleUpdateCustomFieldProperty(
                  property,
                  deletedOptionKey,
                  moduleSettings,
                ),
            },
            getQuickFilterText: (filterParams: GetQuickFilterTextParams) =>
              getCustomFieldFilterText(filterParams, customFields, key),
            field: key,
            minWidth: 150,
            cellRenderer: 'customFieldsRenderer',
            sortable: true,
            comparator: (_a, _b, nodeA, nodeB) =>
              customFieldsComparator(nodeA, nodeB, key, customFields[key]),
          });
        } else {
          // otherwise, we need to update its columns definition
          columnDefs[colDefIndex] = {
            ...columnDefs[colDefIndex],
            cellRendererParams: {
              ...columnDefs[colDefIndex].cellRendererParams,

              onCustomFieldPropertyUpdated: (
                property: MultiSelectCustomField,
                deletedOptionKey?: string,
              ) =>
                handleUpdateCustomFieldProperty(
                  property,
                  deletedOptionKey,
                  moduleSettings,
                ),
              customFieldData: customFields[key],
            },
            getQuickFilterText: (filterParams: GetQuickFilterTextParams) =>
              getCustomFieldFilterText(filterParams, customFields, key),
          };
        }
      });
    }

    // arrange column definitions as per column settings
    const columnSettingsMap: Record<string, ColumnSettingsType> = {};
    if (columnSettings?.tablePropertyFields) {
      columnSettings.tablePropertyFields.forEach((obj, index) => {
        columnSettingsMap[obj.id] = { order: index, hide: obj.disabled };
      });
    }

    let finalColumnDefs = columnDefs
      .filter((col) => Boolean(col.field)) // remove actions column before sorting
      .sort((a, b) => columnSortOrderComparator(a, b, columnSettingsMap)) // sort by order set in crm table settings
      .map((tableColumn) => {
        const settings = columnSettingsMap[tableColumn.field || ''];
        return {
          ...tableColumn,
          hide: Boolean(settings?.hide),
        };
      }); // map column definition with settings in crm table settings

    if (areCompaniesDisabled) {
      finalColumnDefs = finalColumnDefs.filter(
        (col) => col.field !== 'company',
      );
    }

    // set final column definitions and append actions column at end
    setCSVColumns(finalColumnDefs.map((col) => col.field ?? ''));
    setUserColumnDefs([
      ...finalColumnDefs,
      {
        colId: 'actions',
        valueGetter: (params) =>
          params.data.company.id + params.data.status + params.data.userName,
        headerName: 'Actions',
        suppressSizeToFit: true,
        pinned: 'right',
        cellRenderer: 'clientActionRenderer',
        cellRendererParams: {
          handleClickRemove: handleClickAction,
          handleClickEdit: openClientModal,
          handleClickInvite: handleClickInviteBtn,
          handleClickViewDetails,
        },
      },
    ]);
  }, [portalCustomFields, columnSettings, areCompaniesDisabled]);

  React.useEffect(() => {
    if (loaded && !isColumnsLoading && clients) {
      const formattedUsers: Array<ClientTableRowModel> = [];
      for (let i = 0; i < clients.length; i += 1) {
        const user: Client = clients[i];
        const company = allCompanies.find(
          (c) => c.id === user.fields.companyId,
        );

        formattedUsers.push({
          name: user.fields.givenName,
          ref: user.ref, // used to reference the client invitation link
          company: {
            id: company?.id || '',
            name:
              company && company.fields && !company.fields.isPlaceholder
                ? company.fields.name
                : PLACEHOLDER_COMPANY_NAME,
            // when client company is deleted, it is no longer defined
            // in redux store, so we should consider client company
            // as placeholder explicitly.
            isPlaceholder: company
              ? Boolean(company?.fields.isPlaceholder)
              : true,
            avatarUrl: company?.fields.avatarImageURL || '',
            fallbackColor: company?.fields.fallbackColor || '',
          },
          creationDate: user.createdDate
            ? user.createdDate
            : user.fields.cognitoCreated,
          // location: getFieldValue(user, 'address'),
          userId: user.id,
          picture: user.fields.avatarImageUrl,
          fallbackColor: user.fields.fallbackColor,
          email: user.fields.email,
          userName: `${user.fields.givenName} ${user.fields.familyName}`,
          status: formatStatus(user),
          clientFullData: user,
          userRoles: user.fields.roles,
          ...user.fields?.customFields,
        });
      }

      setRowData(formattedUsers);
    }
  }, [clients, allCompanies, loaded, isColumnsLoading, internalUsers]);

  return (
    <>
      {currentData && currentData.id && (
        <ActionButtonModal
          open={!!currentData.id}
          onClose={handleClose}
          positiveAction
          onPositiveActionPerformed={handleConfirm}
          title="Delete client"
          positiveActionText="Confirm"
          mainComponent={`Are you sure you want to delete ${currentData.data.userName}?`}
        />
      )}

      <AgGrid
        className={classes.root}
        rowData={rowData}
        columnDefs={isColumnsLoading ? [] : userColumnDefs}
        overlayParams={{
          noRowsLabel: 'No users created',
        }}
        searchKey={searchKey}
        onCellClicked={handleCellClicked}
        getRowNodeId={(data: any) => data.userId}
        immutableData
        frameworkComponents={{
          userCellRenderer: UserCellRenderer,
          textCellRenderer: TextCellRenderer,
          companyCellRenderer: CompanyCellRenderer,
          chipCellRenderer: ChipCellRenderer,
          clientActionRenderer: ClientActionRenderer,
          customFieldsRenderer: (params: CustomFieldsRendererProps) => (
            <CustomFieldsRenderer {...params} setIsEdit={setIsEdit} />
          ),
        }}
      />

      <TableColumnSettings
        open={Boolean(columnSettingsAnchorEl)}
        anchorEl={columnSettingsAnchorEl}
        onClose={onColumnSettingsClose}
        tableEntityType={TablePropertiesEntityTypes.CRM}
        columnDefs={
          isColumnsLoading
            ? []
            : userColumnDefs.filter((col) => Boolean(col.field))
        }
      />
    </>
  );
};
