import Stripe from 'stripe';
import Axios, { AxiosResponse } from 'axios';
import moment from 'moment';
import { API } from 'src/utils/AmplifyApiUtils';
import {
  InvoiceEntity,
  SubscriptionEntity,
  PayingModel,
  UpdateSourceRequestModel,
  Subscription,
  isInvoice,
} from 'src/store/payments/types';
import { StripeEntity, UnAuthAPIName } from 'src/constants';

interface GetInvoicesInput {
  isTemplateQuery?: boolean;
  clientId?: string;
  paginate?: boolean;
  ids?: string[];
  startKey?: string;
}

export default class PaymentsClient {
  /**
   * method to get invoices
   * @param {boolean, string} data - optional parameters isTemplateQuery and clientId to fetch template of invoices
   * or fetch pre-filtered invoices for a client
   */
  static getInvoices(data: GetInvoicesInput) {
    const queryStringParameters: any = {};
    if (data.clientId) {
      queryStringParameters.userID = data.clientId;
    }

    if (data.isTemplateQuery) {
      queryStringParameters.isTemplateQuery = data.isTemplateQuery;
    }

    if (data.paginate) {
      queryStringParameters.paginate = 'true';
    }

    if (data.ids) {
      queryStringParameters.ids = data.ids.join(',');
    }
    if (data.startKey) {
      queryStringParameters.startKey = data.startKey;
    }

    return API.get('AppAPI', '/entities/stripe_invoice', {
      queryStringParameters,
    });
  }

  /**
   * This method is used to request all subscriptions(template/normal subscriptions)
   * or client subscriptions.
   * @param isTemplateQuery: should be true to load subscription templates
   * @param clientId : given client id to load client subscriptions
   * @returns
   */
  static getSubscriptions(isTemplateQuery?: boolean, clientId?: string) {
    const defaultQueryParams = clientId ? { userID: clientId } : {};
    const params = isTemplateQuery
      ? {
          queryStringParameters: {
            isTemplateQuery: true,
          },
        }
      : { queryStringParameters: defaultQueryParams };

    return API.get('AppAPI', `/entities/stripe_subscription`, params);
  }

  static addStripeEntity(data: InvoiceEntity | SubscriptionEntity) {
    const { fields } = data;
    // we avoid adding a new param for entity type,
    // we can differentiate invoice from subscription from
    // fields names e.g. for invoice it has dateOfIssue.
    const entityType: StripeEntity = fields.dateOfIssue
      ? 'stripe_invoice'
      : 'stripe_subscription';

    delete fields.id;
    delete fields.amount;

    // additional fields are added when it is a template
    // entity data. if it is a template entity, we need
    // to add additionalFields object since it contains
    // all template meta data (template name, etc ...)
    const isTemplate = data.additionalFields?.templateName;
    const additionalFields = isTemplate
      ? {
          additionalFields: data.additionalFields,
        }
      : {};

    const entityFields = isInvoice(data)
      ? {
          dateOfIssue: moment(fields.dateOfIssue).utc().toISOString(true),
          dueDate: moment(data.fields.dueDate).utc().toISOString(true),
        }
      : { daysUntilDue: Number(data.fields.daysUntilDue) };

    return API.post('AppAPI', `/entities/${entityType}`, {
      body: {
        data: [
          {
            fields: {
              ...fields,
              lineItems: fields.lineItems.map((lineItem) => ({
                ...lineItem,
                id: '',
                quantity: parseFloat(lineItem.quantity.toString()),
                rate: parseFloat(lineItem.rate.toString()),
              })),
              taxPercentage: parseFloat(fields.taxPercentage.toString()) || 0,
              ...entityFields,
            },
            ...additionalFields,
          },
        ],
      },
    });
  }

  static addInvoice(data: InvoiceEntity) {
    const { fields } = data;
    delete fields.id;
    delete fields.amount;

    // additional fields are added when it is a template
    // entity data. if it is a template entity, we need
    // to add additionalFields object since it contains
    // all template meta data (template name, etc ...)
    const isTemplate = data.additionalFields?.templateName;
    const additionalFields = isTemplate
      ? {
          additionalFields: data.additionalFields,
        }
      : {};

    const entityFields = {
      dateOfIssue: moment(fields.dateOfIssue).utc().toISOString(true),
      dueDate: moment(fields.dueDate).utc().toISOString(true),
    };

    return API.post('AppAPI', `/entities/invoice`, {
      body: {
        data: [
          {
            fields: {
              ...fields,
              lineItems: fields.lineItems.map((lineItem) => ({
                ...lineItem,
                id: '',
                quantity: parseFloat(lineItem.quantity.toString()),
                rate: parseFloat(lineItem.rate.toString()),
              })),
              taxPercentage: parseFloat(fields.taxPercentage.toString()) || 0,
              ...entityFields,
            },
            ...additionalFields,
          },
        ],
      },
    });
  }

  // updates subscription using field values created by subscription edit form
  static updateSubscription(data: { subId: string; fields: Subscription }) {
    const { subId, fields } = data;

    const entityFields = { daysUntilDue: Number(fields.daysUntilDue) };

    // pass scheduleEndDate, scheduleStartDate and scheduleIterations to BE only if there are valid values
    if (fields.scheduleEndDate && fields.scheduleEndDate === 0) {
      delete fields.scheduleEndDate;
    }

    if (fields.scheduleStartDate && fields.scheduleStartDate === 0) {
      delete fields.scheduleStartDate;
    }

    if (fields.scheduleIterations && fields.scheduleIterations === 0) {
      delete fields.scheduleIterations;
    }

    return API.put('AppAPI', `/subscriptions/${subId}`, {
      body: {
        ...fields,
        lineItems: fields.lineItems.map((lineItem) => ({
          ...lineItem,
          id: '',
          quantity: parseFloat(lineItem.quantity.toString()),
          rate: parseFloat(lineItem.rate.toString()),
        })),
        taxPercentage: parseFloat(fields.taxPercentage.toString()) || 0,
        ...entityFields,
      },
    });
  }

  static updateInvoice(invoiceData: InvoiceEntity) {
    const { fields, id } = invoiceData;
    delete fields.id;

    return API.put('AppAPI', `/entities/invoice`, {
      body: {
        data: [
          {
            id,
            fields: {
              ...fields,
              lineItems: fields.lineItems.map((lineItem) => ({
                ...lineItem,
                id: '',
                quantity: parseFloat(lineItem.quantity.toString()),
                rate: parseFloat(lineItem.rate.toString()),
              })),
              dateOfIssue: new Date(fields.dateOfIssue).toISOString(),
              dueDate: new Date(fields.dueDate).toISOString(),
              taxPercentage: fields.taxPercentage
                ? parseFloat(fields.taxPercentage.toString())
                : 0,
              memo: fields.memo ?? '',
            },
          },
        ],
      },
    });
  }

  static cancelSubscription(subscriptionData: SubscriptionEntity) {
    const { id } = subscriptionData;
    return API.post('AppAPI', `/subscriptions/${id}/cancel`, {});
  }

  static verifyBank(
    stripeCustomerId: string,
    sourceId: string,
    data: Stripe.CustomerSourceVerifyParams,
  ): Promise<AxiosResponse<Stripe.BankAccount>> {
    const url = '/api/stripe/verify';
    return Axios({
      method: 'post',
      url,
      data: {
        stripeCustomerId,
        sourceId,
        data,
      },
    });
  }

  static updateSource(
    updateSourceData: UpdateSourceRequestModel,
  ): Promise<AxiosResponse<Stripe.CustomerSource>> {
    const { customerId, sourceId, sourceData, isPrimary } = updateSourceData;
    const url = '/api/stripe/updateSource';
    return Axios({
      method: 'post',
      url,
      data: {
        customerId,
        sourceId,
        sourceData,
        isPrimary,
      },
    });
  }

  static deleteSource(
    updateSourceData: UpdateSourceRequestModel,
  ): Promise<AxiosResponse<Stripe.CustomerSource>> {
    const { customerId, sourceId, isLastSource } = updateSourceData;
    const url = '/api/stripe/deleteSource';
    return Axios({
      method: 'post',
      url,
      data: {
        customerId,
        sourceId,
        isLastSource,
      },
    });
  }

  static deleteInvoice(invoiceIdList: Array<string>) {
    return API.del('AppAPI', `/entities/stripe_invoice`, {
      body: invoiceIdList,
    });
  }

  static sendInvoice(invoiceId: string) {
    return API.post('AppAPI', `/v0/invoices/${invoiceId}/finalize`, {
      body: {},
    });
  }

  static changePlan(priceId: string) {
    return API.put('AppAPI', `/subscriptions/portal`, {
      body: {
        priceId,
      },
    });
  }

  static payInvoice(payingData: PayingModel) {
    const { invoiceId, sourceId, totalAmount, isManual } = payingData;

    let body = {};

    if (isManual) {
      body = {
        manual: true,
      };
    } else if (sourceId && totalAmount) {
      body = {
        sourceId,
        amount: Math.round(totalAmount),
      };
    }

    return API.post('AppAPI', `/v0/invoices/${invoiceId}/pay`, {
      body,
    });
  }

  static copyPaymentLink(payingData: PayingModel) {
    const { invoiceId } = payingData;
    return API.get('AppAPI', `/v0/invoices/${invoiceId}/link`, {});
  }

  static activateInvoice(invoiceId: string): Promise<Stripe.Invoice> {
    return API.post('AppAPI', `/v0/invoices/${invoiceId}/activate`, {
      body: {},
    });
  }

  static getPlaidLinkToken(): Promise<string> {
    return API.post('AppAPI', `/token/plaid_link_token`, {
      responseType: 'text',
      withCredentials: true,
      body: {
        source: window.location.href,
      },
    });
  }

  static savePlaidBankAccount(
    publicToken: string,
    accountId: string,
  ): Promise<Stripe.BankAccount> {
    return API.post('AppAPI', `/token/plaid_access_token`, {
      body: {
        publicToken,
        accountId,
      },
    });
  }

  /**
   * Get portal plan status API
   * @returns
   */
  static getPortalPlan(): Promise<any> {
    return API.get(UnAuthAPIName, `/portal/any/plan`, {});
  }
}
