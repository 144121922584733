export interface Tab {
  key: ClientDetailsTabKey;
  label: string;
}

export function isTab(item: any): item is Tab {
  return Object.values(ClientDetailsTabKey).includes(item.key);
}

export enum ClientDetailsTabKey {
  Messages = 'tab-messages',
  Files = 'tab-files',
  Payments = 'tab-payments',
  Forms = 'tab-forms',
  Contracts = 'tab-contracts',
  More = 'tab-extensions', // represents embeds and apps
}
