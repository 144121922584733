import React from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import { User } from 'src/constants';
import { UserAvatar } from 'src/components/User';
import BaseTypography from 'src/components/Text/BaseTypography';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    itemDescription: {
      marginLeft: theme.spacing(1.5),
    },
    groupItem: {
      marginTop: theme.spacing(1),
    },
    fade: {
      opacity: 0.5,
    },
  }),
);

interface AssigneeListProps {
  title: string;
  option: User;
  options: User[];
  group: boolean;
  maxShown: number;
}

export const AssigneeList: React.FC<Partial<AssigneeListProps>> = ({
  title,
  option,
  options,
  group = false,
  maxShown,
}) => {
  const classes = useStyles();
  const [maxOptions, setMaxOptions] = React.useState<number | null>(
    maxShown || null,
  );
  const { fields: singleOptionFields } = option || {};
  const singleOptionName = `${singleOptionFields?.givenName} ${singleOptionFields?.familyName}`;

  const handleShowLess = (ev: React.MouseEvent<HTMLSpanElement>) => {
    ev.stopPropagation();
    setMaxOptions(maxShown ?? null);
  };

  const handleShowMore = (ev: React.MouseEvent<HTMLSpanElement>) => {
    ev.stopPropagation();
    setMaxOptions(null);
  };

  if (!group) {
    return (
      <Box>
        <Box display="flex" alignItems="center">
          <Box>
            <UserAvatar
              avatarUrl={singleOptionFields?.avatarImageUrl}
              name={singleOptionName}
              fallbackColor={singleOptionFields?.fallbackColor}
              avatarSize="xsmall"
              type="image"
            />
          </Box>
          <Box className={classes.itemDescription}>
            <BaseTypography
              fontType="13Medium"
              style={{
                color: Colors.GraySmall,
                marginTop: 3,
                lineHeight: `18px`,
              }}
            >
              {title}
            </BaseTypography>
            <BaseTypography fontType="13Medium" style={{ color: Colors.black }}>
              {singleOptionName}
            </BaseTypography>
          </Box>
        </Box>
      </Box>
    );
  }

  return (
    <Box display="flex" flexDirection="column" pb={1}>
      <Box>
        <BaseTypography fontType="13Medium" style={{ color: Colors.GraySmall }}>
          {title}
        </BaseTypography>
      </Box>
      <Box>
        {options?.map((optionItem, index) => {
          const showOption = maxOptions ? index < maxOptions : true;
          return (
            showOption && (
              <Box
                className={classes.groupItem}
                key={optionItem.id}
                display="flex"
                alignItems="center"
              >
                <UserAvatar
                  avatarUrl={optionItem.fields.avatarImageUrl}
                  name={`${optionItem.fields.givenName} ${optionItem.fields.familyName}`}
                  fallbackColor={optionItem.fields.fallbackColor}
                  avatarSize="mini"
                  type="image"
                  shape="circle"
                />
                <BaseTypography
                  fontType="12Medium"
                  style={{ color: Colors.black, marginLeft: 8 }}
                >
                  {`${optionItem.fields.givenName} ${optionItem.fields.familyName}`}
                </BaseTypography>
              </Box>
            )
          );
        })}
        {maxOptions && options && options.length > maxOptions && (
          <BaseTypography
            fontType="12Medium"
            onClick={handleShowMore}
            style={{ color: Colors.LightGray, marginTop: 10 }}
          >
            Show {(options?.length ?? 0) - maxOptions} more
          </BaseTypography>
        )}
        {maxShown && !maxOptions && (
          <BaseTypography
            fontType="12Regular"
            onClick={handleShowLess}
            style={{ color: Colors.LightGray, marginTop: 10 }}
          >
            Show less
          </BaseTypography>
        )}
      </Box>
    </Box>
  );
};
