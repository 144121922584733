import React from 'react';
import { ActionImpl, KBarResults, useMatches } from 'kbar';
import { CommandBarResultsItem } from 'src/components/CommandBar/CommandBarResultsItem';
import { UserResultItem } from 'src/components/CommandBar/UserResultItem';
import {
  ClientAction,
  RenderParams,
} from 'src/components/CommandBar/commandBarTypes';

const HiddenCommandBarActionIds = {
  'escape-shortcut': true,
  'search-shortcut': true,
  shortcutSidebar: true,
  'sidebar-payments': true,
};

/**
 * check the type of the result and return Id accordingly
 * @param result the result from Kbar useMatches
 * @returns
 */
const getResultItemId = (result: ActionImpl | string) => {
  if (typeof result === 'string' || result instanceof String) {
    return result as string;
  }
  return result.id;
};

function isClientAction(
  action: ActionImpl,
): action is ActionImpl & ClientAction {
  return action.parent === 'open-client';
}

export const CommandBarResult: React.FC = () => {
  const { results, rootActionId } = useMatches();

  const filterBySectionName = (
    action: ActionImpl | string,
    sectionName: string,
  ) => action === sectionName || (action as ActionImpl).section === sectionName;

  /**
   * This holds commandbar actions search results
   * to be shown in the command bar menu.
   * It filters out the actions we don't want to show in the command bar.
   * Also it sort the actions by their section name.
   */
  const commandBarItems = React.useMemo(() => {
    const stagedResults = results.filter(
      (result) => !(getResultItemId(result) in HiddenCommandBarActionIds),
    );

    const navigationCommands = stagedResults.filter((item) =>
      filterBySectionName(item, 'Navigation'),
    );
    const generalCommands = stagedResults.filter((item) =>
      filterBySectionName(item, 'General'),
    );
    const helpCommands = stagedResults.filter((item) =>
      filterBySectionName(item, 'Help'),
    );
    const miscellaneousCommands = stagedResults.filter((item) =>
      filterBySectionName(item, 'Miscellaneous'),
    );
    const accountCommands = stagedResults.filter((item) =>
      filterBySectionName(item, 'Account'),
    );
    const otherCommands = stagedResults.filter(
      (item) =>
        // group actions objects without section prop
        typeof item !== 'string' && !(item instanceof String) && !item.section,
    );

    // Define section names to filter client sections
    const sectionNames = ['Create...', 'Open client...', 'Invite team member'];

    // Extract client sections based on sectionNames
    const sectionsByName = otherCommands.filter(
      (item) =>
        typeof item !== 'string' &&
        item.name &&
        sectionNames.includes(item.name),
    );

    // extract all clients with the parent 'open-client'
    const allClients = otherCommands.filter(
      (item) =>
        typeof item !== 'string' &&
        !item.section &&
        item.parent === 'open-client',
    );

    // Determine the final set of commands to display
    const clientCommands = allClients.length
      ? [...sectionsByName, 'Clients', ...allClients]
      : otherCommands;

    // commands should appear in a specific order
    return [
      ...generalCommands,
      ...navigationCommands,
      ...helpCommands,
      ...miscellaneousCommands,
      ...accountCommands,
      ...clientCommands,
    ];
  }, [results, rootActionId]);

  const onItemRender = React.useCallback((props: RenderParams) => {
    // when item has parent id equal to open-client
    // it should render item with avatar
    const { item, active } = props;
    if (typeof item === 'object' && 'parent' in item && isClientAction(item)) {
      return <UserResultItem item={item} active={active} />;
    }

    return <CommandBarResultsItem {...props} />;
  }, []);

  return <KBarResults items={commandBarItems} onRender={onItemRender} />;
};
