import { Company } from 'src/store/clients/types';
import { appAPI, ApiTags, ApiMethods } from '.';

type getCompaniesInput = {
  ignoreAccess?: boolean;
};

export const companiesApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getCompanies: build.query<Company[], getCompaniesInput>({
      query: (getCompaniesInput) => ({
        path: `/companies`,
        method: ApiMethods.get,
        options: {
          queryStringParameters: getCompaniesInput,
        },
      }),
      providesTags: [ApiTags.clientCompanies],
    }),
  }),
});

export const { useGetCompaniesQuery } = companiesApi;
