import React from 'react';
import { Box, Button, makeStyles, Theme } from '@material-ui/core';
import {
  BlackHeadings,
  GraySmall,
  green,
  red,
  redDark,
  yellow,
} from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import RowDivider from 'src/components/RowDivider';
import { SUPPORT_EMAIL } from 'src/constants/externalUrls';

export type AccountVerificationStatus =
  | 'pending'
  | 'failed'
  | 'unverified'
  | 'verified'
  | 'restricted';
export interface AccountStatusData {
  accountType: string;
  loginUrl: string;
  verificationStatus: AccountVerificationStatus | '';
  accountName: string;
  currency: string;
  accountCountry: string;
  businessName: string;
}
interface VerificationStatusProps {
  statusData: AccountStatusData;
  onConnectExpressClick: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  verified: {
    color: green,
  },
  pending: {
    color: yellow,
  },
  unverified: {
    color: red,
  },
  failed: {
    color: redDark,
  },
  restricted: {
    color: redDark,
  },
  descriptionText: {
    color: GraySmall,
    marginLeft: theme.spacing(0.5),
  },
}));

interface VerificationStatusData {
  title: string;
  description: string;
  actionLabel?: string;
}

const VerificationStatusMapper: Record<
  AccountVerificationStatus,
  VerificationStatusData
> = {
  pending: {
    title: 'Verifying',
    description:
      'Please be patient as your banking information is being verified.',
  },
  failed: {
    title: 'Failed to connect',
    description: `Your account could not be connected.`,
    actionLabel: 'Edit',
  },
  verified: {
    title: 'Successfully linked',
    description: `You are ready to bill your clients and receive payments.`,
    actionLabel: 'Edit',
  },
  unverified: {
    title: 'Not verified',
    description:
      'Information that you entered may not be correct or additional verification is required.',
    actionLabel: 'Verify now',
  },
  restricted: {
    title: 'Restricted',
    description: `Your account has been restricted due to a large number of failures when charging or managing payment methods. Please contact ${SUPPORT_EMAIL} to further assistance.`,
  },
};

export const VerificationStatus: React.FC<VerificationStatusProps> = ({
  statusData,
  onConnectExpressClick,
}) => {
  const classes = useStyles();
  const {
    loginUrl,
    verificationStatus: status,
    accountName,
    currency,
    accountType,
  } = statusData;

  const handleEdit = () => {
    if (!loginUrl) return;
    window.open(loginUrl, '__traget');
  };

  const actionLabel = status && VerificationStatusMapper[status].actionLabel;

  return status ? (
    <Box display="flex" flexDirection="row">
      <Box width={1}>
        <RowDivider mt={2} mb={2.5} />
        <Box width={1} display="flex" alignItems="center">
          {accountName && (
            <BaseTypography
              fontType="13Medium"
              style={{ color: BlackHeadings }}
            >
              {accountName}
            </BaseTypography>
          )}
          <Box ml={1}>
            {currency && (
              <BaseTypography>({currency.toUpperCase()})</BaseTypography>
            )}
          </Box>
        </Box>
        <Box width={1} display="flex" justifyContent="space-between">
          <Box display="flex">
            <BaseTypography component="span" className={classes[status]}>
              {VerificationStatusMapper[status].title}
            </BaseTypography>
            <BaseTypography
              component="span"
              className={classes.descriptionText}
            >
              -
            </BaseTypography>
            <BaseTypography
              component="span"
              className={classes.descriptionText}
            >
              {VerificationStatusMapper[status].description}
            </BaseTypography>
          </Box>
          {accountType === 'standard' && (
            <Button
              variant="contained"
              color="secondary"
              onClick={onConnectExpressClick}
            >
              Connect Express
            </Button>
          )}
          {actionLabel && loginUrl && accountType !== 'standard' && (
            <Button variant="contained" color="secondary" onClick={handleEdit}>
              {actionLabel}
            </Button>
          )}
        </Box>
      </Box>
    </Box>
  ) : null;
};
