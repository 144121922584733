import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth';
import {
  BaseEntity,
  User,
  UserRolesTypesUnMapped,
  Client as ClientType,
  Company as CompanyType,
} from 'src/constants/dataTypes';
import {
  ColumnSettingsType,
  TableSettingsType,
} from 'src/constants/componentTypes/tableTypes';
import { MultiSelectOption, SelectOption } from 'src/components/Select/types';
import { AccessLevel } from 'src/components/UsersTable/useClientAccessForm';

export type Client = ClientType;
export type Company = CompanyType;

export interface CompanyFormData {
  companyId?: string;
  companyName?: string;
  isPlaceholder?: boolean;
  customerId?: string;
  leadUserID?: string;
  avatarImageURL?: string;
  additionalFields?: {};
}

export interface ClientFormData {
  userId?: string;
  role?: string;
  companyId?: string;
  companyName?: string;
  avatarImageUrl?: string;
  cognitoEmail?: string;
  cognitoFirstName?: string;
  cognitoLastName?: string;
  sendInvite?: boolean;
  customFields?: Record<string, any>;
  noPasswordSet?: boolean;
  externalAuthProviders?: Array<CognitoHostedUIIdentityProvider>;
  additionalFields?: {
    formsAssociated?: string[];
    assignedToCompanies?: Map<string, boolean>;
    leadOfCompanies?: Map<string, boolean>;
  };
  isPlaceholder?: boolean;
  password?: string;
}

export interface CompanyCellData {
  id: string;
  name: string;
  avatarUrl: string;
  isPlaceholder: boolean;
  fallbackColor: string;
}

export interface ClientTableRowModel {
  userId: string;
  ref?: string;
  name: string;
  email: string;
  userName: string;
  fallbackColor: string;
  picture: string;
  clientFullData?: Client;
  company: CompanyCellData | string;
  role?: string;
  creationDate: string;
  status: string;
  userRoles: Array<UserRolesTypesUnMapped>;
  clientAccess?: AccessLevel;
  companyId?: string;
  companyAccessList?: User['companyAccessList'];
}

export interface AssigneeDropDownOptions extends SelectOption {
  role: string;
}

export interface CustomFieldEntity extends BaseEntity {
  additionalFields: Record<string, CustomFieldOption>;
}

export interface ClientsState {
  clients: Client[];
  activeClients: Client[];
  activeCompanies: Company[];
  archivedCompanies: Company[];
  companies: Company[];
  companyDataMap: Record<string, Record<string, string>>;
  assignedClients: Client[];
  isLoading: boolean;
  loadedClients: boolean;
  isInviting: boolean;
  invitedClient: boolean;
  isCreating: boolean;
  createdClient: boolean;
  isRemoving: boolean;
  isUpdating: boolean;
  updatedClient: boolean;
  error: string;
  clientCustomFields: CustomFieldEntity | null;
  isClientCustomFieldsLoading: boolean;
  isClientCustomFieldsLoaded: boolean;
  crmTableSettings: TableSettingsType;
  updatingClientsIds: Record<string, boolean>;
}

export type CustomFieldType =
  | 'phoneNumber'
  | 'email'
  | 'url'
  | 'text'
  | 'number'
  | 'address'
  | 'multiSelect';

// Base custom field structure
export interface CustomFieldOption<
  Option extends MultiSelectOption = MultiSelectOption,
> {
  id: string;
  key: string;
  order: number;
  name: string;
  type: CustomFieldType;
  options?: Array<Option>;
}

// Multi select custom field structure
export interface MultiSelectCustomField extends CustomFieldOption {
  options: Array<MultiSelectOption>;
}

export const LOAD_CLIENTS_SUCCESS = 'LOAD_CLIENTS_SUCCESS';
export const LOAD_ASSIGNED_CLIENTS = 'LOAD_ASSIGNED_CLIENTS';
export const LOAD_COMPANIES_REQUEST = 'LOADING_COMPANIES';
export const LOAD_COMPANIES_SUCCESS = 'LOAD_COMPANIES_SUCCESS';
export const LOAD_COMPANIES_FAILURE = 'LOAD_COMPANIES_FAILURE';
export const ADD_CLIENT_REQUEST = 'ADDING_CLIENT';
export const ADD_CLIENT_SUCCESS = 'ADD_CLIENT_SUCCESS';
export const ADD_CLIENT_FAILURE = 'ADD_CLIENT_FAILURE';
export const INVITE_CLIENT_REQUEST = 'INVITING_CLIENT';
export const INVITE_CLIENT_SUCCESS = 'INVITE_CLIENT_SUCCESS';
export const INVITE_CLIENT_FAILURE = 'INVITE_CLIENT_FAILURE';
export const UPDATE_CLIENT_REQUEST = 'UPDATE_CLIENT_REQUEST';
export const UPDATE_CLIENTS_SUCCESS = 'UPDATE_CLIENTS_SUCCESS';
export const UPDATE_CLIENT_FAILURE = 'UPDATE_CLIENT_FAILURE';
export const UPDATE_ASSIGNED_CLIENTS = 'UPDATE_ASSIGNED_CLIENTS';
export const CLEAR_CLIENTS = 'CLEAR_CLIENTS';
export const DELETE_CLIENTS_REQUEST = 'DELETE_CLIENTS_REQUEST';
export const DELETE_CLIENTS_SUCCESS = 'DELETE_CLIENTS_SUCCESS';
export const DELETE_CLIENTS_FAILED = 'DELETE_CLIENTS_FAILED';
export const ADD_COMPANY_REQUEST = 'ADDING_COMPANY';
export const ADD_COMPANY_SUCCESS = 'ADD_COMPANY_SUCCESS';
export const ADD_COMPANY_FAILURE = 'ADD_COMPANY_FAILURE';
export const SET_CLIENT_CUSTOM_FIELDS = 'SET_CLIENT_CUSTOM_FIELDS';
export const LOAD_CLIENT_CUSTOM_FIELDS_REQUEST =
  'LOAD_CLIENT_CUSTOM_FIELDS_REQUEST';
export const UPDATE_CLIENT_CUSTOM_FIELDS_REQUEST =
  'UPDATE_CLIENT_CUSTOM_FIELDS_REQUEST';
export const UPDATE_COMPANY_REQUEST = 'UPDATE_COMPANY_REQUEST';
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS';
export const UPDATE_COMPANIES_SUCCESS = 'UPDATE_COMPANIES_SUCCESS';
export const UPDATE_COMPANY_FAILURE = 'UPDATE_COMPANY_FAILURE';
export const DELETE_COMPANY_REQUEST = 'DELETE_COMPANY_REQUEST';
export const DELETE_COMPANIES_SUCCESS = 'DELETE_COMPANIES_SUCCESS';
export const DELETE_COMPANY_FAILED = 'DELETE_COMPANY_FAILED';
export const UPDATE_CRM_TABLE_COLUMN_SETTINGS =
  'UPDATE_CRM_TABLE_COLUMN_SETTINGS';

interface ClearClientsAction {
  type: typeof CLEAR_CLIENTS;
}

interface LoadClientsDoneAction {
  type: typeof LOAD_CLIENTS_SUCCESS;
  clients: Client[];
  activeClients: User[];
  assignedClients: User[];
}

interface StartLoadCustomFieldsActionType {
  type: typeof LOAD_CLIENT_CUSTOM_FIELDS_REQUEST;
}

interface SetCustomFieldsActionType {
  type: typeof SET_CLIENT_CUSTOM_FIELDS;
  payload: CustomFieldEntity | null;
  error: string;
  isRemove: boolean;
}

interface LoadClientAssignmentsDoneAction {
  type: typeof LOAD_ASSIGNED_CLIENTS;
  assignedClients: User[];
}

interface StartLoadCompaniesAction {
  type: typeof LOAD_COMPANIES_REQUEST;
}

interface LoadCompaniesDoneAction {
  type: typeof LOAD_COMPANIES_SUCCESS;
  payload: Company[];
}

interface LoadCompaniesFailAction {
  type: typeof LOAD_COMPANIES_FAILURE;
  error: string;
}

interface StartAddClientAction {
  type: typeof ADD_CLIENT_REQUEST;
}

interface AddClientDoneAction {
  type: typeof ADD_CLIENT_SUCCESS;
  payload: any;
  isWsEvent?: boolean;
}

interface AddClientFailAction {
  type: typeof ADD_CLIENT_FAILURE;
  error: string;
}

interface StartInviteClientAction {
  type: typeof INVITE_CLIENT_REQUEST;
}

interface InviteClientDoneAction {
  type: typeof INVITE_CLIENT_SUCCESS;
  invitedUserId: string;
  invitedByUserId: string;
}

interface InviteClientFailAction {
  type: typeof INVITE_CLIENT_FAILURE;
  error: string;
}

interface StartUpdateClientAction {
  type: typeof UPDATE_CLIENT_REQUEST;
  updatingClientId: string;
}

interface UpdateClientDoneAction {
  type: typeof UPDATE_CLIENTS_SUCCESS;
  isWsEvent?: boolean;
  payload: any;
}

interface UpdateClientAssigneesActionPayload {
  assignedCompanies: string[];
  clients: User[];
}

interface UpdateClientAssigneesAction {
  type: typeof UPDATE_ASSIGNED_CLIENTS;
  payload: UpdateClientAssigneesActionPayload;
}

interface UpdateClientFailAction {
  type: typeof UPDATE_CLIENT_FAILURE;
  error: string;
  updatingClientId: string;
}

interface StartDeleteClientAction {
  type: typeof DELETE_CLIENTS_REQUEST;
}

interface DeleteClientDoneAction {
  type: typeof DELETE_CLIENTS_SUCCESS;
  payload: string;
  isWsEvent?: boolean;
}

interface DeleteClientFailAction {
  type: typeof DELETE_CLIENTS_FAILED;
  error: string;
}

interface StartAddCompanyAction {
  type: typeof ADD_COMPANY_REQUEST;
}

interface AddCompanyDoneAction {
  type: typeof ADD_COMPANY_SUCCESS;
  payload: Company[];
}

interface AddCompanyFailAction {
  type: typeof ADD_COMPANY_FAILURE;
  error: string;
}

interface StartUpdateCompanyAction {
  type: typeof UPDATE_COMPANY_REQUEST;
}

interface UpdateCompanyDoneAction {
  type: typeof UPDATE_COMPANY_SUCCESS;
  payload: any;
}

interface UpdatedCompaniesDoneAction {
  type: typeof UPDATE_COMPANIES_SUCCESS;
  payload: any;
}

interface UpdateCompanyFailAction {
  type: typeof UPDATE_COMPANY_FAILURE;
  error: string;
}

interface StartDeleteCompanyAction {
  type: typeof DELETE_COMPANY_REQUEST;
}

interface DeleteCompanyDoneAction {
  type: typeof DELETE_COMPANIES_SUCCESS;
  payload: string[];
}

interface DeleteCompanyFailAction {
  type: typeof DELETE_COMPANY_FAILED;
  error: string;
}

interface UpdateCrmTableColumnSettingsActionType {
  type: typeof UPDATE_CRM_TABLE_COLUMN_SETTINGS;
  columnSettings: Record<string, ColumnSettingsType>;
}

export type ClientsActionTypes =
  | ClearClientsAction
  | LoadClientsDoneAction
  | LoadClientAssignmentsDoneAction
  | StartLoadCompaniesAction
  | LoadCompaniesDoneAction
  | LoadCompaniesFailAction
  | StartAddClientAction
  | AddClientDoneAction
  | AddClientFailAction
  | StartInviteClientAction
  | InviteClientDoneAction
  | InviteClientFailAction
  | StartDeleteClientAction
  | DeleteClientDoneAction
  | DeleteClientFailAction
  | StartDeleteCompanyAction
  | DeleteCompanyDoneAction
  | DeleteCompanyFailAction
  | StartAddCompanyAction
  | AddCompanyDoneAction
  | AddCompanyFailAction
  | StartUpdateClientAction
  | UpdateClientDoneAction
  | UpdateClientAssigneesAction
  | UpdateClientFailAction
  | StartUpdateCompanyAction
  | UpdateCompanyDoneAction
  | UpdatedCompaniesDoneAction
  | UpdateCompanyFailAction
  | SetCustomFieldsActionType
  | StartLoadCustomFieldsActionType
  | UpdateCrmTableColumnSettingsActionType;
