import React, { ComponentProps } from 'react';
import {
  createStyles,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  SvgIcon,
  Theme,
} from '@material-ui/core';
import { IconType } from 'react-icons';
import classNames from 'classnames';
import { TooltipWrapper } from 'src/components/Tooltip';
import { SidebarThemeContext, SidebarTheme } from 'src/context';
import {
  typography13RegularStyle,
  typography13MediumStyle,
  typography12RegularStyle,
} from 'src/components/Text';
import { LightGray } from 'src/theme/colors';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listItemIconContainer: {
      color: (props: SidebarTheme) => props.sidebarTextColor,
      minWidth: '24px',
      height: 'auto',
    },
    listItemIcon: {
      width: '12px',
      height: '12px',
    },
    listItemIconStrokeWidth: {
      '& path': {
        strokeWidth: 2,
      },
    },
    listItemTextContainer: {
      marginTop: theme.spacing(0.25),
      marginBottom: theme.spacing(0.25),
    },
    listItemText: {
      ...typography13RegularStyle,
      color: (props: SidebarTheme) => props.sidebarTextColor,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
    },
    listItemTextSelected: {
      ...typography13MediumStyle,
    },

    defaultListItem: {
      width: 'auto',
      height: '28px',
      margin: `${theme.spacing(0.5)}px ${theme.spacing(1)}px`,
      borderRadius: '5px',
      padding: `0 ${theme.spacing(1)}px`,
    },
    listItem: {
      '&:hover': {
        backgroundColor: (props: SidebarTheme) =>
          props.sidebarItemHoverBackground,
      },
      '&:hover svg': {
        color: (props: SidebarTheme) => props.sidebarTextColor,
      },
      '&:focus': {
        background: 'transparent',
      },
    },
    selected: {
      backgroundColor: (props: SidebarTheme) =>
        `${props.sidebarItemHoverBackground} !important`,
    },
    sectionTitle: {
      paddingTop: theme.spacing(1),
    },
    sectionTitleText: {
      ...typography12RegularStyle,
      color: LightGray,
    },
  }),
);
interface IconSettings {
  ignoreStrokeWidth?: boolean;
}

export interface SidebarListItemProps {
  htmlId: string;
  selected?: boolean;
  sectionTitle?: string;
  label?: string;
  handleClick?: ComponentProps<typeof ListItem>['onClick'];
  IconElement?: IconType | typeof SvgIcon;
  iconSettings?: IconSettings;
  iconStyleProps?: React.CSSProperties;
  tooltipTitle?: React.ReactNode;
  disabled?: boolean;
}

export const SidebarListItem: React.FC<SidebarListItemProps> = ({
  htmlId,
  selected = false,
  sectionTitle,
  label,
  handleClick,
  IconElement,
  children,
  iconSettings = {},
  iconStyleProps = {},
  tooltipTitle,
  disabled,
}) => {
  const sidebarTheme = React.useContext(SidebarThemeContext);
  const classes = useStyles(sidebarTheme);

  return (
    <TooltipWrapper title={tooltipTitle}>
      <ListItem
        disabled={disabled}
        id={htmlId}
        data-testid={htmlId}
        classes={sectionTitle ? {} : { button: classes.defaultListItem }}
        className={
          sectionTitle
            ? `${classes.sectionTitle} ${classes.defaultListItem}`
            : `${classes.listItem} ${selected ? classes.selected : ''}`
        }
        onClick={handleClick}
        button={!sectionTitle}
      >
        {IconElement && (
          <ListItemIcon classes={{ root: classes.listItemIconContainer }}>
            <IconElement
              style={iconStyleProps}
              className={classNames(classes.listItemIcon, {
                [classes.listItemIconStrokeWidth]:
                  !iconSettings?.ignoreStrokeWidth,
              })}
            />
          </ListItemIcon>
        )}
        <ListItemText
          primary={sectionTitle || label}
          classes={{
            root: classes.listItemTextContainer,
            primary: sectionTitle
              ? classes.sectionTitleText
              : `${classes.listItemText} ${
                  selected ? classes.listItemTextSelected : ''
                }`,
          }}
        />
        {children}
      </ListItem>
    </TooltipWrapper>
  );
};
