import { ValueFormatterParams } from '@ag-grid-community/core';
import LocaleCurrency from 'locale-currency';
import React from 'react';

export const GetDefaultCurrency = () =>
  LocaleCurrency.getCurrency(navigator.language) || 'USD';

let currencyFormatter = new Intl.NumberFormat();

export function GetCurrencyFormatter(
  currency: string,
  options?: Intl.NumberFormatOptions,
) {
  const opts = options || {};
  const currentOptions = currencyFormatter.resolvedOptions();

  // return existing formatter if options are the same
  // 1. currency matches
  // 2. maximum fraction digits matches
  //   a. if maximum fraction digits is not set, we can use the existing formatter
  //   b. if maximum fraction digits is set, we check if it matches the existing formatter
  if (
    currentOptions.currency === currency &&
    (!opts.maximumFractionDigits ||
      currentOptions.maximumFractionDigits === opts.maximumFractionDigits)
  ) {
    return currencyFormatter;
  }

  currencyFormatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
    ...opts,
  });
  return currencyFormatter;
}

interface BooleanFormatterProps {
  value: boolean;
}

interface LocaleDateFormatterProps {
  value: string;
}

interface LocaleTimeFormatterProps {
  value: string;
}

interface NumberFormatterProps {
  value: number;
  fixed?: number;
}

interface CurrencyFormatterProps {
  value: number;
  defaultVal?: any;
  currency?: string;
  formatOptions?: Intl.NumberFormatOptions;
}

interface SentenceCaseFormatterProps {
  value: string;
}

interface ImageFormatterProps {
  value: string[];
}

export interface StatusFormatter {
  value: string;
}

export const StatusFormatter = (props: StatusFormatter): string =>
  props.value ? props.value.toUpperCase() : '';

export const BooleanFormatter = (props: BooleanFormatterProps) =>
  props.value ? 'Yes' : 'No';

interface TimestampFormatterProps {
  value: number;
}

export const TimestampFormatter = (props: TimestampFormatterProps) =>
  props.value ? new Date(props.value * 1000).toLocaleDateString() : '';

export const LocaleDateFormatter = (props: LocaleDateFormatterProps) =>
  props.value ? new Date(props.value).toLocaleDateString() : '';

export const MonthDayFormatter = (props: TimestampFormatterProps) =>
  props.value
    ? new Date(props.value * 1000).toLocaleDateString('en-US', {
        month: 'long',
        day: 'numeric',
      })
    : ''; // Formats date in the following manner: June 17

export const LocaleTimeFormatter = (props: LocaleTimeFormatterProps) =>
  props.value ? new Date(props.value).toLocaleTimeString() : '';

export const SentenceCaseFormatter = (props: SentenceCaseFormatterProps) =>
  props.value && props.value.length
    ? props.value.charAt(0).toUpperCase() + props.value.substr(1)
    : '';

export const NumberFormatter = (props: NumberFormatterProps) =>
  Math.floor(props.value)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const FloatFormatter = (props: NumberFormatterProps) =>
  props.value
    .toFixed(2)
    .toString()
    .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');

export const CentsCurrencyFormatter = ({
  value,
  defaultVal = 0,
  currency,
  formatOptions,
}: CurrencyFormatterProps) => {
  const formatCurrency = currency || GetDefaultCurrency();
  const formatter = GetCurrencyFormatter(formatCurrency, formatOptions);

  if (!value) {
    return formatter.format(defaultVal);
  }
  return formatter.format(value);
};

export const StripeCurrencyFormatterAdapter = ({
  value,
  currency,
}: CurrencyFormatterProps) => {
  let valueToFormat = value;
  // ensure value is a number otherwise toFixed operations will not work
  // it can also be string when being passed from getQuickFilterText
  if (typeof valueToFormat !== 'number') {
    valueToFormat = Number(valueToFormat);
  }

  // this is required to round up the value https://linear.app/portaltechnologies/issue/POR-6616#comment-c92d029d
  // using toFixed is required to handle floating point errors. E.g. $355.5449999999
  // would get rounded to $355.54 instead of $355.55
  const roundedValue = Number(valueToFormat.toFixed(2));
  return CentsCurrencyFormatter({ value: roundedValue / 100, currency });
};

export const StripeCurrencyTableFormatter = (params: ValueFormatterParams) => {
  const stripeEntity =
    params.data.subscriptionFullData || params.data.invoiceFullData;
  const isCurrencyDefined =
    params.data.invoiceFullData &&
    !params.data.invoiceFullData?.fields?.currency; // Subscription invoices have currency property missing. If so, set USD as default

  return CentsCurrencyFormatter({
    value: params.value / 100,
    currency: stripeEntity?.fields.currency || (isCurrencyDefined && 'USD'),
  });
};

export const CurrencyFormatter = (props: CurrencyFormatterProps) => {
  if (!props.value) {
    return '0.00';
  }
  return `$${NumberFormatter(props)}`;
};

export const ImageFormatter = ({ value }: ImageFormatterProps) => (
  <img src={value[0]} alt="Image1" />
);

export const FloatFormatterTrunc = ({ value, fixed }: NumberFormatterProps) => {
  if (!value) {
    return '';
  }
  const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`);
  return value.toString().match(re)?.[0];
};

export const DownloadCSVNumberFormatter = ({
  value,
  currency,
}: {
  value: number;
  currency: string;
}) => {
  const formatter = new Intl.NumberFormat(navigator.language, {
    style: 'currency',
    currency,
  });
  const formattedNumber = formatter.format(value / 100);
  return formattedNumber;
};
