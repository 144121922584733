import Stripe from 'stripe';

import { UNAUTH_USER_ID } from 'src/constants';

import * as UserStoreTypes from 'src/store/user/types';
import * as ClientsStoreTypes from 'src/store/clients/types';

export const isUnAuthUser = (id: string) => id === UNAUTH_USER_ID;

export const initialState: UserStoreTypes.UserState = {
  loaded: false,
  loading: false,
  loadingError: null,
  updatingAttributes: false,
  updatingPayment: false,
  updatingSubscription: false,
  isUnAuth: false,
  data: null,
  instance: null,
  viewMode: 'default',
  id: '',
  ref: '',
  isClient: false,
};

export const isUserInGroup = (
  user: UserStoreTypes.UserState,
  groupName: string,
) => {
  try {
    return user.data?.groups?.includes(groupName);
  } catch (err) {
    return false;
  }
};

const userReducer = (
  // eslint-disable-next-line default-param-last
  state = initialState,
  action: UserStoreTypes.UserActionTypes | ClientsStoreTypes.ClientsActionTypes,
) => {
  switch (action.type) {
    case UserStoreTypes.UPDATE_USER_ID: {
      const { id } = action;
      const isUnAuth = isUnAuthUser(id);
      return {
        ...state,
        id,
        loaded: false,
        loading: false,
        isUnAuth,
        // isClient,
        viewMode: 'default',
      };
    }

    case UserStoreTypes.UPDATE_VIEW_MODE: {
      return {
        ...state,
        viewMode: action.mode,
      };
    }

    case UserStoreTypes.CLEAR_USER: {
      return {
        ...state,
        instance: {
          username: UNAUTH_USER_ID,
          attributes: {},
        },
        id: UNAUTH_USER_ID,
        // isClient: true,
        loaded: true,
        loading: false,
        updatingAttributes: false,
        updatingPayment: false,
        isUnAuth: true,
        viewMode: 'default',
      };
    }

    case UserStoreTypes.UPDATE_USER: {
      const { user, data, userId: id } = action;
      const isUnAuth = isUnAuthUser(id);
      return {
        ...state,
        instance: user,
        data,
        id,
        isUnAuth,
        // isClient,
        loaded: true,
        loading: false,
        ref: user.ref,
      };
    }

    case UserStoreTypes.LOAD_USER_ERROR: {
      const { error } = action;
      return {
        ...state,
        loadingError: error,
      };
    }

    case UserStoreTypes.UPDATED_USER_ATTRIBUTE: {
      const { attributes } = action;
      const existingAttributes = state.instance
        ? state.instance.attributes
        : {};
      return {
        ...state,
        instance: {
          ...state.instance,
          attributes: {
            ...existingAttributes,
            ...attributes,
          },
        },
        updatingAttributes: false,
      };
    }

    case UserStoreTypes.UPDATING_USER_ATTRIBUTE: {
      return {
        ...state,
        updatingAttributes: true,
      };
    }

    case UserStoreTypes.UPDATING_USER_SUBSCRIPTION: {
      return {
        ...state,
        updatingSubscription: action.updating,
      };
    }

    case UserStoreTypes.SET_STRIPE_CUSTOMER: {
      return {
        ...state,
        data: {
          ...state.data,
          stripeCustomer: {
            ...state.data?.stripeCustomer,
            sources: action.stripeCustomer.sources,
            default_source: action.stripeCustomer.default_source,
          },
        },
      };
    }

    case UserStoreTypes.UPDATE_USER_SUBSCRIPTION_DONE: {
      let updatedCustomer: Stripe.Customer | undefined;
      if (state.data?.stripeCustomer?.subscriptions?.data?.length) {
        // if the current stripe customer has a subscription update the subscription
        // that matches the one in the action payload
        updatedCustomer = { ...state.data.stripeCustomer };
        if (updatedCustomer?.subscriptions) {
          let subFound = false;
          const updatedCustomSubscriptionData =
            updatedCustomer.subscriptions.data.map((sub) => {
              if (sub.id === action.subscription.id) {
                subFound = true;
                return action.subscription;
              }

              return sub;
            });
          if (!subFound) {
            // if no sub was found that matched id then add new bug
            updatedCustomSubscriptionData.push(action.subscription);
          }
          updatedCustomer = {
            ...updatedCustomer,
            subscriptions: {
              ...updatedCustomer.subscriptions,
              data: updatedCustomSubscriptionData,
            },
          };
        }
      }

      const updatedSubscriptions = state.data?.stripeSubscriptions?.map(
        (sub) => {
          if (action.subscription.id === sub.id) {
            return action.subscription;
          }

          return sub;
        },
      );

      return {
        ...state,
        updatingSubscription: false,
        data: {
          ...state.data,
          ...(updatedCustomer
            ? {
                stripeCustomer: updatedCustomer,
                stripeSubscriptions: updatedSubscriptions,
              }
            : {}),
        },
      };
    }

    case UserStoreTypes.UPDATING_USER_PAYMENT: {
      return {
        ...state,
        updatingPayment: true,
      };
    }

    case UserStoreTypes.UPDATED_USER_PAYMENT: {
      const { paymentAttributes, error } = action;
      const existingAttributes = state.instance
        ? state.instance.attributes
        : {};

      if (
        !error &&
        paymentAttributes &&
        Object.keys(paymentAttributes).length > 0
      ) {
        return {
          ...state,
          instance: {
            ...state.instance,
            attributes: {
              ...existingAttributes,
              ...paymentAttributes,
            },
          },
          updatingPayment: false,
        };
      }

      return {
        ...state,
        updatingPayment: false,
      };
    }

    case UserStoreTypes.CHANGE_USER_LOADED_STATE: {
      return {
        ...state,
        loaded: action.loaded,
        loading: !action.loaded,
      };
    }

    case ClientsStoreTypes.UPDATE_COMPANY_SUCCESS: {
      const {
        payload: { isPlaceholder },
      } = action;
      return {
        ...state,
        data: {
          ...state.data,
          havePlaceholderCompany: isPlaceholder,
        },
      };
    }

    default:
      return state;
  }
};

export default userReducer;
