import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import * as PlansUtils from 'src/utils/PlansUtils';
import { useCustomerSubscription } from 'src/hooks/useCustomerSubscription';

/**
 *
 * @returns information regarding current user plan.
 * @note - all plans other than ACTIVE_BILLING_PLANS are now considered legacy plans
 */
export const usePlanStatus = () => {
  const isClient = useSelector((state: RootState) => state.user.isClient);

  const { stripeCustomer, allSubscriptions, customerSubscription } =
    useCustomerSubscription();

  const planStatus: {
    isProPlan: boolean;
    isBasicPlan: boolean;
    isFixedAccessProPlan: boolean;
    isFixedAccessBasicPlan: boolean;
    isTrialing: boolean;
    isTrialPeriodExpired: boolean;
    requireProUpgrade: boolean;
    requireAdvancedUpgrade: boolean;
    isPaidPlan: boolean;
    isCancelledPlan: boolean;
    isStarterPlan: boolean;
    isCopilotProfessionalPlan: boolean;
    isAdvancedPlan: boolean;
    billingCycle: string | undefined;
  } = {
    isProPlan: false,
    isBasicPlan: false,
    isFixedAccessProPlan: false,
    isFixedAccessBasicPlan: false,
    // isTrialing indicates if at current moment user is on trial or not
    isTrialing: false,
    // checks that user is on trial or on basic plan
    requireProUpgrade: false,
    // builds on top of requireProUpgrade to also check if user is on starter plan
    // or pro plan
    requireAdvancedUpgrade: false,
    // isTrialPeriodExpired indicates if trial got expired or not
    // is true even if user is on paid plan but the initial offered trial got expired
    isTrialPeriodExpired: false,
    // isCancelledPlan indicates if paid subscription is cancelled because of any reason (manual cancellation or non-payment)
    isCancelledPlan: false,
    // isPaidPlan user has an active subscription and not currently trialing
    isPaidPlan: false,
    // Current active low tier plan.
    isStarterPlan: false,
    // Current active professional plan. Note that it's different from legacy pro plan.
    isCopilotProfessionalPlan: false,
    // Currently active advanced plan.
    isAdvancedPlan: false,
    billingCycle: undefined,
  };

  if (stripeCustomer && !isClient) {
    planStatus.isTrialing = PlansUtils.hasActiveTrial(allSubscriptions);
    planStatus.isTrialPeriodExpired =
      PlansUtils.isTrialPeriodExpired(allSubscriptions);

    // isCancelledPlan indicates if subscription is cancelled because of any reason
    planStatus.isCancelledPlan =
      PlansUtils.hasCancelledPlan(allSubscriptions) && !customerSubscription;

    const activeSubscriptionPlanPrice =
      customerSubscription?.items?.data[0].price;

    planStatus.billingCycle = PlansUtils.getBillingCycle(
      activeSubscriptionPlanPrice,
    );
    planStatus.isProPlan =
      PlansUtils.isProPlan(activeSubscriptionPlanPrice) &&
      !planStatus.isCancelledPlan;
    planStatus.isBasicPlan =
      PlansUtils.isBasicPlan(activeSubscriptionPlanPrice) &&
      !planStatus.isCancelledPlan;
    planStatus.isFixedAccessBasicPlan = PlansUtils.isFixedAccessBasicPlan(
      activeSubscriptionPlanPrice,
    );
    planStatus.isFixedAccessProPlan = PlansUtils.isFixedAccessProPlan(
      activeSubscriptionPlanPrice,
    );

    planStatus.isStarterPlan = PlansUtils.isStarterPlan(
      activeSubscriptionPlanPrice,
    );

    planStatus.isCopilotProfessionalPlan = PlansUtils.isCopilotProfessionalPlan(
      activeSubscriptionPlanPrice,
    );

    planStatus.isAdvancedPlan = PlansUtils.isAdvancedPlan(
      activeSubscriptionPlanPrice,
    );

    planStatus.requireProUpgrade =
      planStatus.isTrialing || planStatus.isBasicPlan;

    planStatus.requireAdvancedUpgrade =
      (planStatus.requireProUpgrade ||
        planStatus.isStarterPlan ||
        planStatus.isProPlan) &&
      // also check if plan is not an enterprise plan
      // because for enterprise plans we use the plan_status
      // pro_xxx_0_ent along with advanced_xxx_0_ent if we check
      // for the isProPlan only to determine if advanced upgrade is required
      // then it will return true for enterprise plans as well unless we check
      // explicitly if th plan is not an enterprise plan
      !PlansUtils.isEnterprisePlan(activeSubscriptionPlanPrice);

    planStatus.isPaidPlan =
      planStatus.isProPlan ||
      planStatus.isBasicPlan ||
      planStatus.isFixedAccessBasicPlan ||
      planStatus.isFixedAccessProPlan ||
      planStatus.isStarterPlan ||
      planStatus.isCopilotProfessionalPlan ||
      planStatus.isAdvancedPlan;
  }

  return { ...planStatus };
};
