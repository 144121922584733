import React from 'react';
import {
  BANK_CONNECTION_STATUS_DESCRIPTION_TEXT,
  BANK_CONNECTION_STATUS_DESCRIPTION_TITLE,
} from 'src/constants';
import { BaseDescription } from 'src/components/CustomizationForm/BaseDescription';

export const BankingStatusSettingsDescription: React.FC = () => (
  <BaseDescription
    title={BANK_CONNECTION_STATUS_DESCRIPTION_TITLE}
    descriptions={BANK_CONNECTION_STATUS_DESCRIPTION_TEXT}
  />
);
