import Stripe from 'stripe';
import { StripeCardElement } from '@stripe/stripe-js';
import { BaseEntity, BusinessAddress } from 'src/constants/dataTypes';
import { Client, Company } from 'src/store/clients/types';
import { InvoiceStatus, SubscriptionStatus } from 'src/constants';

interface BaseStripeEntityFields {
  id?: string;
  companyId: string;
  clientUserId: string;
  lineItems: InvoiceLineItem[];
  collectionMethod: string;
  currency: string;
  recipientId?: string;
  allowPaymentViaCC?: boolean;
  allowPaymentViaACH?: boolean;
  absorbTransactionFees?: boolean;
}

export interface InvoiceLineItem {
  id: number;
  description: string;
  quantity: number;
  rate: number;
  total: number;
}

export interface Invoice extends BaseStripeEntityFields {
  dateOfIssue: string;
  receiverGivenName?: string;
  receiverFamilyName?: string;
  dueDate: string;
  amount?: number;
  memo: string;
  dueDateCount?: number;
  createDraft: boolean;
  taxPercentage: number | string;
  status?: string;
  quickbooksId?: string;
  attachment?: string;
  attachmentIdentityId?: string;
  interval?: string;
  intervalCount?: number;
  fileKey?: string;
}

// this is a Type guard to check if the object is an invoice or a subscription
// when used in a conditional statement, it will narrow the type of the object
// to either an invoice or a subscription
export function isInvoice(
  obj: InvoiceEntity | SubscriptionEntity,
): obj is InvoiceEntity {
  return obj && 'dueDate' in obj.fields;
}

export interface InvoiceAdditionalFields extends Partial<Stripe.Invoice> {
  id: string;
  templateName: string;
}

export interface Subscription extends BaseStripeEntityFields {
  interval: string;
  createDraft?: boolean;
  memo?: string;
  attachment: string | null;
  taxPercentage: number | string;
  daysUntilDue: number;
  cancelledAt: string;
  scheduleEndDate?: number;
  scheduleStartDate?: number;
  scheduleIterations?: number;
  prorate?: boolean;
  amount?: number;
  price: number;
  resetBillingCycle?: boolean;
  dateOfIssue?: string;
}

export interface InvoicePaymentFees {
  fee_ach_fixed_amount: number;
  fee_ach_perc_amount: number;
  fee_credit_base_perc_amount: number;
  fee_credit_conversion_perc_amount: number;
  fee_credit_fixed_amount: number;
  fee_credit_international_perc_amount: number;
  fee_recurring_perc_amount: number;
  fee_store_perc_amount: number;
}

// For now fields are added once invoice is paid, but more fields can be added in future irrespective of invoice paid action
// so keeping all fields as optional to avoid any breaking changes
export interface InvoiceStructFields {
  paymentMethod?: string;
  paymentMethodEnding?: string;
  feeAmount?: number;
  feePayer?: number;
  paymentDate?: number;
  depositDate?: number;
  fees: InvoicePaymentFees;
}

export interface InvoiceEntity extends BaseEntity {
  fields: Invoice;
  additionalFields?: InvoiceAdditionalFields;
  structFields?: InvoiceStructFields;
}

export interface SubscriptionAdditionalFields
  extends Partial<Stripe.Subscription> {
  templateName?: '';
}

export interface SubscriptionEntity extends BaseEntity {
  fields: Subscription;
  additionalFields?: SubscriptionAdditionalFields;
}

export interface InvoiceTableRowModel {
  invoiceId: string;
  client: Client | null;
  companyFields?: Company | null;
  invoiceFullData?: InvoiceEntity;
  date?: string;
  status: InvoiceStatus;
  amount?: number;
  accountId: string;
}

export interface SubscriptionTableRow {
  subscriptionId: string;
  client: Client | null;
  price: number;
  companyFields?: Company | null;
  billingPeriod: string;
  paymentMethod?: string;
  subscriptionFullData?: SubscriptionEntity;
  date: string;
  status?: SubscriptionStatus;
  nextPayment?: number;
  startDate?: number;
  endDate?: number;
  cancelledAt?: string;
  memo?: string;
  taxPercentage?: number | string;
}

export interface CardElementModel {
  element: StripeCardElement | null;
  error: string;
}

export interface BankFormModel {
  accountHolderName: string;
  accountHolderType: 'company' | 'individual' | undefined;
  routingNumber: string;
  accountNumber: string;
  fromAmount: number;
  toAmount: number;
}

export interface BankFormErrorModel {
  accountHolderName?: string;
  accountHolderType?: string;
  routingNumber?: string;
  accountNumber?: string;
}

export interface BankElementModel {
  element: BankFormModel;
  error: BankFormErrorModel;
  stripeError: string;
}

export interface PayingModel {
  sourceId?: string;
  totalAmount?: number;
  invoiceId: string;
  isManual?: boolean;
}

export interface PaymentInfo {
  id: string;
  ownerId: string;
  customerId: string;
  address?: BusinessAddress;
  subscriptionId: string;
  accountId: string;
  accountType: string;
  businessName: string;
  createDate: string;
}

export interface UpdateSourceRequestModel {
  customerId: string;
  sourceId: string;
  sourceData: Stripe.CustomerSourceUpdateParams;
  isPrimary: boolean;
  isLastSource: boolean;
}

export interface AddCreditCardForm {
  sourceId: string;
  name: string;
  postalCode: string;
  cardEnding: string;
  isPrimary: boolean;
  brand: string;
}

export interface PaymentFees {
  achFixed: number;
  achPercentage: number;
  ccBasePercentage: number;
  ccConversionPercentage: number;
  ccFixed: number;
  ccInternationalPercentage: number;
  recurringPercentage: number;
  storePercentage: number;
}

export interface PaymentsState {
  invoices: InvoiceEntity[];
  clientInvoices: InvoiceEntity[];
  subscriptions: SubscriptionEntity[];
  paymentInfo: PaymentInfo;
  updatingPayment: boolean;
  updatingPaymentError: string;
  isLoaded: boolean;
  isLoading: boolean;
  isLoadingForClient: boolean;
  isLoadedForClient: boolean;
  isCreating: boolean;
  isCreated: boolean;
  isPaying: boolean;
  isPayed: boolean;
  isSubscriptionsLoading: boolean;
  isSubscriptionsLoaded: boolean;
  isSubscriptionsCanceling: boolean;
  isSubscriptionsCanceled: boolean;
  isSubscriptionCreating: boolean;
  isSubscriptionCreated: boolean;
  isActivating: boolean;
  isActivated: boolean;
  error: string;
  activatingError: string;
  isCopyingPaymentLink: boolean;
  canPreviewInvoice: boolean;
  invoicePreviewEnabled: boolean;
  accountInfo?: Stripe.Account;
}

export interface SuccessSentInvoiceResponse {
  id: string;
  status: string;
}

export const LOAD_INVOICES_REQUEST = 'LOAD_INVOICES_REQUEST';
export const LOAD_INVOICES_DONE = 'LOAD_INVOICES_DONE';
export const LOAD_INVOICES_FAIL = 'LOAD_INVOICES_FAIL';
export const LOAD_CLIENT_INVOICES_REQUEST = 'LOAD_CLIENT_INVOICES_REQUEST';
export const LOAD_CLIENT_INVOICES_DONE = 'LOAD_CLIENT_INVOICES_DONE';
export const LOAD_CLIENT_INVOICES_FAIL = 'LOAD_CLIENT_INVOICES_FAIL';
export const ADD_INVOICE_REQUEST = 'ADDING_INVOICE';
export const ADD_INVOICE_SUCCESS = 'ADD_INVOICE_SUCCESS';
export const ADD_INVOICE_FAILURE = 'ADD_INVOICE_FAILURE';
export const UPDATE_INVOICE_REQUEST = 'UPDATE_INVOICE_REQUEST';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_SUCCESS';
export const UPDATE_INVOICE_FAILURE = 'UPDATE_INVOICE_FAILURE';
export const PAY_INVOICE_REQUEST = 'PAY_INVOICE_REQUEST';
export const PAY_INVOICE_SUCCESS = 'PAY_INVOICE_SUCCESS';
export const PAY_INVOICE_FAILURE = 'PAY_INVOICE_FAILURE';
export const COPY_PAYMENT_LINK_REQUEST = 'COPY_PAYMENT_LINK_REQUEST';
export const COPY_PAYMENT_LINK_SUCCESS = 'COPY_PAYMENT_LINK_SUCCESS';
export const COPY_PAYMENT_LINK_FAILURE = 'COPY_PAYMENT_LINK_FAILURE';
export const UPDATE_PAYMENT_SUCCESS = 'UPDATE_PAYMENT_SUCCESS';
export const UPDATE_ACCOUNT_INFO = 'UPDATE_ACCOUNT_INFO';
export const DELETE_PAYMENT_SUCCESS = 'DELETE_PAYMENT_SUCCESS';
export const UPDATE_PAYMENT_START = 'UPDATE_PAYMENT_START';
export const UPDATE_PAYMENT_FAIL = 'UPDATE_PAYMENT_FAIL';
export const CLEAR_INVOICES = 'CLEAR_INVOICES';
export const LOAD_SUBSCRIPTIONS_REQUEST = 'LOAD_SUBSCRIPTIONS_REQUEST';
export const LOAD_SUBSCRIPTIONS_DONE = 'LOAD_SUBSCRIPTIONS_DONE';
export const LOAD_SUBSCRIPTIONS_FAIL = 'LOAD_SUBSCRIPTIONS_FAIL';
export const ADD_SUBSCRIPTION_REQUEST = 'ADDING_SUBSCRIPTION';
export const ADD_SUBSCRIPTION_SUCCESS = 'ADD_SUBSCRIPTION_SUCCESS';
export const ADD_SUBSCRIPTION_FAILURE = 'ADD_SUBSCRIPTION_FAILURE';
export const UPDATE_SUBSCRIPTION_REQUEST = 'UPDATING_SUBSCRIPTION';
export const UPDATE_SUBSCRIPTION_SUCCESS = 'UPDATE_SUBSCRIPTION_SUCCESS';
export const UPDATE_SUBSCRIPTION_FAILURE = 'UPDATE_SUBSCRIPTION_FAILURE';
export const CANCEL_SUBSCRIPTION_REQUEST = 'CANCELING_SUBSCRIPTION';
export const CANCEL_SUBSCRIPTION_SUCCESS = 'CANCEL_SUBSCRIPTION_SUCCESS';
export const CANCEL_SUBSCRIPTION_FAILURE = 'CANCEL_SUBSCRIPTION_FAILURE';
export const VOID_INVOICE = 'VOID_INVOICE';
export const DELETE_INVOICE_REQUEST = 'DELETE_INVOICE_REQUEST';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILURE = 'DELETE_INVOICE_FAILURE';
export const SEND_INVOICE_REQUEST = 'SEND_INVOICE_REQUEST';
export const SEND_INVOICE_SUCCESS = 'SEND_INVOICE_SUCCESS';
export const SEND_INVOICE_FAILURE = 'SEND_INVOICE_FAILURE';
export const ACTIVATE_INVOICE_REQUEST = 'ACTIVATE_INVOICE_REQUEST';
export const ACTIVATE_INVOICE_SUCCESS = 'ACTIVATE_INVOICE_SUCCESS';
export const ACTIVATE_INVOICE_FAILURE = 'ACTIVATE_INVOICE_FAILURE';
export const UPDATE_INVOICES = 'UPDATE_INVOICES';
export const DELETE_INVOICES = 'DELETE_INVOICES';
export const UPDATE_SUBSCRIPTIONS = 'UPDATE_SUBSCRIPTIONS';
export const DELETE_SUBSCRIPTIONS = 'DELETE_SUBSCRIPTIONS';
export const SET_CAN_PREVIEW_INVOICE = 'SET_CAN_PREVIEW_INVOICE';
export const SET_INVOICE_PREVIEW_STATUS = 'SET_INVOICE_PREVIEW_STATUS';

interface StartLoadInvoicesAction {
  type: typeof LOAD_INVOICES_REQUEST;
}

interface LoadInvoicesDoneAction {
  type: typeof LOAD_INVOICES_DONE;
  payload: InvoiceEntity[];
}

interface LoadInvoicesFailAction {
  type: typeof LOAD_INVOICES_FAIL;
  error: string;
}

interface StartLoadClientInvoicesAction {
  type: typeof LOAD_CLIENT_INVOICES_REQUEST;
}

interface LoadClientInvoicesDoneAction {
  type: typeof LOAD_CLIENT_INVOICES_DONE;
  payload: InvoiceEntity[];
}

interface LoadClientInvoicesFailAction {
  type: typeof LOAD_CLIENT_INVOICES_FAIL;
  error: string;
}

interface StartLoadSubscriptionsAction {
  type: typeof LOAD_SUBSCRIPTIONS_REQUEST;
}

interface LoadSubscriptionsDoneAction {
  type: typeof LOAD_SUBSCRIPTIONS_DONE;
  payload: SubscriptionEntity[];
}

interface LoadSubscriptionsFailAction {
  type: typeof LOAD_SUBSCRIPTIONS_FAIL;
  error: string;
}

interface StartCancelSubscriptionAction {
  type: typeof CANCEL_SUBSCRIPTION_REQUEST;
}

interface CancelSubscriptionDoneAction {
  type: typeof CANCEL_SUBSCRIPTION_SUCCESS;
  payload: Stripe.Subscription;
}

interface CancelSubscriptionFailAction {
  type: typeof CANCEL_SUBSCRIPTION_FAILURE;
  error: string;
}

interface StartAddInvoiceAction {
  type: typeof ADD_INVOICE_REQUEST;
}

interface AddInvoiceDoneAction {
  type: typeof ADD_INVOICE_SUCCESS;
  payload: InvoiceEntity;
}

interface AddInvoiceFailAction {
  type: typeof ADD_INVOICE_FAILURE;
  error: string;
}

interface StartAddSubscriptionAction {
  type: typeof ADD_SUBSCRIPTION_REQUEST;
}

interface AddSubscriptionDoneAction {
  type: typeof ADD_SUBSCRIPTION_SUCCESS;
  payload: SubscriptionEntity;
}

interface AddSubscriptionFailAction {
  type: typeof ADD_SUBSCRIPTION_FAILURE;
  error: string;
}

interface StartUpdatingSubscriptionAction {
  type: typeof UPDATE_SUBSCRIPTION_REQUEST;
}

interface UpdateSubscriptionDoneAction {
  type: typeof UPDATE_SUBSCRIPTION_SUCCESS;
  payload: SubscriptionEntity;
}

interface UpdateSubscriptionFailAction {
  type: typeof UPDATE_SUBSCRIPTION_FAILURE;
  error: string;
}

interface StartUpdateInvoiceAction {
  type: typeof UPDATE_INVOICE_REQUEST;
}

interface UpdateInvoiceDoneAction {
  type: typeof UPDATE_INVOICE_SUCCESS;
  payload: InvoiceEntity;
  invoiceId: string;
}

interface UpdateInvoiceFailAction {
  type: typeof PAY_INVOICE_FAILURE;
  error: string;
}

interface StartPayInvoiceAction {
  type: typeof PAY_INVOICE_REQUEST;
}

interface PayInvoiceDoneAction {
  type: typeof PAY_INVOICE_SUCCESS;
  payload: Stripe.Invoice;
}

interface PayInvoiceFailAction {
  type: typeof UPDATE_INVOICE_FAILURE;
  error: string;
}

interface StartCopyPaymentLinkAction {
  type: typeof COPY_PAYMENT_LINK_REQUEST;
}

interface CopyPaymentLinkDoneAction {
  type: typeof COPY_PAYMENT_LINK_SUCCESS;
}

interface CopyPaymentLinkFailAction {
  type: typeof COPY_PAYMENT_LINK_FAILURE;
  error: string;
}

export interface UpdatePaymentSuccess {
  type: typeof UPDATE_PAYMENT_SUCCESS;
  data: PaymentInfo | undefined;
}

export interface UpdateAccountInfo {
  type: typeof UPDATE_ACCOUNT_INFO;
  payload: Stripe.Account;
}

export interface DeletePaymentSuccess {
  type: typeof DELETE_PAYMENT_SUCCESS;
}

export interface UpdatePaymentFail {
  type: typeof UPDATE_PAYMENT_FAIL;
  error: string;
}

export interface UpdatePaymentStart {
  type: typeof UPDATE_PAYMENT_START;
}

export interface VoidInvoice {
  type: typeof VOID_INVOICE;
  payload: string;
}

export interface DeleteInvoiceSuccess {
  type: typeof DELETE_INVOICE_SUCCESS;
  payload: string;
}

export interface DeleteInvoiceFail {
  type: typeof DELETE_INVOICE_FAILURE;
  error: string;
}

export interface DeleteInvoiceStart {
  type: typeof DELETE_INVOICE_REQUEST;
}

export interface SendInvoiceSuccess {
  type: typeof SEND_INVOICE_SUCCESS;
  payload: SuccessSentInvoiceResponse;
}

export interface SendInvoiceFail {
  type: typeof SEND_INVOICE_FAILURE;
  error: string;
}

export interface SendInvoiceStart {
  type: typeof SEND_INVOICE_REQUEST;
}

export interface ActivateInvoiceSuccess {
  type: typeof ACTIVATE_INVOICE_SUCCESS;
  payload: Stripe.Invoice;
  originalInvoiceId: string;
}

export interface ActivateInvoiceFail {
  type: typeof ACTIVATE_INVOICE_FAILURE;
  error: string;
}

export interface ActivateInvoiceStart {
  type: typeof ACTIVATE_INVOICE_REQUEST;
}

export interface ClearInvoicesAction {
  type: typeof CLEAR_INVOICES;
}

export interface UpdateInvoicesAction {
  type: typeof UPDATE_INVOICES;
  payload: SubscriptionEntity[];
}

export interface DeleteInvoicesAction {
  type: typeof DELETE_INVOICES;
  payload: SubscriptionEntity[];
}

export interface UpdateSubscriptionsAction {
  type: typeof UPDATE_SUBSCRIPTIONS;
  payload: SubscriptionEntity[];
}

export interface DeleteSubscriptionsAction {
  type: typeof DELETE_SUBSCRIPTIONS;
  payload: SubscriptionEntity[];
}

interface SetCanPreviewinvoiceAction {
  type: typeof SET_CAN_PREVIEW_INVOICE;
  payload: boolean;
}

interface SetInvoicePreviewStatusAction {
  type: typeof SET_INVOICE_PREVIEW_STATUS;
  payload: boolean;
}

export interface BillingLineItemModel {
  id: string;
  description: string;
  quantity: number;
  rate: number;
  total: number;
}
export interface BillingFieldsModel {
  clientUserId?: string;
  companyId?: string;
  memo?: string;
  attachment?: string;
  dueDate?: string;
  lineItems?: BillingLineItemModel[];
  createDraft: boolean;
  taxPercentage: number;
  collectionMethod?: string;
  allowPaymentViaCC: boolean;
  allowPaymentViaACH: boolean;
  absorbTransactionFees: boolean;
  paidManually?: boolean;
  currency?: string;
  dateOfIssue?: string;
  ignoreStripe?: boolean;
  creatorId?: string;
  attachmentIdentityId?: string;
  scheduleStartDate?: string;
}
export interface BillingTemplateAdditionalFields {
  templateName: string;
}
export interface BillingTemplate {
  id: string;
  object: string;
  creatorId?: string;
  createdAt: string;
  updatedAt?: string;
  additionalFields: BillingTemplateAdditionalFields;
  fields: BillingFieldsModel;
  DefaultListIndexPkey: string;
  FilteredListIndexPkey: string;
  identityId: string;
  StructFields: any;
}

export type PaymentsActionTypes =
  | StartLoadInvoicesAction
  | LoadInvoicesDoneAction
  | LoadInvoicesFailAction
  | StartLoadClientInvoicesAction
  | LoadClientInvoicesDoneAction
  | LoadClientInvoicesFailAction
  | StartLoadSubscriptionsAction
  | LoadSubscriptionsDoneAction
  | LoadSubscriptionsFailAction
  | StartCancelSubscriptionAction
  | CancelSubscriptionDoneAction
  | CancelSubscriptionFailAction
  | StartAddInvoiceAction
  | AddInvoiceDoneAction
  | AddInvoiceFailAction
  | StartAddSubscriptionAction
  | AddSubscriptionDoneAction
  | AddSubscriptionFailAction
  | UpdateSubscriptionsAction
  | DeleteSubscriptionsAction
  | StartUpdateInvoiceAction
  | UpdateInvoiceDoneAction
  | UpdateInvoiceFailAction
  | UpdateInvoicesAction
  | DeleteInvoicesAction
  | StartPayInvoiceAction
  | PayInvoiceDoneAction
  | PayInvoiceFailAction
  | StartCopyPaymentLinkAction
  | CopyPaymentLinkDoneAction
  | CopyPaymentLinkFailAction
  | UpdatePaymentStart
  | UpdatePaymentSuccess
  | DeletePaymentSuccess
  | UpdatePaymentFail
  | VoidInvoice
  | DeleteInvoiceStart
  | DeleteInvoiceSuccess
  | DeleteInvoiceFail
  | SendInvoiceStart
  | SendInvoiceSuccess
  | SendInvoiceFail
  | ActivateInvoiceStart
  | ActivateInvoiceSuccess
  | ActivateInvoiceFail
  | ClearInvoicesAction
  | SetCanPreviewinvoiceAction
  | SetInvoicePreviewStatusAction
  | UpdateAccountInfo
  | StartUpdatingSubscriptionAction
  | UpdateSubscriptionDoneAction
  | UpdateSubscriptionFailAction;
