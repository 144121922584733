import {
  Grid,
  IconButton,
  MenuItem,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { createSelector } from '@reduxjs/toolkit';
import { useFormikContext } from 'formik';
import React, { ComponentProps, useMemo } from 'react';
import { ChevronDownIcon, TrashIcon } from 'src/components/Icons';
import { TagIcon } from 'src/components/Icons/TagIcon';
import { MultiSelectOption } from 'src/components/Select/types';
import BaseTypography from 'src/components/Text/BaseTypography';
import { BaseTextField } from 'src/components/TextField';
import { BaseChip } from 'src/components/UI';
import { VisibilityComparator, VisibilityRuleType } from 'src/constants';
import { useAppSelector } from 'src/hooks/useStore';
import { useGetPortalConfigQuery } from 'src/services/api';
import { RootState } from 'src/store';

import {
  BlackHeadings,
  DarkFont,
  DividersAndCardBorders,
  GraySmall,
  HoverBackground,
  HoverBorder,
  NonHoverBorder,
  white,
} from 'src/theme/colors';
import { DropdownMenuShadow } from 'src/theme/shadows';
import { ensureUnreachable } from 'src/utils/common_utils';
import {
  CustomVisibilitySchema,
  isCustomFieldRule,
} from 'src/utils/app_visibility';
import { useListCustomFieldsQuery } from 'src/services/api/clientsApi';

type MultiSelectDropdownOption = Omit<MultiSelectOption, 'order'>;

type CustomFieldId = string;

type RuleField = {
  id: VisibilityRuleType.Client | VisibilityRuleType.Company | CustomFieldId;
  options: MultiSelectDropdownOption[];
};

type ComparatorRules = {
  id: VisibilityComparator;
  singularLabel: string;
  pluralLabel: string;
};

/**
 * Comparator rules per visibility rule type
 * defines the comparator rules available for each visibility rule type
 * e.g if the visibility rule type is client/company, the available comparator rules are:
 * - IncludesAnyOf (Is) - singularLabel | (Is any of) - pluralLabel
 * if the visibility rule type is custom field, the available comparator rules are:
 * - IncludesAllOf (Includes) - singularLabel | (Includes all of) - pluralLabel
 * - DoesntContainAnyOf (Does not include) - singularLabel | (Doesn’t include any of) - pluralLabel
 */
const COMPARATOR_RULES_PER_VISIBILITY_RULE: Record<
  VisibilityRuleType,
  ComparatorRules[]
> = {
  [VisibilityRuleType.Client]: [
    {
      id: VisibilityComparator.IncludesAnyOf,
      singularLabel: 'Is',
      pluralLabel: 'Is any of',
    },
  ],
  [VisibilityRuleType.Company]: [
    {
      id: VisibilityComparator.IncludesAnyOf,
      singularLabel: 'Is',
      pluralLabel: 'Is any of',
    },
  ],
  [VisibilityRuleType.CustomField]: [
    {
      id: VisibilityComparator.IncludesAnyOf,
      singularLabel: 'Includes',
      pluralLabel: 'Includes any of',
    },
    {
      id: VisibilityComparator.IncludesAllOf,
      singularLabel: 'Includes',
      pluralLabel: 'Includes all of',
    },
    {
      id: VisibilityComparator.DoesntContainAnyOf,
      singularLabel: 'Does not include',
      pluralLabel: 'Doesn’t include any of',
    },
  ],
  [VisibilityRuleType.NoVisibility]: [],
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      '& .MuiTextField-root .MuiOutlinedInput-root': {
        borderRadius: 0,
      },
    },
    select: {
      '& .MuiTextField-root .MuiOutlinedInput-root': {
        borderRadius: '4px 0 0 4px',
      },
    },
    autoComplete: {
      '& .MuiTextField-root .MuiOutlinedInput-root': {
        borderRadius: '0 4px 4px 0',
        paddingRight: theme.spacing(1.5),
        paddingLeft: theme.spacing(1),
      },
    },
    dropdownIcon: {
      height: '15px',
      width: '15px',
      color: GraySmall,
    },
    list: {
      padding: theme.spacing(0.75, 0),
    },
    listPaper: {
      border: `1px solid ${NonHoverBorder}`,
      boxShadow: DropdownMenuShadow,
      '& .MuiAutocomplete-groupUl': {
        '& .MuiAutocomplete-option': {
          paddingLeft: theme.spacing(2),
        },
      },
    },
    inputRoots: {
      '& .MuiAutocomplete-endAdornment': {
        marginRight: theme.spacing(0.25),
        backgroundColor: white,
      },
    },
    baseChip: {
      borderRadius: 100,
      color: BlackHeadings,
      backgroundColor: HoverBackground,
      border: `1px solid ${NonHoverBorder}`,
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: DividersAndCardBorders,
      },
      '&.MuiChip-root': {
        height: 'auto',
        '& span': {
          padding: 0,
        },
      },
      '&.MuiAutocomplete-tag': {
        margin: 0,
      },
    },
    closeIcon: {
      display: 'none',
    },
    avatarContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(0, 1.5),
      gap: theme.spacing(0.75),
    },

    chipLabel: {
      flexShrink: 0,
      maxWidth: '110px',
    },
    deleteIcon: {
      border: `1px solid ${NonHoverBorder}`,
      borderRadius: theme.shape.borderRadius,
      height: theme.spacing(4.5),
      width: theme.spacing(4.5),
      display: 'flex',
      alignItems: 'center',
      boxSizing: 'border-box',
      justifyContent: 'center',
    },
    trashIcon: {
      fontSize: '20px',
      color: HoverBorder,
    },
    or: {
      color: DarkFont,
      marginBottom: theme.spacing(0.75),
    },

    truncatedText: {
      display: 'inline-block',
      verticalAlign: 'middle',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '100%',
    },
    tagIcon: {
      display: 'flex',
    },
    icon: {
      position: 'relative',
      '&:nth-child(2)': {
        left: '-2px',
      },
      '&:nth-child(3)': {
        left: '-4px',
      },
    },
  }),
);

type Props = {
  ruleIndex: number;
  onDeleteRule: () => void;
};

export function getComparatorRules(
  ruleType: VisibilityRuleType,
  selectedValues: string[] = [],
): ComparatorRules[] {
  const rules = COMPARATOR_RULES_PER_VISIBILITY_RULE[ruleType];
  // when tags are selected as visibility rule, we want to avoid
  // rendering duplicate singular comparator labels for
  // IncludesAnyOf and IncludesAllOf. To achieve this, we filter out
  // IncludesAllOf when only one tag field value is selected.
  if (
    ruleType === VisibilityRuleType.CustomField &&
    selectedValues.length <= 1
  ) {
    return rules.filter((r) => r.id !== VisibilityComparator.IncludesAllOf);
  }
  return rules;
}

function getSelectedFieldName(type: VisibilityRuleType) {
  switch (type) {
    case VisibilityRuleType.Client:
      return 'Clients';
    case VisibilityRuleType.Company:
      return 'Companies';
    case VisibilityRuleType.CustomField:
      return 'Tags';
    case VisibilityRuleType.NoVisibility:
      return '';
    default:
      return ensureUnreachable(type);
  }
}

function isVisibilityRuleType(type: any): type is VisibilityRuleType {
  return Object.values(VisibilityRuleType).includes(type);
}

export const AppVisibilityRule: React.FC<Props> = ({
  ruleIndex,
  onDeleteRule,
}) => {
  const classes = useStyles();
  const { values, setFieldValue, handleChange, errors, touched } =
    useFormikContext<CustomVisibilitySchema>();

  const rule = values?.visibilityConfig?.[ruleIndex];

  const selector = createSelector(
    [
      (state: RootState) => state.clients.companies,
      (state: RootState) => state.clients.activeClients,
    ],
    (allCompanies, clients) => ({
      allCompanies,
      clients,
    }),
  );
  const { allCompanies, clients } = useAppSelector(selector);

  const { data: portalCustomFields } = useListCustomFieldsQuery();
  const { data: portalConfigurations } = useGetPortalConfigQuery();
  const areCompaniesEnabled =
    !portalConfigurations?.structFields?.disableCompanies;

  const tagsCustomFields = useMemo(
    () =>
      Object.values(portalCustomFields || {}).filter(
        (field) => field.type === 'multiSelect',
      ),
    [portalCustomFields],
  );

  const clientOptions: MultiSelectDropdownOption[] = useMemo(
    () =>
      clients
        .filter((x) => !!x.fields.givenName && !!x.fields.familyName)
        .map((client) => ({
          id: client.id,
          label: `${client.fields.givenName} ${client.fields.familyName}`,
          color: client.fields.fallbackColor,
        })),
    [clients],
  );

  const companiesOptions: MultiSelectDropdownOption[] = useMemo(
    () =>
      allCompanies
        .filter(
          (company) =>
            !!company.fields.name && company.entityStatus === 'ENABLED',
        )
        .map((company) => ({
          id: company.id,
          label: company.fields.name,
          color: company.fields.fallbackColor ?? '',
        })),
    [allCompanies],
  );

  const customFieldsDropdown = tagsCustomFields.map((field) => ({
    id: field.id,
    label: field.name,
    type: VisibilityRuleType.CustomField,
  }));

  const comparatorFields = useMemo(() => {
    const fields = [
      { id: 'client', label: 'Client', type: VisibilityRuleType.Client },
      ...(areCompaniesEnabled
        ? [
            {
              id: 'company',
              label: 'Company',
              type: VisibilityRuleType.Company,
            },
          ]
        : []),
      ...customFieldsDropdown,
    ].filter(Boolean);
    return fields;
  }, [areCompaniesEnabled, customFieldsDropdown]);

  const getComparatorFieldValues = useMemo(
    () =>
      <T extends VisibilityRuleType>(
        comparatorFieldType: T,
        comparatorFieldTypeId: T extends VisibilityRuleType.CustomField
          ? string
          : undefined,
      ) => {
        switch (comparatorFieldType) {
          case VisibilityRuleType.NoVisibility:
            return {
              id: VisibilityRuleType.NoVisibility,
              options: [],
            };
          case VisibilityRuleType.Client:
            return {
              id: VisibilityRuleType.Client,
              options: clientOptions,
            };
          case VisibilityRuleType.Company:
            return {
              id: VisibilityRuleType.Company,
              options: companiesOptions,
            };
          case VisibilityRuleType.CustomField:
            const customFieldOptions =
              tagsCustomFields.find((x) => x.id === comparatorFieldTypeId)
                ?.options || [];
            return {
              id: comparatorFieldTypeId!,
              options: customFieldOptions,
            };
          default:
            ensureUnreachable(comparatorFieldType);
        }
      },
    [clients, allCompanies, tagsCustomFields],
  );

  // Get initial custom field values for edit mode
  const getInitialCustomFieldValues = () => {
    const comparatorFieldId = isCustomFieldRule(rule)
      ? rule.customFieldId
      : undefined;
    const comparatorFieldType = rule.ruleType;
    const comparatorFieldValues = getComparatorFieldValues(
      comparatorFieldType,
      comparatorFieldId,
    );
    return comparatorFieldValues;
  };

  const [selectedFieldValues, setSelectedFieldValues] =
    React.useState<RuleField>(getInitialCustomFieldValues);

  // This useEffect makes sure that the selected custom fields values
  // dropdown is updated when the custom fields are fetched. Without
  // doing this, the dropdown will initially be empty when the custom
  // fields are fetched.
  React.useEffect(() => {
    if (!portalCustomFields) return;
    setSelectedFieldValues(getInitialCustomFieldValues());
  }, [portalCustomFields]);

  const handleFieldChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const comparatorFieldId = event.target.value;
    const selectedComparatorField = comparatorFields.find(
      (field) => field.id === comparatorFieldId,
    );
    if (selectedComparatorField) {
      setFieldValue(
        `visibilityConfig.${ruleIndex}.ruleType`,
        selectedComparatorField.type,
      );
      const comparatorFieldValues = getComparatorFieldValues(
        selectedComparatorField.type,
        comparatorFieldId,
      );
      setSelectedFieldValues(comparatorFieldValues);
      setFieldValue(`visibilityConfig.${ruleIndex}.values`, []);
      // For custom fields, we need to send `customFieldId` to the backend
      if (selectedComparatorField.type === VisibilityRuleType.CustomField) {
        setFieldValue(
          `visibilityConfig.${ruleIndex}.customFieldId`,
          comparatorFieldId,
        );
      }
    }
  };

  const handleFieldValueChange: ComponentProps<
    typeof Autocomplete
  >['onChange'] = (_, value) => {
    setFieldValue(`visibilityConfig.${ruleIndex}.values`, value);
  };

  const selectedOptionsRenderer: ComponentProps<
    typeof Autocomplete
  >['renderTags'] = (options) => {
    const filteredOptions = options
      .map((option) => selectedFieldValues.options.find((x) => x.id === option))
      .filter(Boolean);

    // id will be a GUID when it's a custom field
    const optionsName = isVisibilityRuleType(selectedFieldValues.id)
      ? getSelectedFieldName(selectedFieldValues.id)
      : 'Tags';

    return (
      <BaseChip
        key={optionsName}
        classes={{
          root: classes.baseChip,
          deleteIcon: classes.closeIcon,
          label: classes.chipLabel,
        }}
        label={
          <div className={classes.avatarContainer}>
            <div className={classes.tagIcon}>
              {filteredOptions.slice(0, 3).map((x) => (
                <div className={classes.icon} key={x.id}>
                  <TagIcon style={{ fill: x.color, fontSize: 6 }} />
                </div>
              ))}
            </div>
            <BaseTypography
              fontType="13Medium"
              textColor={BlackHeadings}
              className={classes.truncatedText}
            >
              {filteredOptions.length > 1
                ? `${filteredOptions.length} ${optionsName}`
                : filteredOptions.at(0)?.label}
            </BaseTypography>
          </div>
        }
      />
    );
  };

  const errorContainer = errors.visibilityConfig?.[ruleIndex];

  const optionDropdownHelperText =
    !!touched.visibilityConfig?.[ruleIndex]?.values &&
    typeof errorContainer !== 'string' &&
    errorContainer?.values;

  const visibilityConfigValue = values.visibilityConfig?.[ruleIndex];
  const {
    comparator: selectedComparatorValue,
    ruleType: selectedRuleType,
    values: selectedValues,
  } = visibilityConfigValue;

  const comparatorDropdownRules = useMemo(
    () => getComparatorRules(selectedRuleType, selectedValues),
    [visibilityConfigValue, selectedValues],
  );

  return (
    <Grid container className={classes.root} spacing={3}>
      {/* Leave space for delete icon when there are more than one rules */}
      <Grid xs={values.visibilityConfig?.length > 1 ? 11 : 12} item container>
        <Grid item xs={6} className={classes.select}>
          <BaseTextField
            fullWidth
            select
            sizeVariant="medium"
            variant="outlined"
            value={selectedFieldValues.id}
            onChange={handleFieldChange}
          >
            {comparatorFields.map((f) => (
              <MenuItem key={f.id} value={f.id}>
                {f.label}
              </MenuItem>
            ))}
          </BaseTextField>
        </Grid>
        <Grid item xs={3}>
          <BaseTextField
            fullWidth
            select
            sizeVariant="medium"
            variant="outlined"
            name={`visibilityConfig.${ruleIndex}.comparator`}
            value={
              comparatorDropdownRules.find(
                (r) => r.id === selectedComparatorValue,
              )?.id || VisibilityComparator.IncludesAnyOf
            }
            onChange={handleChange}
          >
            {comparatorDropdownRules.map((comparatorRule) => (
              <MenuItem key={comparatorRule.id} value={comparatorRule.id}>
                {selectedValues.length > 1
                  ? comparatorRule.pluralLabel
                  : comparatorRule.singularLabel}
              </MenuItem>
            ))}
          </BaseTextField>
        </Grid>
        <Grid item xs={3} className={classes.autoComplete}>
          <Autocomplete
            classes={{
              listbox: classes.list,
              paper: classes.listPaper,
              inputRoot: classes.inputRoots,
            }}
            multiple
            limitTags={1}
            id="tags-outlined"
            options={selectedFieldValues.options.map((x) => x.id)}
            renderTags={selectedOptionsRenderer}
            getOptionLabel={(optionId) =>
              selectedFieldValues.options.find((x) => x.id === optionId)
                ?.label || ''
            }
            value={values.visibilityConfig?.[ruleIndex].values}
            popupIcon={<ChevronDownIcon className={classes.dropdownIcon} />}
            onChange={handleFieldValueChange}
            renderInput={(params) => (
              <BaseTextField
                {...params}
                variant="outlined"
                sizeVariant="medium"
                name={`visibilityConfig.${ruleIndex}.values`}
                error={
                  !!touched.visibilityConfig?.[ruleIndex]?.values &&
                  !!optionDropdownHelperText
                }
                helperText={optionDropdownHelperText}
              />
            )}
            disableClearable
          />
        </Grid>
      </Grid>
      {values.visibilityConfig?.length > 1 && (
        <Grid item xs={1}>
          <div className={classes.deleteIcon}>
            <IconButton onClick={onDeleteRule}>
              <TrashIcon className={classes.trashIcon} />
            </IconButton>
          </div>
        </Grid>
      )}
    </Grid>
  );
};
