import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store/reduxTypes';
import { LoadingWrapper } from 'src/components/Loading';
import { StripeConnectForm } from 'src/components/StripeConnectForm';
import { SettingsPageRow } from 'src/components/Settings/SettingsPageRow';
import { BankingStatusSettingsDescription } from 'src/components/Settings/BankingPage/BankingStatusSettingsDescription';

export const BankingPage: React.FC = () => {
  const isLoading = useSelector((state: RootState) => state.settings.loading);

  return (
    <LoadingWrapper isLoading={isLoading} hideContentWhileLoading>
      <>
        {/** BANK STATUS SECTION */}
        <SettingsPageRow
          description={<BankingStatusSettingsDescription />}
          hideDivider
        >
          <StripeConnectForm />
        </SettingsPageRow>
      </>
    </LoadingWrapper>
  );
};
