import React from 'react';
import UserAvatar from 'src/components/User/UserAvatar';
import { UserCellRendererProps } from 'src/components/AgGrid/Renderer/types';

export const UserCellRenderer: React.FC<UserCellRendererProps> = React.memo(
  ({ data, value, useCellData = false, fieldKeys = {} }) => {
    const userData = useCellData ? data : value;
    const {
      nameField,
      avatarField,
      firstNameField,
      lastNameField,
      hideCellField,
    } = fieldKeys;

    const hideUserCell = hideCellField && data[hideCellField];

    const name = React.useMemo(() => {
      if (nameField) {
        return userData[nameField];
      }

      if (firstNameField && lastNameField) {
        return `${userData[firstNameField]} ${userData[lastNameField]}`;
      }

      return `${userData.firstName} ${userData.lastName}`;
    }, [userData, nameField, firstNameField, lastNameField]);

    if (hideUserCell) return null;

    return (
      <UserAvatar
        avatarUrl={avatarField ? userData[avatarField] : userData.avatarUrl}
        name={name}
        description={userData.email}
        fallbackColor={userData.fallbackColor}
        primaryTextVariant="tableMain"
        secondaryTextVariant="tableSubtitle"
        shape="circle"
      />
    );
  },
);
