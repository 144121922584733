import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useChatContext } from 'stream-chat-react';
import { RootState } from 'src/store';
import { initializeClientAction } from 'src/store/messages/actions';

export const useChatClient = () => {
  const dispatch = useDispatch();
  const { client: chatClient } = useChatContext();

  const { userLoaded, clientUsers, internalUsers, clientInitialized } =
    useSelector((state: RootState) => ({
      userLoaded: state.user.loaded,
      clientUsers: state.users.users,
      internalUsers: state.users.internalUsers,
      clientInitialized: state.messages.clientInitialized,
    }));

  /**
   * This effect is responsible for initializing the client.
   * It depends the clientUsers and internalUsers states
   * from where the current user data is retrieved.
   */
  useEffect(() => {
    if (!userLoaded) return;
    dispatch(initializeClientAction(chatClient));
  }, [chatClient, userLoaded, clientUsers, internalUsers]);

  return clientInitialized ? chatClient : null;
};
