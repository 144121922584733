import { RouterContext } from 'universal-router';
// The top-level (parent) route
/* eslint-disable global-require */

/**
 * This method is used to redirect to a new route with query params
 * @param pathTo new path to redirect to
 * @param context router context
 * @returns redirect object
 */
const redirectWithQueryParams = (pathTo: string, context: RouterContext) => {
  const { query } = context;
  const queryParams = new URLSearchParams(query);
  const params = queryParams.toString();
  return { redirect: `${pathTo}?${params}` };
};
const routes = {
  path: '',

  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'home' */ './home'),
    },
    {
      path: '/playground',
      load: () => import(/* webpackChunkName: 'playground' */ './playground'),
    },
    {
      path: '/new',
      load: () => import(/* webpackChunkName: 'home-new' */ './home-new'),
    },
    {
      path: '/portal',
      children: [
        {
          path: '/:portalId',
          load: () => import(/* webpackChunkName: 'home' */ './home'),
        },
      ],
    },
    {
      path: '/workspaces',
      load: () =>
        import(/* webpackChunkName: 'onboarding-users' */ './onboarding/users'),
    },
    {
      path: '/clients',
      children: [
        {
          path: '/users',
          children: [
            {
              path: '',
              load: () => import(/* webpackChunkName: 'users' */ './users'),
            },
            {
              path: '/details',
              load: () =>
                import(
                  /* webpackChunkName: 'client-details-page' */ './users/ClientsDetailsPage'
                ),
            },
          ],
        },
        {
          path: '/import',
          load: () =>
            import(
              /* webpackChunkName: 'client-imports-page' */ './users/ClientImportPage'
            ),
        },
      ],
    },
    {
      path: '/products',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'products' */ './products'),
        },
        {
          path: '/edit',
          load: () =>
            import(/* webpackChunkName: 'product-edit' */ './products/edit'),
        },
        {
          path: '/details/:id',
          load: () =>
            import(
              /* webpackChunkName: 'product-details' */ './products/product-details'
            ),
        },
      ],
    },
    {
      path: '/invoices',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'invoices' */ './invoices'),
        },
        {
          path: '/details',
          load: () =>
            import(
              /* webpackChunkName: 'invoice-details' */ './invoices/details'
            ),
        },
        {
          path: '/pay',
          load: () =>
            import(/* webpackChunkName: 'invoices-pay' */ './invoices/pay'),
        },
        {
          path: '/edit',
          children: [
            {
              path: '',
              load: () =>
                import(
                  /* webpackChunkName: 'invoice-edit' */ './invoices/edit'
                ),
            },
            {
              path: '/template',
              load: () =>
                import(
                  /* webpackChunkName: 'invoice-template' */ './invoices/template'
                ),
            },
          ],
        },
      ],
    },
    {
      path: '/subscriptions',
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: 'subscriptions' */ './subscriptions'),
        },
        {
          path: '/edit',
          children: [
            {
              path: '',
              load: () =>
                import(
                  /* webpackChunkName: 'subscription-edit' */ './subscriptions/edit'
                ),
            },
            {
              path: '/template',
              load: () =>
                import(
                  /* webpackChunkName: 'subscription-template' */ './subscriptions/template'
                ),
            },
          ],
        },
      ],
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'login' */ './auth'),
    },
    {
      path: '/browser-unsupported',
      load: () =>
        import(
          /* webpackChunkName: 'browser-unsupported' */ './browser-unsupported'
        ),
    },
    // Following routes serve as redirection to helpdesk route as knowledge base it self has a path change
    {
      path: '/knowledge-base',
      children: [
        {
          path: '',
          action() {
            return { redirect: '/helpdesk' };
          },
        },
        {
          path: '/article/:id',
          // Optional only for Typescript's benefit, we can count
          // on this existing in the JS runtime.
          action({ params }: { params: { id?: string } }) {
            return { redirect: `/helpdesk/article/${params.id}` };
          },
        },
      ],
    },
    {
      path: '/helpdesk',
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: 'knowledge-base' */ './knowledgebase'),
        },
        {
          path: '/article/:id',
          load: () => import(/* webpackChunkName: 'article' */ './article'),
        },
      ],
    },
    {
      path: '/files',
      load: () => import(/* webpackChunkName: 'files' */ './files'),
    },
    {
      path: '/automations',
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: 'automations' */ './automations'),
        },
        {
          path: '/edit',
          load: () =>
            import(
              /* webpackChunkName: 'automations-edit' */ './automations/edit'
            ),
        },
        {
          path: '/details',
          load: () =>
            import(
              /* webpackChunkName: 'automations-runs' */ './automations/runs'
            ),
        },
        {
          path: '/runs/details',
          load: () =>
            import(
              /* webpackChunkName: 'automations-details' */ './automations/details'
            ),
        },
      ],
    },
    {
      path: '/contracts',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'contracts' */ './contracts'),
        },
        {
          path: '/edit',
          load: () =>
            import(/* webpackChunkName: 'contract-edit' */ './contracts/edit'),
        },
        {
          path: '/details',
          load: () =>
            import(
              /* webpackChunkName: 'contract-details' */ './contracts/contract-details'
            ),
        },
        {
          path: '/submit',
          load: () =>
            import(
              /* webpackChunkName: 'contract-submit' */ './contracts/submit'
            ),
        },
        {
          path: '/submission',
          load: () =>
            import(
              /* webpackChunkName: 'contract-submission' */ './contracts/submission'
            ),
        },
      ],
    },
    {
      path: '/notifications',
      load: () => import(/* webpackChunkName: 'inbox' */ './inbox'),
    },
    {
      path: '/apps',
      load: () => import(/* webpackChunkName: 'extensions' */ './apps'),
    },
    {
      path: '/extensions',
      action: (context: RouterContext) =>
        redirectWithQueryParams('/apps', context),
    },
    {
      path: '/messaging',
      load: () => import(/* webpackChunkName: 'messaging' */ './messaging'),
    },
    {
      path: '/signup',
      load: () =>
        import(/* webpackChunkName: 'onboarding-setup' */ './onboarding/setup'),
    },
    {
      path: '/admin',
      load: () => import(/* webpackChunkName: 'admin' */ './admin'),
    },
    {
      path: '/add-account',
      load: () =>
        import(/* webpackChunkName: 'onboarding-login' */ './onboarding/login'),
    },
    {
      path: '/preview',
      load: () =>
        import(/* webpackChunkName: 'file-preview' */ './file-preview'),
    },
    {
      path: '/forms',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'forms' */ './forms'),
        },
        {
          path: '/edit',
          load: () =>
            import(/* webpackChunkName: 'form-edit' */ './forms/edit'),
        },
        {
          path: '/response',
          load: () =>
            import(/* webpackChunkName: 'form-response' */ './forms/response'),
        },
        {
          path: '/details',
          load: () =>
            import(
              /* webpackChunkName: 'form-details' */ './forms/form-details'
            ),
        },
        {
          path: '/submit',
          load: () =>
            import(/* webpackChunkName: 'form-submit' */ './forms/submit'),
        },
      ],
    },
    // Customization and modules being a standalone route instead of child of settings.
    // The original route are still available for backward compatibility.
    {
      path: '/customization',
      load: () =>
        import(
          /* webpackChunkName: 'settings-customization' */ './settings/customization'
        ),
    },
    {
      path: '/app-setup',
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: 'app-setup' */ './appSetup/setup'),
        },
        {
          path: '/new',
          load: () =>
            import(
              /* webpackChunkName: 'app-setup-new-module' */ './appSetup/new-app'
            ),
        },
        {
          path: '/setup',
          load: () =>
            import(/* webpackChunkName: 'setup-app' */ './appSetup/setup-app'),
        },
        {
          path: '/:appId',
          load: () =>
            import(
              /* webpackChunkName: 'app-details' */ './appSetup/app-details'
            ),
        },
      ],
    },
    {
      path: '/referrals',
      load: () => import(/* webpackChunkName: 'referrals' */ './referrals'),
    },
    {
      path: '/settings',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'settings' */ './settings'),
        },
        {
          path: '/profile',
          load: () =>
            import(
              /* webpackChunkName: 'settings-account' */ './settings/account'
            ),
        },
        // OLD ROUTE
        {
          path: '/me',
          action() {
            return { redirect: '/settings/profile' };
          },
        },
        {
          path: '/general',
          load: () =>
            import(
              /* webpackChunkName: 'settings-main' */ './settings/general'
            ),
        },
        // OLD ROUTE
        {
          path: '/main',
          action() {
            return { redirect: '/settings/general' };
          },
        },
        {
          path: '/domains',
          load: () =>
            import(
              /* webpackChunkName: 'settings-domain' */ './settings/domain'
            ),
        },
        // OLD ROUTE
        {
          path: '/domain',
          action() {
            return { redirect: '/settings/domains' };
          },
        },
        {
          path: '/team',
          load: () =>
            import(/* webpackChunkName: 'settings-team' */ './settings/team'),
        },
        // OLD ROUTE
        {
          path: '/members',
          action() {
            return { redirect: '/settings/team' };
          },
        },
        {
          path: '/notifications',
          load: () =>
            import(
              /* webpackChunkName: 'settings-notifications' */ './settings/notifications'
            ),
        },
        {
          path: '/api',
          children: [
            {
              path: '',
              load: () =>
                import(/* webpackChunkName: 'settings-api' */ './settings/api'),
            },
            {
              path: '/webhooks/edit/:id',
              load: () =>
                import(
                  /* webpackChunkName: 'settings-api-webhooks' */ './settings/api/webhooks'
                ),
            },
            {
              path: '/webhooks',
              load: () =>
                import(
                  /* webpackChunkName: 'settings-api-webhooks' */ './settings/api/webhooks'
                ),
            },
          ],
        },
        {
          path: '/apps',
          load: () =>
            import(/* webpackChunkName: 'settings-apps' */ './settings/apps'),
        },
        {
          path: '/plans',
          children: [
            {
              path: '',
              load: () =>
                import(
                  /* webpackChunkName: 'settings-plans' */ './settings/plans'
                ),
            },
            {
              path: '/cancel',
              load: () =>
                import(
                  /* webpackChunkName: 'settings-plans-cancel-plan-start' */ './settings/plans/cancel-plan-start'
                ),
            },
            {
              path: '/cancel/confirm',
              load: () =>
                import(
                  /* webpackChunkName: 'settings-plans-cancel-plan-confirm' */ './settings/plans/cancel-plan-confirm'
                ),
            },
          ],
        },
        // OLD ROUTE
        {
          path: '/plan',
          children: [
            {
              path: '',
              action() {
                return { redirect: '/settings/plans' };
              },
            },
            {
              path: '/cancel',
              action() {
                return { redirect: '/settings/plans/cancel' };
              },
            },
            {
              path: '/cancel/confirm',
              action() {
                return { redirect: '/settings/plans/cancel/confirm' };
              },
            },
          ],
        },
        {
          path: '/referral',
          action: () => ({ redirect: '/referrals' }),
        },
        {
          path: '/payout-account',
          load: () =>
            import(
              /* webpackChunkName: 'settings-payments' */ './settings/payments'
            ),
        },
        // OLD ROUTE
        {
          path: '/payments',
          action() {
            return { redirect: '/settings/payout-account' };
          },
        },
        {
          path: '/billing',
          load: () =>
            import(
              /* webpackChunkName: 'settings-billing' */ './settings/billing'
            ),
        },
      ],
    },
    {
      path: '/install/:slug',
      load: () => import(/* webpackChunkName: 'install' */ './install'),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ next }: { next: () => Promise<RouterContext> }) {
    // Execute each child route until one of them return the result
    const route = await next();

    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'}`;
    route.description = route.description || '';

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    // eslint-disable-next-line @typescript-eslint/no-var-requires
    action: require('./error').default,
  });
}

export default routes;
