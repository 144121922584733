import React from 'react';
import {
  MenuItem,
  createStyles,
  makeStyles,
  Box,
  Theme,
} from '@material-ui/core';
import { ColDef } from '@ag-grid-community/core';
import { SortableElement, SortableHandle } from 'react-sortable-hoc';
import { ReorderIcon } from 'src/components/Icons/Reorder';
import BaseTypography from 'src/components/Text/BaseTypography';
import { zIndex } from 'src/theme/zindex';
import Activator from 'src/components/Activator';
import * as Colors from 'src/theme/colors';

interface ColumnSettingsProps {
  handleVisibilityChanged: (
    field: string | undefined,
    isChecked: boolean,
    order: number,
  ) => void;
  columnDef: ColDef;
  order: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    settingsMenuItemContainer: {
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    settingsMenuItem: {
      display: 'flex',
      justifyContent: 'space-between',
      width: '100%',
      height: 24,
      zIndex: zIndex.modal + 1, // this is for showing sorting behavior over columnSettings dropdown
    },
    dragIconContainer: {
      marginRight: theme.spacing(1.4),
    },
    dragIcon: {
      color: Colors.NonHoverBorder,
      fontSize: 10,
    },
  }),
);

const DragHandle = SortableHandle(() => {
  const classes = useStyles();
  return <ReorderIcon className={classes.dragIcon} />;
});

const TableColumnElement: React.FC<ColumnSettingsProps> = ({
  columnDef,
  order,
  handleVisibilityChanged,
}) => {
  const classes = useStyles();

  return (
    <MenuItem key={columnDef.field} className={classes.settingsMenuItem}>
      <Box className={classes.settingsMenuItemContainer}>
        <Box className={classes.dragIconContainer}>
          <DragHandle />
        </Box>
        <BaseTypography fontType="13Medium">
          {columnDef.headerName}
        </BaseTypography>
      </Box>
      <Activator
        isFixedWidth={false}
        checked={!columnDef.hide}
        onChange={(_, checked) => {
          handleVisibilityChanged(columnDef.field, checked, order);
        }}
        onBlur={() => 0}
      />
    </MenuItem>
  );
};

export const SortableTableColumnElement = SortableElement(TableColumnElement);
