import { appAPI, ApiMethods, ApiTags } from '.';

interface TrackPageViewInput {
  eventType: 'PAGE_VIEWED#INC';
  eventData: {
    page: string;
  };
}

export type GetRecentlyVisitedResponse = {
  Crm: Array<{
    clientId: string;
  }>;
  Apps: Array<{
    moduleExtensionConfigId: string;
  }>;
};

type ISOTimestamp = string;

export interface Aggregate {
  added: number;
  interval: ISOTimestamp;
  removed: number;
  total: number;
}

export type AggregateResponse = Aggregate[];

export type AggregateTargets = 'CLIENT' | 'CLIENT_ACTIVATION' | 'SUBSCRIPTION';

export const analyticsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    trackPageView: build.mutation<void, TrackPageViewInput>({
      query: (body) => ({
        method: ApiMethods.post,
        options: { body },
        path: '/v1/analytics/track',
      }),
    }),
    getRecentlyVisitedApps: build.query<GetRecentlyVisitedResponse, void>({
      query: () => ({
        path: `/v1/analytics-recently-visited`,
        method: ApiMethods.get,
        options: {},
      }),
      providesTags: [ApiTags.analyticsRecentlyVisited],
    }),
    fetchClients: build.query<AggregateResponse, void>({
      query: () => ({
        method: ApiMethods.get,
        options: {},
        path: `/v1/analytics?target=CLIENT`,
      }),
      providesTags: [ApiTags.analyticsClients],
    }),
    fetchActiveClients: build.query<AggregateResponse, void>({
      query: () => ({
        method: ApiMethods.get,
        options: {},
        path: `/v1/analytics?target=CLIENT_ACTIVATION`,
      }),
      providesTags: [ApiTags.analyticsActiveClients],
    }),
    fetchSubscriptions: build.query<AggregateResponse, void>({
      query: () => ({
        method: ApiMethods.get,
        options: {},
        path: `/v1/analytics?target=SUBSCRIPTION`,
      }),
      providesTags: [ApiTags.analyticsSubscriptions],
    }),
  }),
});

export const {
  useTrackPageViewMutation,
  useGetRecentlyVisitedAppsQuery,
  useFetchClientsQuery,
  useFetchActiveClientsQuery,
  useFetchSubscriptionsQuery,
} = analyticsApi;

export function getErrorMessage(msg: string) {
  switch (msg) {
    case 'This Analytics report is currently unavailable. Please try again later.':
      return msg;
    default:
      return 'Something went wrong. Please try again later.';
  }
}
