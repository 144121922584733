import {
  AllVisibilityRules,
  VisibilityType,
} from 'src/components/Settings/AppVisibilityForm';
import { z } from 'zod';
import {
  ModuleSettingsItem,
  User,
  VisibilityComparator,
  VisibilityRule,
  VisibilityRuleType,
} from 'src/constants';
import { ensureUnreachable } from 'src/utils/common_utils';
import { ClientDetailsTabKey, Tab } from 'src/types/client_details';

export enum VisibilityScenario {
  EditApp = 'EditApp',
  AddAnApp = 'AddAnApp',
}

export const clientRuleSchema = z.object({
  ruleType: z.literal(VisibilityRuleType.Client),
  ruleKey: z.string(),
  comparator: z.enum([
    VisibilityComparator.IncludesAnyOf,
    VisibilityComparator.DoesntContainAnyOf,
    VisibilityComparator.IncludesAllOf,
  ]),
  values: z.array(z.string()).min(1, { message: 'Select at least one value' }),
});

export const companyRuleSchema = z.object({
  ruleType: z.literal(VisibilityRuleType.Company),
  ruleKey: z.string(),
  comparator: z.enum([
    VisibilityComparator.IncludesAnyOf,
    VisibilityComparator.DoesntContainAnyOf,
    VisibilityComparator.IncludesAllOf,
  ]),
  values: z.array(z.string()).min(1, { message: 'Select at least one value' }),
});

export const customFieldRuleSchema = z.object({
  ruleType: z.literal(VisibilityRuleType.CustomField),
  ruleKey: z.string(),
  comparator: z.enum([
    VisibilityComparator.IncludesAnyOf,
    VisibilityComparator.DoesntContainAnyOf,
    VisibilityComparator.IncludesAllOf,
  ]),
  values: z.array(z.string()).min(1, { message: 'Select at least one value' }),
  customFieldId: z.string(),
});

type CustomFieldRuleSchema = z.infer<typeof customFieldRuleSchema>;

export type VisibilityRuleSchema = z.infer<typeof rulesSchema>;

const visbileToEveryOneSchema = z.object({
  visibility: z.literal(VisibilityType.VisibleToEveryone),
});

const noVisibilitySchema = z.object({
  visibility: z.literal(VisibilityType.NoVisibility),
});

const customVisibilitySchema = z.object({
  visibility: z.literal(VisibilityType.CustomVisibility),
  visibilityConfig: z.array(
    z.discriminatedUnion('ruleType', [
      clientRuleSchema,
      companyRuleSchema,
      customFieldRuleSchema,
    ]),
  ),
});

export type VisibileToEveryoneSchema = z.infer<typeof visbileToEveryOneSchema>;
export type CustomVisibilitySchema = z.infer<typeof customVisibilitySchema>;
export type NoVisibilitySchema = z.infer<typeof noVisibilitySchema>;

export const rulesSchema = z.discriminatedUnion('visibility', [
  visbileToEveryOneSchema,
  customVisibilitySchema,
  noVisibilitySchema,
]);

export function isCustomFieldUsedInAppVisibility(
  moduleSettings: ModuleSettingsItem[],
  deletedOption: string,
  deletionComparison: (
    rule: VisibilityRule,
    deletedCustomField: string,
  ) => boolean,
): [boolean, string] {
  for (const module of moduleSettings) {
    const { visibilityConfig } = module;
    if (
      visibilityConfig &&
      visibilityConfig.length > 0 &&
      visibilityConfig.some((visibilityRule) =>
        deletionComparison(visibilityRule, deletedOption),
      )
    ) {
      return [true, module.label];
    }
  }
  return [false, ''];
}

export function getVisibilityConfig(
  values: AllVisibilityRules,
): VisibilityRule[] {
  const { visibility } = values;
  switch (visibility) {
    case VisibilityType.VisibleToEveryone:
      return [];
    case VisibilityType.NoVisibility:
      return [{ ruleType: VisibilityRuleType.NoVisibility }];
    case VisibilityType.CustomVisibility:
      return values.visibilityConfig.map((rule) => {
        if (isCustomFieldRule(rule)) {
          return { ...rule, customFieldId: rule.customFieldId };
        }
        return rule;
      });
    default:
      return ensureUnreachable(visibility);
  }
}

export function isCustomFieldRule(
  rule: AllVisibilityRules['visibilityConfig'][0],
): rule is CustomFieldRuleSchema {
  return (
    rule.ruleType === VisibilityRuleType.CustomField && 'customFieldId' in rule
  );
}

export function isVisibilityType(value: any): value is VisibilityType {
  return Object.values(VisibilityType).includes(value);
}

export function getVisibilityType(moduleVisbilityConfig: VisibilityRule[]) {
  if (moduleVisbilityConfig.length === 0) {
    return VisibilityType.VisibleToEveryone;
  }
  if (
    moduleVisbilityConfig.length === 1 &&
    moduleVisbilityConfig.at(0)?.ruleType === VisibilityRuleType.NoVisibility
  ) {
    return VisibilityType.NoVisibility;
  }
  return VisibilityType.CustomVisibility;
}

export function getVisibilitySubtitle(type: VisibilityType) {
  switch (type) {
    case VisibilityType.CustomVisibility:
      return 'Custom visibility';
    case VisibilityType.NoVisibility:
      return 'No visibility';
    case VisibilityType.VisibleToEveryone:
      return 'Visible to all clients';
    default:
      return ensureUnreachable(type);
  }
}

export function getClientVisibilityCount(
  scenario: VisibilityScenario,
  clients: User[],
  visibilityConfig: VisibilityRule[],
  serverClientCount: number,
) {
  let count = 0;
  switch (scenario) {
    case VisibilityScenario.AddAnApp: {
      for (const client of clients) {
        if (hasClientVisibilityIntoModule(visibilityConfig, client)) {
          count += 1;
        }
      }
      return count;
    }
    case VisibilityScenario.EditApp:
      return serverClientCount;
    default:
      ensureUnreachable(scenario);
  }
}

export function hasClientVisibilityIntoModule(
  visibilityConfig: VisibilityRule[],
  client: User,
): boolean {
  if (visibilityConfig.length === 0) {
    return true;
  }

  for (const rule of visibilityConfig) {
    const { ruleType } = rule;
    switch (ruleType) {
      case VisibilityRuleType.Client: {
        const { comparator, values } = rule;
        switch (comparator) {
          case VisibilityComparator.IncludesAnyOf:
            if (values.includes(client.id)) {
              return true;
            }
            break;
          case VisibilityComparator.DoesntContainAnyOf:
          case VisibilityComparator.IncludesAllOf:
            continue;
          default:
            ensureUnreachable(comparator);
        }
        continue;
      }
      case VisibilityRuleType.Company: {
        const { comparator, values } = rule;
        switch (comparator) {
          case VisibilityComparator.IncludesAnyOf:
            if (values.includes(client.fields.companyId || '')) {
              return true;
            }
            break;
          case VisibilityComparator.DoesntContainAnyOf:
          case VisibilityComparator.IncludesAllOf:
            continue;
          default:
            ensureUnreachable(comparator);
        }
        continue;
      }
      case VisibilityRuleType.CustomField: {
        const { comparator, values, customFieldId } = rule;
        switch (comparator) {
          case VisibilityComparator.IncludesAnyOf: {
            const customFieldValue = new Set(
              client.fields.customFields?.[customFieldId],
            );
            if (values.some((value) => customFieldValue.has(value))) {
              return true;
            }
            break;
          }
          case VisibilityComparator.DoesntContainAnyOf: {
            const customFieldValue =
              client.fields.customFields?.[customFieldId];
            if (!values.includes(customFieldValue)) {
              return true;
            }
            break;
          }
          case VisibilityComparator.IncludesAllOf:
            {
              const customFieldValue = new Set(
                client.fields.customFields?.[customFieldId],
              );
              if (values.every((value) => customFieldValue.has(value))) {
                return true;
              }
            }
            continue;
          default:
            ensureUnreachable(comparator);
        }
        continue;
      }
      case VisibilityRuleType.NoVisibility: {
        continue;
      }

      default:
        ensureUnreachable(ruleType);
    }
  }

  return false;
}

export function isTabVisibleToClient(
  tab: Tab,
  activeClient: User,
  visibilityConfigMapping: Record<string, VisibilityRule[]>,
) {
  switch (tab.key) {
    case ClientDetailsTabKey.Contracts:
      return hasClientVisibilityIntoModule(
        visibilityConfigMapping['contracts'],
        activeClient,
      );
    case ClientDetailsTabKey.Files:
      return hasClientVisibilityIntoModule(
        visibilityConfigMapping['files'],
        activeClient,
      );
    case ClientDetailsTabKey.Forms:
      return hasClientVisibilityIntoModule(
        visibilityConfigMapping['forms'],
        activeClient,
      );
    case ClientDetailsTabKey.Messages:
      return hasClientVisibilityIntoModule(
        visibilityConfigMapping['messages'],
        activeClient,
      );
    case ClientDetailsTabKey.Payments:
      return hasClientVisibilityIntoModule(
        visibilityConfigMapping['payments'],
        activeClient,
      );
    case ClientDetailsTabKey.More:
      return true;
    default:
      ensureUnreachable(tab.key);
  }
}
