import { CustomFieldType } from 'src/store/clients/types';

export const AllClientSelectValue = 'default';

export const CLIENT_DETAILS_TAB_KEYS = {
  MESSAGES: {
    key: 'messages',
    label: 'Messages',
  },
  FILES: {
    key: 'files',
    label: 'Files',
  },
  INVOICES: {
    key: 'invoices',
    label: 'Invoices',
  },
  FORMS: {
    key: 'forms',
    label: 'Forms responses',
  },
  EXTENSIONS: {
    key: 'extensions',
    label: 'Apps',
  },
};

export type ClientDetailsTabKey = keyof typeof CLIENT_DETAILS_TAB_KEYS;

export const COPYABLE_FIELDS_TYPES: Array<CustomFieldType> = [
  'url',
  'email',
  'address',
  'text',
  'number',
  'phoneNumber',
];

export const NEW_CLIENT_CREATE_OPTIONS = {
  CREATE_ONE_CLIENT: 'Create one client',
  IMPORT_MANY_CLIENTS: 'Import many clients',
  COPY_SIGNUP_LINK: 'Copy client sign up link',
};
