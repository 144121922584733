import { API } from 'src/utils/AmplifyApiUtils';
import { ContactForm } from 'src/components/MarketingPages/ContactUsForm';
import { LandingPageEntity } from 'src/store/landing/types';

export default class UiClient {
  static getPortalConfig() {
    return API.get('AppAPI', '/portal/config', {});
  }

  static uploadPortalConfig(data: { config: any; source?: string }) {
    let apiPath = '/portal/config';
    if (data.source) {
      apiPath = `${apiPath}?source=${data.source}`;
    }

    return API.post('AppAPI', apiPath, {
      body: data.config,
    });
  }

  static loadLandings() {
    return API.get('AppAPI', '/portal/landing_page', {});
  }

  static loadLandingWithType(type: string) {
    return API.get('AppAPI', `/entities/${type}`, {});
  }

  static saveLandingWithType(landingData: LandingPageEntity) {
    return API.post(
      'AppAPI',
      `/entities/${landingData.fields.pageEntityType}`,
      {
        body: {
          data: [
            {
              entityStatus: landingData.entityStatus,
              fields: landingData.fields,
            },
          ],
        },
      },
    );
  }

  static updateLandingWithType(landingData: LandingPageEntity) {
    return API.put('AppAPI', `/entities/${landingData.fields.pageEntityType}`, {
      body: {
        data: [
          {
            id: landingData.id,
            entityStatus: landingData.entityStatus,
            fields: landingData.fields,
          },
        ],
      },
    });
  }

  static contactUs(values: ContactForm, poolId: string) {
    return API.post('AppAPI', `/portal/any/contact-form?poolId=${poolId}`, {
      body: {
        name: values.name,
        email: values.email,
        phone: values.phone,
        message: values.message,
      },
    });
  }

  static contactSupport(message: string, attachment: string) {
    return API.post('AppAPI', `/portal/support`, {
      body: {
        message,
        attachment,
      },
    });
  }
}
