import React from 'react';

export const AutomationsIcon = () => (
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2769 3.46258C9.32547 2.125 7.76624 1.25 5.99993 1.25C3.10022 1.25 0.75 3.60025 0.75 6.49999C0.75 6.81441 0.782666 7.12066 0.835749 7.41932"
      stroke="#212B36"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.58008 10.9295V9.53711H2.97364"
      stroke="#212B36"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M1.72266 9.53682C2.67406 10.8744 4.23329 11.7494 5.9996 11.7494C8.89931 11.7494 11.2495 9.39916 11.2495 6.49941C11.2495 6.18499 11.2169 5.87874 11.1638 5.58008"
      stroke="#212B36"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.419 2.07031V3.46273H9.02539"
      stroke="#212B36"
      stroke-width="1.25"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);
