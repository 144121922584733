import { appAPI, ApiTags, ApiError, ApiMethods } from '.';
import { notify } from 'src/clients/ApiService';
import { INVOICES_PAGE, SUBSCRIPTION_PAGE } from 'src/constants';
import history from 'src/history';
import { BillingTemplate, BillingFieldsModel } from 'src/store/payments/types';

const formatTemplateBodyLineItems = (fields: BillingFieldsModel) => ({
  ...fields,
  // we need to format line items of invoice and subscription fields before
  // sending them in api body because in frontend quantity, rate and tex is
  // saved in string format and backend accept float
  lineItems:
    fields.lineItems &&
    fields.lineItems.map((lineItem) => ({
      ...lineItem,
      id: '',
      quantity: parseFloat(lineItem.quantity.toString()),
      rate: parseFloat(lineItem.rate.toString()),
    })),
  taxPercentage: parseFloat(fields.taxPercentage.toString()) || 0,
});

export const templateApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    getInvoiceTemplates: build.query<BillingTemplate[], void>({
      query: () => ({
        path: '/entities/stripe_invoice',
        method: ApiMethods.get,
        options: {
          queryStringParameters: {
            isTemplateQuery: true,
          },
        },
      }),
      providesTags: [ApiTags.templates],
    }),
    getSubscriptionTemplates: build.query<BillingTemplate[], void>({
      query: () => ({
        path: '/entities/stripe_subscription',
        method: ApiMethods.get,
        options: {
          queryStringParameters: {
            isTemplateQuery: true,
          },
        },
      }),
      providesTags: [ApiTags.templates],
    }),
    addInvoiceTemplate: build.mutation<any, BillingTemplate>({
      query: (data) => {
        const { fields } = data;

        const additionalFields = {
          additionalFields: data.additionalFields,
        };

        return {
          path: '/v0/invoice-templates',
          method: ApiMethods.post,
          options: {
            body: {
              fields: {
                ...formatTemplateBodyLineItems(fields),
              },
              ...additionalFields,
            },
          },
        };
      },
      invalidatesTags: [ApiTags.templates],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            templateApi.util.updateQueryData(
              'getInvoiceTemplates',
              undefined,
              (draft) => {
                // on success push the newly added template
                // in invoice templates array
                draft.push(data);
              },
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Template is saved and available for future use.',
            dispatch,
          });
          // on success redirect user back to invoices page
          history.push(INVOICES_PAGE.path);
        } catch (err) {
          const error = err as ApiError;
          const errorMessage = error?.error?.data?.message;
          notify({
            status: 'error',
            errorMessage:
              errorMessage || 'This Invoice Template could not be created.',
            error,
            dispatch,
          });
        }
      },
    }),
    updateInvoiceTemplate: build.mutation<any, BillingTemplate>({
      query: (data) => {
        const { fields } = data;

        return {
          path: `/v0/invoice-templates/${data.id}`,
          method: ApiMethods.put,
          options: {
            body: {
              ...data,
              fields: {
                ...formatTemplateBodyLineItems(fields),
              },
            },
          },
        };
      },
      invalidatesTags: [ApiTags.templates],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            templateApi.util.updateQueryData(
              'getInvoiceTemplates',
              undefined,
              (draft) =>
                draft.map((template) => {
                  if (template.id === data.id) {
                    return data;
                  }

                  return template;
                }),
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Template is saved and available for future use.',
            dispatch,
          });
          history.push(INVOICES_PAGE.path);
        } catch (err) {
          const error = err as ApiError;
          const errorMessage = error?.error?.data?.message;
          notify({
            status: 'error',
            errorMessage:
              errorMessage || 'This Invoice Template could not be created.',
            error,
            dispatch,
          });
        }
      },
    }),
    addSubscriptionTemplate: build.mutation<any, BillingTemplate>({
      query: (data) => {
        const { fields } = data;

        const additionalFields = {
          additionalFields: data.additionalFields,
        };

        return {
          path: `/subscription-templates`,
          method: ApiMethods.post,
          options: {
            body: {
              fields: {
                ...formatTemplateBodyLineItems(fields),
              },
              ...additionalFields,
            },
          },
        };
      },
      invalidatesTags: [ApiTags.templates],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            templateApi.util.updateQueryData(
              'getSubscriptionTemplates',
              undefined,
              (draft) => {
                draft.push(data);
              },
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Template is saved and available for future use.',
            dispatch,
          });
          history.push(SUBSCRIPTION_PAGE.path);
        } catch (err) {
          const error = err as ApiError;
          const errorMessage = error?.error?.data?.message;
          notify({
            status: 'error',
            errorMessage:
              errorMessage ||
              'This Subscription Template could not be created.',
            error,
            dispatch,
          });
        }
      },
    }),
    updateSubscriptionTemplate: build.mutation<any, BillingTemplate>({
      query: (data) => {
        const { fields } = data;

        return {
          path: `/subscription-templates/${data.id}`,
          method: ApiMethods.put,
          options: {
            body: {
              ...data,
              fields: {
                ...formatTemplateBodyLineItems(fields),
              },
            },
          },
        };
      },
      invalidatesTags: [ApiTags.templates],
      async onQueryStarted(data, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          dispatch(
            templateApi.util.updateQueryData(
              'getSubscriptionTemplates',
              undefined,
              (draft) =>
                draft.map((template) => {
                  if (template.id === data.id) {
                    return data;
                  }

                  return template;
                }),
            ),
          );
          notify({
            status: 'success',
            successMessage: 'Template is saved and available for future use.',
            dispatch,
          });
          history.push(SUBSCRIPTION_PAGE.path);
        } catch (err) {
          const error = err as ApiError;
          const errorMessage = error?.error?.data?.message;
          notify({
            status: 'error',
            errorMessage:
              errorMessage || 'This Subscription Template could not be saved.',
            error,
            dispatch,
          });
        }
      },
    }),
    deleteInvoiceTemplate: build.mutation<any, Pick<BillingTemplate, 'id'>>({
      query: (params) => ({
        path: `/v0/invoice-templates/${params.id}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          templateApi.util.updateQueryData(
            'getInvoiceTemplates',
            undefined,
            (draft) => {
              const index = draft.findIndex((domain) => domain.id === id);
              draft.splice(index, 1);
            },
          ),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Invoice template has been deleted',
            dispatch,
          });
        } catch (error) {
          patchResult.undo();
          notify({
            status: 'error',
            errorMessage:
              'This invoice template could not be deleted. Please try again or contact support',
            error,
            dispatch,
          });
        }
      },
    }),
    deleteSubscriptionTemplate: build.mutation<
      any,
      Pick<BillingTemplate, 'id'>
    >({
      query: (params) => ({
        path: `/subscription-templates/${params.id}`,
        method: ApiMethods.del,
        options: {},
      }),
      async onQueryStarted({ id }, { dispatch, queryFulfilled }) {
        const patchResult = dispatch(
          templateApi.util.updateQueryData(
            'getSubscriptionTemplates',
            undefined,
            (draft) => {
              const index = draft.findIndex((domain) => domain.id === id);
              draft.splice(index, 1);
            },
          ),
        );
        try {
          await queryFulfilled;
          notify({
            status: 'success',
            successMessage: 'Subscription template has been deleted',
            dispatch,
          });
        } catch (error) {
          patchResult.undo();
          notify({
            status: 'error',
            errorMessage:
              'This subscription template could not be deleted. Please try again or contact support',
            error,
            dispatch,
          });
        }
      },
    }),
  }),
});

export const {
  useGetInvoiceTemplatesQuery,
  useGetSubscriptionTemplatesQuery,
  useDeleteInvoiceTemplateMutation,
  useDeleteSubscriptionTemplateMutation,
  useAddInvoiceTemplateMutation,
  useAddSubscriptionTemplateMutation,
  useUpdateInvoiceTemplateMutation,
  useUpdateSubscriptionTemplateMutation,
} = templateApi;
