import React from 'react';
import { useAppSelector } from 'src/hooks/useStore';
import { useTrackPageViewMutation } from 'src/services/api/analyticsApi';
import { useRunOncePerNavigation } from 'src/utils/Navigation';

export function AnalyticsTracker() {
  const [trackPageView] = useTrackPageViewMutation();
  const userId = useAppSelector((state) => state.user.id);

  const isProxySession = React.useMemo(() => {
    const proxySession = window.localStorage.getItem(`proxy#${userId}`);
    return proxySession === 'true';
  }, [userId]);

  useRunOncePerNavigation((routeContext) => {
    if (isProxySession) {
      return;
    }
    const search = new URLSearchParams(routeContext.query).toString();
    trackPageView({
      eventType: 'PAGE_VIEWED#INC',
      eventData: {
        page: `${routeContext.pathname}${search ? `?${search}` : ''}`,
      },
    });
  });

  return null;
}
