import { CompanyCellData } from 'src/store/clients/types';

export const companyCellComparator = (
  a: CompanyCellData,
  b: CompanyCellData,
) => {
  if (a.isPlaceholder) {
    return 1;
  }
  if (b.isPlaceholder) {
    return -1;
  }
  return a.name.localeCompare(b.name);
};
