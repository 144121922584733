import React, { ComponentProps } from 'react';
import BaseChannelSidebar from 'src/components/Channels/BaseChannelSidebar';
import { AvatarInfoType } from 'src/components/Channels/ChannelSidebarItem';
import { InboxSidebarItem } from 'src/components/Inbox/InboxSidebarItem';
import { NOTIFICATIONS_STATUSES } from 'src/constants';
import { RouteContext } from 'src/context';
import { InboxPageContext } from 'src/context/inboxPageContext';
import {
  InboxNotificationDetailsResponse,
  NotificationEventType,
} from 'src/entities/Notifications';
import history from 'src/history';
import { useNotificationsCounts } from 'src/hooks/useNotificationsCount';
import {
  useGetInboxNotificationsQuery,
  useUpdateInboxNotificationStatusMutation,
} from 'src/services/api/inboxApi';
import { NotificationGroups } from 'src/store/notifications/types';
import { replaceQueryParam } from 'src/utils/UrlUtils';
import { useChannelNavigationShortcut } from '../Channels/useChannelNavigation';
import { NotificationsActionDropdown } from './NotificationsActionsDropdown';
import { useInboxNotification } from './useInboxNotification';
import { useAppSelector } from 'src/hooks/useStore';

export enum InboxNotificationStatus {
  Read = 'read',
  Sent = 'sent',
}

export interface InboxSidebarItemData {
  id?: string;
  title: string;
  label: string; // TODO(EnableInboxSidebarItemRedesign): legacy prop
  description: string;
  notificationStatus: InboxNotificationStatus;
  avatarInfo: (AvatarInfoType & { email: string; companyName?: string }) | null;
  notificationGroup: NotificationGroups | string;
  channelId: string;
  data: string;
  isRead: boolean;
  timestamp: Date;
  resourceId: string;
  refId: string;
  notificationEventType: NotificationEventType;
}

export function isInboxSidebarItemData(
  item: unknown,
): item is InboxSidebarItemData {
  return (
    typeof item === 'object' &&
    item !== null &&
    'title' in item &&
    'label' in item &&
    'description' in item &&
    'notificationStatus' in item &&
    'avatarInfo' in item &&
    'notificationGroup' in item &&
    'channelId' in item &&
    'data' in item &&
    'isRead' in item &&
    'timestamp' in item &&
    'resourceId' in item &&
    'refId' in item &&
    'notificationEventType' in item
  );
}
/**
 * getFormCompletedResourceIds is a helper function
 * that extracts form response related resourceIds when the notification
 * eventtype is formResponse.completed. When the notification event type is not
 * is not formResponse.completed, it returns empty strings. The user
 * of this function should check if the ids are empty before using them.
 * @param notificationItem
 * @returns
 */
export function getFormCompletedResourceIds(
  notificationItem: InboxSidebarItemData | null,
): { formId: string; responseRefId: string } {
  if (
    !notificationItem ||
    notificationItem.notificationEventType !== 'formResponse.completed'
  ) {
    return {
      formId: '',
      responseRefId: '',
    };
  }

  return {
    formId: notificationItem.resourceId,
    responseRefId: notificationItem.refId,
  };
}

export function hasInProductNotification(
  notification: InboxNotificationDetailsResponse,
): boolean {
  return Boolean(
    notification?.deliveryTargets?.inProduct &&
      notification.deliveryTargets.inProduct.title !== '',
  );
}

export const InboxSidebar = () => {
  const { data: inboxNotifications = [] } = useGetInboxNotificationsQuery();
  const { query } = React.useContext(RouteContext);
  const { channelId: activeChannelId } = query as { channelId: string };
  const searchKey = useAppSelector((state) => state.ui.searchValue);
  const { setSelectedNotification, selectedNotification } =
    React.useContext(InboxPageContext);
  const [markNotificationAsRead] = useUpdateInboxNotificationStatusMutation();
  const { deleteNotification, formattedNotifications } = useInboxNotification();
  const clients = useAppSelector((state) => state.clients.clients);
  const internalUsers = useAppSelector((state) => state.users.internalUsers);
  const companyDataMap = useAppSelector(
    (state) => state.clients.companyDataMap,
  );
  const allUsers = [...clients, ...internalUsers];
  const { readNotificationCount, unreadNotificationCount } =
    useNotificationsCounts(inboxNotifications || [], allUsers, companyDataMap);

  /**
   * When a notification item is clicked this function handles the following:
   * 1. Sets the selected notification state
   * 2. Sets the active channel id in the url
   * 3. Marks the notification as read if it is not already read
   * @param sidebarItemOption {InboxSidebarItemData}
   */
  const onSelectChannel: ComponentProps<
    typeof BaseChannelSidebar
  >['onSelectChannel'] = (sidebarItemOption: InboxSidebarItemData) => {
    // if the selected notification is already selected then do not set it again
    if (selectedNotification?.id === sidebarItemOption.id) return;
    setSelectedNotification({
      ...sidebarItemOption,
      isRead: true,
    });
    replaceQueryParam('channelId', sidebarItemOption.id || '');

    if (sidebarItemOption.id && !sidebarItemOption.isRead) {
      markNotificationAsRead({
        notificationId: sidebarItemOption.id,
        status: NOTIFICATIONS_STATUSES.READ,
      });
    }
  };

  /**
   *
   * @param channel {ChannelOptionData}
   * @description This function is used to delete the notification and is triggered when user presses delete key
   */
  const onDeleteNotificationThroughShortcut = () => {
    deleteNotification();
  };

  React.useEffect(
    () => () => {
      // reset inbox sidebar
      setSelectedNotification(null);
    },
    [],
  );

  const filteredNotificationList = React.useMemo(() => {
    const notificationList = [...formattedNotifications];
    // if search value is empty then return all the notifications available in the list
    if (!searchKey) return notificationList;

    const queriedNotifications = notificationList.filter(
      (n) =>
        n.title.toLowerCase().includes(searchKey.toLowerCase()) ||
        n.description.toLowerCase().includes(searchKey.toLowerCase()) ||
        n.avatarInfo?.companyName
          ?.toLowerCase()
          .includes(searchKey.toLowerCase()),
    );

    // if the selected notification is not included in the queried result
    // un-select the notification.
    if (selectedNotification) {
      const selectedNotificationExist = queriedNotifications.find(
        (n) => n.id === selectedNotification.id,
      );
      if (!selectedNotificationExist) {
        // if the selected notification is not included in the queried result
        // reset the selected notification state and remove the active channel id from the url.
        // the active notification query param should be in sync with the selected notification state.
        setSelectedNotification(null);
        history.replace({
          search: '',
        });
      }
    }

    // return only those notifications that meet our search criteria
    return queriedNotifications;
  }, [searchKey, formattedNotifications, selectedNotification]);

  /**
   * This effect is used to set the selected notification
   * based on the active notification id in the url.
   */
  React.useEffect(() => {
    // when there is already a selected notification
    // then skip this effect.
    if (selectedNotification) return;
    if (activeChannelId) {
      // set selected notification based on active channel id
      const activeNotificationItem = formattedNotifications.find(
        (notification) => notification.id === activeChannelId,
      );
      if (activeNotificationItem) {
        setSelectedNotification(activeNotificationItem);
      }
    }
  }, [activeChannelId, formattedNotifications, selectedNotification]);

  // This useEffect will trigger when inboxNotifications cache is updated and we happen
  // to have the updated notification selected. Will update the read status accordingly or deselect it
  // if it was deleted from the cache (e.g. triggered when a websocket event is received)
  React.useEffect(() => {
    if (selectedNotification) {
      const updatedNotif = inboxNotifications.find(
        (x) => x.id == selectedNotification.id,
      );
      if (updatedNotif) {
        setSelectedNotification({
          ...selectedNotification,
          isRead: updatedNotif.isRead,
        });
      } else {
        setSelectedNotification(null); // it means the selected notification was deleted
      }
    }
  }, [inboxNotifications]);

  // handle channel notification shortcuts for inbox list
  useChannelNavigationShortcut(
    filteredNotificationList,
    filteredNotificationList.findIndex(
      (notification) => notification.id === selectedNotification?.id,
    ),
    onSelectChannel,
    onDeleteNotificationThroughShortcut,
  );

  if (formattedNotifications.length < 1) return null;

  return (
    <BaseChannelSidebar<InboxSidebarItemData>
      title="Notifications"
      entity="inbox"
      sidebarItemOptions={filteredNotificationList}
      onSelectChannel={onSelectChannel}
      itemRenderer={InboxSidebarItem}
      noCreateAction
      headerActionsSlot={
        <NotificationsActionDropdown
          totalUnreadNotificationsCount={unreadNotificationCount || 0}
          totalReadNotificationsCount={readNotificationCount || 0}
        />
      }
      shouldSetQueryParam={false}
      disableDefaultSelect
    />
  );
};
