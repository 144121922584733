import { useSnackbar } from 'notistack';
import React from 'react';
import { RecommendedResource, ResourceDismissalData } from 'src/constants';
import { useUpdateResourcesDismissalMutation } from 'src/services/api';
import { AlertVariant } from 'src/store/ui/types';
import { AlertSnackbar } from 'src/components/AlertSnackbar/AlertSnackbar';
import { HomePageSection } from 'src/components/HomePage/HomePageSection';
import { ResourceCard } from 'src/components/HomePage/ResourcesSection/ResourceCard';

interface ResourcesSectionProps {
  resources: Array<RecommendedResource>;
}
export const ResourcesSection: React.FC<ResourcesSectionProps> = ({
  resources,
}) => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [updateResourcesDismissal] = useUpdateResourcesDismissalMutation();

  const loadedResources = React.useMemo(
    () =>
      resources.map(({ content, CardUUID, visibility }) => ({
        id: CardUUID,
        title: content.title,
        description: content.body,
        primaryActionLabel: content.button_primary,
        secondaryActionLabel: content.button_secondary,
        thumbnail: content.thumbnail,
        asset: content.asset,
        primaryActionLink: content.action_primary,
        secondaryActionLink: content.action_secondary,
        hideOnMobile: visibility.hide_on_mobile,
      })),
    [resources],
  );

  /**
   * When undo snackbar action is clicked, this function will be called.
   * It will update the dismissal status of the resource to 'not dismissed'.
   */
  const handleUndoDismiss = React.useCallback((dismissedResourceId: string) => {
    updateResourcesDismissal({
      structFields: {
        dismissed: { [dismissedResourceId]: false },
      },
    });
    // hide snackbar right away
    closeSnackbar(dismissedResourceId);
  }, []);

  // this defines the snackbar that will be displayed when a resource is dismissed
  const successDismissSnackbar = React.useCallback(
    (snackbarKey: string, dismissedResourceId: string) => (
      <div key={snackbarKey}>
        <AlertSnackbar
          variant={AlertVariant.SUCCESS}
          onClose={() => closeSnackbar(snackbarKey)}
          alertMessage="Card dismissed"
          onAction={() => handleUndoDismiss(dismissedResourceId)}
          actionLabel="Undo"
        />
      </div>
    ),
    [closeSnackbar],
  );

  /**
   * When dismiss resource button is clicked, this function will be called.
   * It updates the resources dismissal state by calling the updateResourcesDismissal mutation.
   * It also displays a snackbar to notify the user that the resource has been dismissed.
   * @param dismissedResourceCardId
   */
  const handleDismissResource = async (dismissedResourceCardId: string) => {
    const stagedResourcesDismissal = resources.reduce(
      (dismissalsMap: Record<string, boolean>, { CardUUID: resourceId }) => {
        // eslint-disable-next-line no-param-reassign
        dismissalsMap[resourceId] = resourceId === dismissedResourceCardId;
        return dismissalsMap;
      },
      {},
    );

    try {
      const result = await updateResourcesDismissal({
        structFields: {
          dismissed: stagedResourcesDismissal,
        },
      });
      if ((result as { data: ResourceDismissalData }).data) {
        enqueueSnackbar('Resource has been dismissed', {
          key: dismissedResourceCardId,
          content: (snackbarKey: string) =>
            successDismissSnackbar(snackbarKey, dismissedResourceCardId),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <HomePageSection title="Recommended resources" subTitle="">
      {loadedResources.map((resource) => (
        <ResourceCard
          key={resource.title}
          onDismissClick={() => handleDismissResource(resource.id)}
          {...resource}
        />
      ))}
    </HomePageSection>
  );
};
