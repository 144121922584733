import React from 'react';
import { makeStyles, withStyles } from '@material-ui/styles';
import {
  Breadcrumbs,
  Chip,
  Theme,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { GraySmall, HoverNonBorderBackground } from 'src/theme/colors';
import BaseTypography from 'src/components/Text/BaseTypography';
import { ForwardIcon } from 'src/components/Icons';
import { RegularButtonHoverShadow } from 'src/theme/shadows';
import useNavigate from 'src/hooks/useNavigate';
import { useEscNavigation } from 'src/hooks/useEscNavigation';

const useStyles = makeStyles((theme: Theme) => ({
  breadcrumbsRoot: {
    overflow: 'hidden',
  },
  breadcrumbsListContainer: {
    flexWrap: 'nowrap',
  },
  breadcrumbListItem: {
    '&:last-child': {
      overflow: 'hidden',
    },
  },
  separator: {
    margin: theme.spacing(0, 1.5),
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(0, 0.5),
    },
    '& svg': {
      height: '7px',
      width: '7px',
    },
  },
  node: {
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    '&::first-letter': {
      textTransform: 'uppercase',
    },
  },
}));
const StyledChip = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
    cursor: 'pointer',
    height: theme.spacing(3),
    color: GraySmall,
    borderRadius: theme.shape.borderRadius,
    fontWeight: theme.typography.fontWeightRegular,
    '& > .MuiChip-label': {
      padding: theme.spacing(0, 0.5),
    },
    '&:hover, &:focus': {
      backgroundColor: HoverNonBorderBackground,
    },
    '&:active': {
      boxShadow: RegularButtonHoverShadow,
    },
  },
}))(Chip) as typeof Chip;

export interface BreadCrumbItem {
  label: string;
  path?: string;
  textTransform?:
    | 'capitalize'
    | 'full-size-kana'
    | 'full-width'
    | 'lowercase'
    | 'none'
    | 'uppercase';
}
export const BreadCrumbItem: React.FC<BreadCrumbItem> = ({
  label,
  path,
  textTransform,
}) => {
  const classes = useStyles({ textTransform });
  const { navigate } = useNavigate();

  const handleRedirection = React.useCallback(() => {
    if (path) {
      navigate(path);
    }
  }, [navigate, path]);

  // if path is not provided, then wrap the label in a chip, otherwise, wrap the label in normal text
  return path ? (
    <StyledChip
      key={path}
      label={
        <BaseTypography fontType="13Medium" className={classes.node}>
          {label}
        </BaseTypography>
      }
      onClick={handleRedirection}
    />
  ) : (
    <BaseTypography fontType="13Medium" className={classes.node}>
      {label}
    </BaseTypography>
  );
};
export interface BreadCrumbBaseProps {
  items: BreadCrumbItem[];
  showOnlyTitleOnMobile?: boolean;
}
export const BreadCrumbBase: React.FC<BreadCrumbBaseProps> = ({
  items,
  showOnlyTitleOnMobile = false,
}) => {
  const classes = useStyles();

  const previousPage = items.at(items.length - 2);

  useEscNavigation(previousPage?.path || '', {
    disabled: items.length === 1,
  });

  const theme = useTheme();
  const isExtraSmallViewport = useMediaQuery(theme.breakpoints.down('xs'));

  // if showOnlyTitleOnMobile is true, then show only the last item in the breadcrumb
  // this is done to make sure that the breadcrumb does not take up too much space on mobile
  const formattedBreadcrumbItems =
    isExtraSmallViewport && showOnlyTitleOnMobile ? items.slice(-1) : items;

  return (
    <Breadcrumbs
      classes={{
        separator: classes.separator,
        root: classes.node,
        ol: classes.breadcrumbsListContainer,
        li: classes.breadcrumbListItem,
      }}
      className={classes.breadcrumbsRoot}
      separator={<ForwardIcon />}
      aria-label="breadcrumb"
      color="initial"
    >
      {formattedBreadcrumbItems.map(({ label, path, textTransform }) => (
        <BreadCrumbItem
          key={label}
          label={label}
          path={path}
          textTransform={textTransform}
        />
      ))}
    </Breadcrumbs>
  );
};
