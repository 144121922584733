import React from 'react';
import { TablePropertyFields } from 'src/constants/dataTypes';

interface TablePropertiesContextType {
  columnSettings?: TablePropertyFields; // column settings with field visibility only
  setColumnSettings: (columnSettings?: TablePropertyFields) => void;
  columnSettingsEntityId?: string; // column settings entity id, after column settings are saved at the BE
  setColumnSettingsEntityId: (propertiesId?: string) => void;
  useColumnDefsForSorting?: boolean; // use column defs for sorting
}

// set the defaults
export const TablePropertiesContext = React.createContext({
  setColumnSettings: () => {
    console.info('setColumnSettings not provided');
  },
  setColumnSettingsEntityId: () => {
    console.info('setColumnSettingsEntityId not provided');
  },
} as TablePropertiesContextType);
