/* eslint-disable no-param-reassign */
import _ from 'lodash';
import {
  AppAPIName,
  MessagesAPIName,
  UnAuthAPIName,
  UsersAPIName,
} from 'src/constants';
import {
  devClientUserDomain,
  internalCopilotDomainProd,
  internalCopilotDomainStaging,
  prodClientUserDomain,
} from 'src/constants/middlewareConsts';
import { defaultTheme, faviconUrl } from 'src/context';
import { ChangeModel } from 'src/store/ui/types';
import { getAxiosInstance } from 'src/utils/ApiUtils';
import {
  portalConfigNameListModel,
  NameListModel,
} from 'src/utils/PortalUtils/portalModels';

export interface FeaturesConfigType {
  signupDisabled?: boolean;
  clientInvitesDisabled?: boolean;
  disableCompanies?: boolean;
  disableGoogleSignIn?: boolean; // read-only: this is being set from structFields
}

const FeaturesConfig: FeaturesConfigType = {};

export const defaultAuthImage = '/images/auth_image.png';

interface setupConfigInput {
  config: any;
  apiDomain: string;
  apiStage?: string;
  apiRegion?: string;
  coreApiUrl?: string;
  unAuthApiUrl?: string;
  usersApiUrl?: string;
  getStreamApiUrl?: string;
  wssEndpoint?: string;
}

// setupPortalConfig populates the default values in the PortalConfig
// object returned from the loadPortalConfig method if there are not
// any values returned from the API Get request. This likely happens
// in the case you are an `internal` user, and should take the values
// from the
export const setupPortalConfig = ({
  config,
  apiStage,
  apiRegion = 'us-west-2',
  apiDomain,
  coreApiUrl,
  unAuthApiUrl,
  usersApiUrl,
  getStreamApiUrl,
  wssEndpoint,
}: setupConfigInput) => {
  // do not use constants variable here to skip circular ref.
  config.features = config.features || FeaturesConfig;
  config.assets.logoStyle = config.assets.logoStyle || {
    filter: 'brightness(0) invert(1)',
    height: '35px',
  };

  config.assets.authImage = config.assets.authImage || {
    signInUrl: defaultAuthImage,
    signInStyle: {},
    signUpUrl: defaultAuthImage,
    signUpStyle: {},
  };

  config.assets.authImage.signInUrl =
    config.assets.authImage.signInUrl || defaultAuthImage;
  config.assets.authImage.signUpUrl =
    config.assets.authImage.signUpUrl || defaultAuthImage;
  config.assets.socialShareImage.imageUrl =
    config.assets.socialShareImage.imageUrl || '';

  const baseEndpoint = {
    region: apiRegion,
  };

  // api endpoints are used when configuring Amplify with API.configure
  // Amplify is the client used to make api calls and handles setting signatures and headers.
  // These settings tell the Amplify client what urls to use for the api
  const coreAPIEndpoint = coreApiUrl || `https://app-api.${apiDomain}`;
  config.AWS.API.endpoints = [
    {
      ...baseEndpoint,
      name: AppAPIName,
      endpoint: coreAPIEndpoint,
      wssEndpoint,
    },
    {
      ...baseEndpoint,
      name: MessagesAPIName,
      endpoint: getStreamApiUrl || coreAPIEndpoint,
    },
    {
      ...baseEndpoint,
      name: UnAuthAPIName,
      endpoint: unAuthApiUrl || coreAPIEndpoint,
    },
    {
      ...baseEndpoint,
      name: UsersAPIName,
      endpoint: usersApiUrl || coreAPIEndpoint,
    },
  ];

  config.BaseAPIUrl = config.AWS.API.endpoints[0].endpoint;

  config.MarketingSite = config.MarketingSite || {
    Enabled: false,
    S3Prefix: '',
  };

  config.Chat = {
    ClientId: apiStage === 'dev' ? 'uwae3u5x7eyz' : 'juz5j72pjuuu',
  };
};

export const getPortalHostFromHostname = (host: string): string => {
  if (!host) {
    return 'localhost';
  }

  const hostParts = host.split('.');
  // see if host format is like: subdomain.domain.ext
  // or subdomain.domain.co.uk
  if (hostParts.length > 2) {
    // if portal is hosted by us then the domain.net part will be
    // copilot.app
    if (
      host.endsWith('joinportal.com') || // this is used for backward compatibility
      host.endsWith(prodClientUserDomain) ||
      host.endsWith(devClientUserDomain)
    ) {
      return hostParts.at(0) || '';
    }

    // otherwise the portal is hosted on a subdomain like www, portal etc. and just want the
    // domain part in host
    return hostParts.at(1) || '';
  }

  // see if host format is like: mydomain.com
  if (hostParts.length === 2) {
    return hostParts.at(0) || ''; // only return the domain name with the suffix
  }

  // portal setup to use custom domain config should be
  // placed in customdomain dir
  return host.replace('.', '-');
};

interface loadPortalConfigOptions {
  hostname: string;
  stage: string;
  subdomain: string;
  apiRegion?: string;
  coreApiUrl: string;
  unAuthApiUrl: string;
  usersApiUrl: string;
  getStreamApiUrl: string;
  viewMode?: string;
  wssEndpoint?: string;
}

export const loadPortalConfig = async ({
  hostname,
  stage,
  subdomain,
  apiRegion,
  coreApiUrl,
  unAuthApiUrl,
  usersApiUrl,
  getStreamApiUrl,
  viewMode,
  wssEndpoint,
}: loadPortalConfigOptions) => {
  console.info('Loading portalConfig for', hostname);
  const portalHostname = getPortalHostFromHostname(hostname);
  console.info('Using portal hostname', portalHostname);

  const apiDomain =
    stage === 'production'
      ? internalCopilotDomainProd
      : internalCopilotDomainStaging;

  const axiosService = getAxiosInstance({
    apiDomain,
    apiUrl: unAuthApiUrl || coreApiUrl,
  });

  // this sets up the structure of the portalConfig
  // in two ways:
  //  1. you are a client user that needs to fetch the properties
  //      from the subdomain record and get the right values for this
  //      particular copilot account
  //  2. you are an internal user that needs to get the default values
  //      and configResponse is there to create the default structure of
  //      the portalConfig such that we can use `defaultTheme` to populate
  //      the fields
  //
  // This will always return a 200 OK frm the backend regardless of who you are
  const configResponse = await axiosService.get(
    `/portal/${portalHostname}/config`,
    {
      params: { subdomain, viewMode },
    },
  );

  // unfold that structure here to conditionally set this value based
  // on the `hostname`
  const portalConfig = configResponse.data;
  portalConfig.viewMode = viewMode;
  // when the loading the workspaces config, the portalConfig is empty
  // so defaults need to be set
  if (hostname === 'workspaces') {
    portalConfig.portalHeader = 'workspace';
    portalConfig.theme = defaultTheme;
  }

  if (!portalConfig.portalHeader) {
    throw new Error('no portal found');
  }

  // when view mode is internal, then update portalConfig metadata
  // to show copilot branding
  if (viewMode === 'internal') {
    // set title and favicon overrides for internal experience
    portalConfig.metadata.titleOverride = `Copilot`;
    if (portalConfig.name) {
      // append portal name if not empty, other only show Copilot Admin (login page)
      portalConfig.metadata.titleOverride += ` Dashboard \u2013 ${portalConfig.name}`;
    }
    portalConfig.metadata.faviconOverride = faviconUrl;
  }

  const apiStage = stage === 'production' ? '' : stage;
  setupPortalConfig({
    config: portalConfig,
    apiStage,
    apiDomain,
    apiRegion,
    coreApiUrl,
    unAuthApiUrl,
    usersApiUrl,
    getStreamApiUrl,
    wssEndpoint,
  });

  return portalConfig;
};

export const getConfigKey = (key: string, nameList: NameListModel) => {
  const mappedNameKey = Object.keys(nameList).find((name) => name === key);
  if (mappedNameKey) {
    return nameList[mappedNameKey];
  }
  return null;
};

export const MapConfigProperty = (changes: ChangeModel[], config: any) => {
  const portalConfig = _.cloneDeep(config);
  if (changes && changes.length) {
    changes.forEach((change) => {
      const configKey = getConfigKey(change.name, portalConfigNameListModel);
      if (configKey) {
        _.set(
          portalConfig,
          configKey.path,
          configKey.invertBooleanValue ? !change.value : change.value,
        );
      }
    });
  }
  return portalConfig;
};
