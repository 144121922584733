import { Auth, Logger } from 'aws-amplify';
import Stripe from 'stripe';
import { AxiosError } from 'axios';
import { API } from 'src/utils/AmplifyApiUtils';
import { AppThunkAction, AppThunkDispatch } from 'src/store/reduxTypes';
import PaymentsClient from 'src/clients/PaymentsClient';
import UsersClient from 'src/clients/UsersClient';
import * as UserStoreTypes from 'src/store/user/types';
import * as UsersStoreTypes from 'src/store/users/types';
import {
  alertSnackbar,
  showTrialBannerAction,
  clearBannerAction,
} from 'src/store/ui/actions';
import * as PlansUtils from 'src/utils/PlansUtils';
import { CallApiWithNotification } from 'src/clients/ApiService';
import ApiUtils from 'src/utils/ApiUtils';
import { GetCurrentUserFromState } from 'src/store/storeUtils';
import { OnboardingStatus } from 'src/entities/OnboardingStatus';
import { AuthErrors } from 'src/constants/errorConsts/errorCodesConsts';

const logger = new Logger('UserStoreTypes');

export const updateViewMode = (mode: string) => ({
  type: UserStoreTypes.UPDATE_VIEW_MODE,
  mode,
});

export const updateUserId =
  (id: string): AppThunkAction =>
  (dispatch) => {
    if (!id) {
      return Promise.resolve();
    }

    return dispatch({
      type: UserStoreTypes.UPDATE_USER_ID,
      id,
    });
  };

export const clearUser = (): AppThunkAction => async (dispatch) => {
  dispatch({
    type: UserStoreTypes.CLEAR_USER,
  });
};

export const setUserLoadingErrorAction = (error: string) => ({
  type: UserStoreTypes.LOAD_USER_ERROR,
  error,
});

export const updateUserAttributes =
  (
    updatedAttributes: object,
    updateOptions?: {
      disableApiFailureNotification?: boolean;
    },
  ): AppThunkAction =>
  async (dispatch, getState) => {
    const { disableApiFailureNotification } = updateOptions || {};
    const state = getState();
    const currentUser = GetCurrentUserFromState(state);
    const { user } = state;
    if (!user) {
      return;
    }

    function updateComplete(attributes?: object) {
      dispatch({
        type: UserStoreTypes.UPDATED_USER_ATTRIBUTE,
        attributes,
      });
    }

    function updateUserInMembers(payload: any) {
      dispatch({
        type: UsersStoreTypes.UPDATE_MEMBER_SUCCESS,
        payload,
      });
    }

    dispatch({ type: UserStoreTypes.UPDATING_USER_ATTRIBUTE });

    const fields = {
      ...updatedAttributes,
      companyId: currentUser?.fields.companyId,
      companyName: currentUser?.fields.companyName,
    };

    try {
      const result = await CallApiWithNotification({
        executeFunction: UsersClient.updateProfileAttributes,
        checkFunction: ApiUtils.IsBatchUpdateResultSuccessful,
        params: {
          userId: currentUser?.id,
          isClient: user.isClient,
          fields,
        },
        successMessage: 'Profile updated.',
        errorMessage: `The changes on the profile could not be updated.`,
        dispatch,
        disableApiFailureNotification,
      });

      if (result.status) {
        if (currentUser) {
          updateUserInMembers({
            ...currentUser,
            fields: {
              ...currentUser.fields,
              ...fields,
            },
          });
        }
        updateComplete(updatedAttributes);
      } else {
        logger.warn('Failed to update user attributes', result.data);
        updateComplete();
      }
    } catch (error) {
      if ((error as Error).message.includes('AliasExistsException')) {
        dispatch(
          alertSnackbar({
            errorMessage: AuthErrors.emailAlreadyInUse,
          }),
        );
      }
    }
  };

export const setTrialState =
  (
    stripeSubscriptions?: Stripe.Subscription[],
    onboardingStatus?: OnboardingStatus,
  ): AppThunkAction =>
  async (dispatch) => {
    if (!stripeSubscriptions) {
      return;
    }

    const trialSubscription =
      PlansUtils.activeTrialSubscription(stripeSubscriptions);

    const showTrialBanner = PlansUtils.hasActiveTrial(stripeSubscriptions);
    if (showTrialBanner) {
      const trialText = PlansUtils.getTrialDaysRemainingText({
        subscription: trialSubscription,
        onboardingTaskStatus: onboardingStatus,
      });
      if (trialText) {
        dispatch(showTrialBannerAction(trialText));
      }
    } else {
      dispatch(clearBannerAction());
    }
  };

export const resetUserLoadedAction = () => ({
  type: UserStoreTypes.CHANGE_USER_LOADED_STATE,
  loaded: false,
});

export const clearData = (): AppThunkAction => async (dispatch) => {
  dispatch(resetUserLoadedAction());
};

export const changeUserPassword =
  (oldPassword: string, newPassword: string): AppThunkAction =>
  async (dispatch) => {
    try {
      const authUser = await UsersClient.getAuthUser({ getCognitoUser: true });
      const res = await Auth.changePassword(authUser, oldPassword, newPassword);
      if (res && res === 'SUCCESS') {
        dispatch(
          alertSnackbar({
            successMessage: 'Your password was updated successfully.',
          }),
        );
      }
    } catch (error) {
      const e = error as AxiosError;
      dispatch(
        alertSnackbar({
          axiosError: e.response,
          errorMessage: 'We were unable to update your password.',
        }),
      );
    }
  };

export const addUserPassword =
  (userId: string, newPassword: string, isClient: boolean): AppThunkAction =>
  async (dispatch) => {
    CallApiWithNotification({
      executeFunction: (params) => UsersClient.addPassword(params, isClient),
      params: { userId, newPassword, isClient },
      successMessage: `Password was added successfully.`,
      errorMessage: `Password was not set.`,
      dispatch,
    });
  };

export const initializeOnboardingTestData = async () => {
  try {
    await API.post('AppAPI', `/onboarding/initialize/testdata`, {
      body: {},
    });
  } catch (err) {
    logger.debug('Failed to initialize onboarding test data');
  }
};

export const updateCustomerSubscriptionAction = (
  subscription: Stripe.Subscription,
) => ({
  type: UserStoreTypes.UPDATE_USER_SUBSCRIPTION_DONE,
  subscription,
});

export const changeBillingPlan =
  (priceId: string) => async (dispatch: AppThunkDispatch) => {
    dispatch({
      type: UserStoreTypes.UPDATING_USER_SUBSCRIPTION,
      updating: true,
    });

    const result = await CallApiWithNotification<Stripe.Subscription>({
      executeFunction: PaymentsClient.changePlan,
      params: priceId,
      successMessage: '',
      errorMessage: `Your plan could not be changed. Please contact support.`,
      dispatch,
    });

    if (result.status) {
      dispatch(clearBannerAction());
      dispatch(updateCustomerSubscriptionAction(result.data));
    } else {
      dispatch({
        type: UserStoreTypes.UPDATING_USER_SUBSCRIPTION,
        updating: false,
      });
    }

    return result;
  };

export const setStripeCustomerAction = (stripeCustomer: Stripe.Customer) => ({
  type: UserStoreTypes.SET_STRIPE_CUSTOMER,
  stripeCustomer,
});

export const loadStripeCustomerAction =
  (customerId: string): AppThunkAction =>
  async (dispatch) => {
    try {
      const result = await UsersClient.getCustomer(customerId);
      dispatch(setStripeCustomerAction(result.data));
    } catch (err) {
      logger.debug('Failed to load client');
    }
  };
