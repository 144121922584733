// This hook returns an object that toggles on and off
// the analytics tabs on the homepage.
import { useAppSelector } from 'src/hooks/useStore';
import { useRouteAccess } from 'src/routes/routeAccess';

// the analytics tabs on the homepage.
export function useAnalyticsAccess() {
  const accountInfo = useAppSelector((state) => state.payments.accountInfo);
  const routeAccess = useRouteAccess();

  return {
    CLIENT: true,
    CLIENT_ACTIVATION: true,
    SUBSCRIPTION:
      routeAccess.allowInvoiceDetailsPage &&
      accountInfo?.default_currency.toLowerCase() === 'usd',
  } as const;
}
