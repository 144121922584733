import { useState } from 'react';

interface CurrentDataModel {
  id: string;
  data: any;
}

const useActionRecord = (action: any, idField: string) => {
  const initialState = {
    id: '',
    data: null,
  };

  const [currentData, setCurrentData] =
    useState<CurrentDataModel>(initialState);

  const handleClickAction = (data: any) => {
    if (data && data[idField]) {
      setCurrentData({
        id: data[idField],
        data,
      });
    }
  };

  const handleClose = () => {
    setCurrentData(initialState);
  };

  const handleConfirm = () => {
    action(currentData.id);
    handleClose();
  };

  const handleGetCurrentData = () => {
    action(currentData);
    handleClose();
  };

  return {
    currentData,
    handleClickAction,
    handleClose,
    handleConfirm,
    handleGetCurrentData,
  };
};

export default useActionRecord;
