import React, { ComponentProps } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'src/store';
import { removeDuplicates } from 'src/utils/array';
import * as UserUtils from 'src/utils/UserUtils';
import ComboBox, { ComboBoxOption } from 'src/components/Select/ComboBox';
import { PortalConfigContext } from 'src/context';
import {
  CLIENT_TYPE,
  DEFAULT_FILTER_RESULTS_LIMIT,
  TEAM_TYPE,
} from 'src/constants/multiSelectConsts';
import { Client } from 'src/store/clients/types';
import { User } from 'src/constants/dataTypes';
import { TextFieldSizeVariant } from 'src/components/TextField';
import {
  GROUP_OPTION_MAPPER,
  isGroupOption,
} from 'src/constants/multiSelectConsts';

type Member = User | Client;

export interface MultiCategorySelectProps {
  id: string;
  label: string;
  placeholder: string;
  showPlaceholder: boolean;
  onChange: (
    e: React.ChangeEvent<{}>,
    vals: ComboBoxOption | ComboBoxOption[] | null,
  ) => void;
  values: string[] | string;
  multiple: boolean;
  error?: boolean;
  helperText?: any;
  includeClientUsers?: boolean;
  includeInternalUsers?: boolean;
  disabled?: boolean;
  filterResultsLimit?: number;
  optionToExclude?: string | string[];
  showAllValues?: boolean;
  allowSelfUser?: boolean;
  showClientsInOtherCompanies?: boolean;
  textFieldVariant?: TextFieldSizeVariant;
  disabledUserIDs?: string[];
  autoFocus?: boolean;
  useFullClientIDs?: boolean;
}

/**
 * This component is used in channel forms, message recipient selection and
 * intake form selection for the purpose of selecting users
 *
 * TODO: There is no multi-category aspect to this any longer and this
 * should be refactored to be called UserSelectDropdown
 *
 * TODO: Refactor the Combobox component that is called as a child. It is redundant because it
 * is not used anywhere else
 */
export const MultiCategorySelect: React.FC<MultiCategorySelectProps> = ({
  id,
  label,
  placeholder,
  showPlaceholder,
  onChange,
  values,
  multiple,
  error,
  helperText,
  includeClientUsers = true,
  includeInternalUsers = true,
  disabled = false,
  filterResultsLimit = DEFAULT_FILTER_RESULTS_LIMIT,
  optionToExclude = '',
  showAllValues = true,
  allowSelfUser = false,
  showClientsInOtherCompanies = false,
  textFieldVariant = 'skinny',
  disabledUserIDs = [],
  autoFocus = true,
  useFullClientIDs = true,
}) => {
  const portalConfig = React.useContext(PortalConfigContext);
  const user = useSelector((state: RootState) => state.user);
  const [clientUsers, setClientUsers] = React.useState<Client[]>([]);
  const [activeInvitedClients, setActiveInvitedClients] = React.useState<
    Client[]
  >([]);
  const [internalUsers, setInternalUsers] = React.useState<User[]>([]);
  const activeClients = useSelector(
    (state: RootState) => state.clients.activeClients,
  );
  const usersState = useSelector((state: RootState) => state.users);
  const [dropdownOptions, setDropdownOptions] = React.useState<
    ComboBoxOption[] | null
  >(null);
  const [allDropdownOptions, setAllDropdownOptions] = React.useState<
    ComboBoxOption[] | null
  >(null);

  const [selectedOptions, setSelectedOptions] = React.useState<
    ComboBoxOption[] | ComboBoxOption | null
  >(multiple ? [] : null);

  const updateSelectedOptions = (
    pDropdownOptions = dropdownOptions,
    pAllDropdownOptions = allDropdownOptions,
  ) => {
    if (!values || !pDropdownOptions || !pAllDropdownOptions) {
      return;
    }
    if (Array.isArray(values)) {
      setSelectedOptions(
        showAllValues
          ? pAllDropdownOptions.filter((option: ComboBoxOption) =>
              values.includes(option.id),
            )
          : pDropdownOptions.filter((option: ComboBoxOption) =>
              values.includes(option.id),
            ),
      );
    } else {
      const opt = showAllValues
        ? pAllDropdownOptions.find(
            (option: ComboBoxOption) => values === option.id,
          )
        : pDropdownOptions.find(
            (option: ComboBoxOption) => values === option.id,
          );

      if (opt) {
        setSelectedOptions(opt);
      }
    }
  };

  // after selecting an option, we should
  // update dropdown options
  React.useEffect(() => {
    updateSelectedOptions();
  }, [values]);

  const getCategoryUsersWithConditions = (
    shouldInclude: boolean,
    resultsLimit: number,
    usersArr: Array<Member>,
  ) => {
    const tempUsers = shouldInclude ? usersArr : [];
    const cutUsers = resultsLimit
      ? tempUsers.slice(0, resultsLimit)
      : tempUsers;
    const usersWithoutExcludedOption = cutUsers.filter(
      (item) => optionToExclude.indexOf(item.id) < 0,
    );
    return usersWithoutExcludedOption;
  };

  const getAllUsersWithConditions = (
    clients: Array<Client>,
    internals: Array<User>,
    optionsLimit: number = filterResultsLimit,
  ) => {
    const clientUsersOptions = getCategoryUsersWithConditions(
      includeClientUsers,
      optionsLimit,
      clients,
    );

    const internalUsersOptions = getCategoryUsersWithConditions(
      includeInternalUsers,
      optionsLimit,
      internals,
    );

    return [...clientUsersOptions, ...internalUsersOptions].sort((a, b) =>
      b.createdDate.localeCompare(a.createdDate),
    );
  };

  /**
   * This method is used to classify options
   * item by their type (either it is a client
   * user/internal user or else)
   * @param member
   */
  const getMemberType = (member: Member) => {
    // if there aren't any roles for the member, then
    // they were a test user who never got roles assigned to them
    // so we default to CLIENT_TYPE
    if (!member.fields.roles) {
      return CLIENT_TYPE;
    }
    // check member roles to indicate member type (client/internal)
    if (member.fields.roles[0].includes('client')) {
      return CLIENT_TYPE;
    }

    return TEAM_TYPE;
  };

  /**
   * This method extract needed data that will be
   * shown in the dropdown list from users array
   * @param users
   */
  const getFormattedOptions = (users: Array<Member>) =>
    (allowSelfUser ? users : users.filter((u: Member) => u.id !== user.id)).map(
      (u: Member) => {
        return {
          id: u.fields.givenName && useFullClientIDs ? u.getstreamId : u.id,
          label: u.fields.givenName
            ? `${u.fields.givenName} ${u.fields.familyName}`
            : u.fields.name,
          type: getMemberType(u),
          avatar: u.fields.name
            ? u.fields.avatarImageUrl
            : u.fields.avatarImageUrl,
          fallbackColor: u.fields.fallbackColor,
        } as ComboBoxOption;
      },
    );

  const resetInitialOptions = () => {
    const allUsers = getAllUsersWithConditions(clientUsers, internalUsers);
    const formattedOptions = getFormattedOptions(allUsers);
    setDropdownOptions(formattedOptions);
  };

  React.useEffect(() => {
    if (activeClients && usersState.internalUsers) {
      const activeUsers = usersState.internalUsers.filter(
        UserUtils.isUserEnabled,
      );
      const enabledClients = activeClients.filter(UserUtils.isUserEnabled);
      setActiveInvitedClients(activeClients);

      const clients = removeDuplicates(enabledClients, 'id');
      const internals = removeDuplicates(activeUsers, 'id');

      setClientUsers(clients);
      setInternalUsers(internals);

      const initialOptions: ComboBoxOption[] = getFormattedOptions(
        getAllUsersWithConditions(clients, internals),
      );
      setDropdownOptions(initialOptions);

      const allOptions: ComboBoxOption[] = getFormattedOptions(
        getAllUsersWithConditions(clients, internals, 0),
      );
      setAllDropdownOptions(allOptions);

      updateSelectedOptions(initialOptions, allOptions);
    }
  }, [activeClients, usersState.internalUsers, optionToExclude]);

  const handleComboBoxInputChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const { value } = event.target;
    if (value.length > 0) {
      const array = getAllUsersWithConditions(
        activeInvitedClients,
        internalUsers,
        0,
      ).filter((u: Member) =>
        u.fields?.name
          ? u.fields.name.toLowerCase().includes(value.toLowerCase())
          : `${u.fields.givenName} ${u.fields.familyName}`
              .toLowerCase()
              .includes(value.toLowerCase()),
      );
      const formattedOptions = getFormattedOptions(array);
      setDropdownOptions(formattedOptions);
    } else {
      resetInitialOptions();
    }
  };

  const getGroupingProps = () => ({
    groupBy: (option: ComboBoxOption) =>
      GROUP_OPTION_MAPPER[isGroupOption(option.type) ? option.type : 'text'] ||
      '',
  });

  /**
   * This is where we're filtering dropdown options after
   * item selection. f.e. when we select a client user
   * here we filter all clients that belongs to the same
   * company as the initial selected client.
   * @param options: the current dropdown available options
   */
  const handleFilterOptions: ComponentProps<
    typeof ComboBox
  >['filterOptions'] = (options, params) => {
    let opts = options.slice(0, 6);

    // Adding 'No results found' option
    if (opts.length === 0 && params.inputValue !== '') {
      opts.push({
        id: 'no-option',
        type: 'no-option',
        label: params.inputValue,
      });
    }
    if (!Array.isArray(values)) {
      return opts;
    }

    // get client user from the selected members
    // if this component is not being used with a channel (useFullClientIDs=false), we find client values using entity ID not "full" ID
    const clientsArray = values
      .map((clientID: string) =>
        activeInvitedClients.find((c) =>
          useFullClientIDs
            ? UserUtils.getFullUserIdFromUserId(portalConfig, c.id) === clientID
            : c.id === clientID,
        ),
      )
      .filter(Boolean);

    if (showClientsInOtherCompanies) {
      return opts;
    }

    if (clientsArray.length > 0) {
      // if there are client users
      // removing other client user from the list and they we be
      // replaced by the clients in the same company as the initial
      // selected client

      opts = opts.filter(
        (o) =>
          o && typeof o === 'object' && 'type' in o && o.type !== CLIENT_TYPE,
      );

      // we need to get only clients with company
      const activeClientsWithCompanies = activeInvitedClients.filter(
        (c) => c.fields.companyId,
      );

      const selectedClientsColleagues = activeClientsWithCompanies.filter(
        (c) =>
          c.fields.companyId === clientsArray.at(0)?.fields.companyId &&
          !values.includes(
            useFullClientIDs
              ? UserUtils.getFullUserIdFromUserId(portalConfig, c.id)
              : c.id,
          ),
      );

      const formattedOptions = getFormattedOptions(selectedClientsColleagues);

      // combine filtered results
      opts = opts.concat(formattedOptions);
    }

    return opts;
  };
  /**
   * filterOptions handles options filtering
   * after an item is selected.
   */
  const getFilterOptions = () => ({
    filterOptions: handleFilterOptions,
  });

  return dropdownOptions ? (
    <ComboBox
      textFieldVariant={textFieldVariant}
      disabled={disabled}
      error={error}
      helperText={helperText}
      id={id}
      label={label}
      placeholder={placeholder}
      showPlaceholder={showPlaceholder}
      onBlur={() => resetInitialOptions()}
      onChange={(event, vals) => {
        if (!multiple) {
          setSelectedOptions(vals);
        }
        resetInitialOptions();
        if (onChange) onChange(event, vals);
      }}
      {...getFilterOptions()}
      options={dropdownOptions || []}
      {...getGroupingProps()}
      values={selectedOptions}
      additionalComboBoxProps={{
        inputProps: {
          autoFocus,
          onChange: handleComboBoxInputChange,
        },
      }}
      withAvatars
      hideSelectedItems
      multiple={multiple}
      disabledIDs={disabledUserIDs}
    />
  ) : null;
};
