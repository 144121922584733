import React from 'react';
import Button from 'src/components/Button';
import { HomePageSection } from 'src/components/HomePage/HomePageSection';
import { INBOX_PAGE, MESSAGES_PAGE } from 'src/constants';
import history from 'src/history';
import { useInProductNotificationsState } from 'src/hooks/useInProductNotificationsState';
import { useNotificationsCounts } from 'src/hooks/useNotificationsCount';
import { useAppSelector } from 'src/hooks/useStore';
import { useRouteAccess } from 'src/routes/routeAccess';
import { useGetInboxNotificationsQuery } from 'src/services/api/inboxApi';
import { formatNotificationCount } from 'src/utils/NotificationsUtils';

const getPlural = (count: number) => (count > 1 ? 's' : '');

type ActionItemType = {
  icon: React.ComponentType<any>;
  label: string;
  link: string;
};

export const UserActionItems = () => {
  const { data: allInboxNotifications } = useGetInboxNotificationsQuery();
  const routeAccess = useRouteAccess();
  const { clients, internalUsers, companyDataMap, messagesNotificationsCount } =
    useAppSelector((state) => ({
      clients: state.clients.clients,
      internalUsers: state.users.internalUsers,
      companyDataMap: state.clients.companyDataMap,
      messagesNotificationsCount: state.messages.totalUnreadMessages,
    }));

  const allUsers = [...clients, ...internalUsers];

  const { unreadNotificationCount: inboxNotificationsCount } =
    useNotificationsCounts(
      allInboxNotifications || [],
      allUsers,
      companyDataMap,
    );

  const {
    isInboxInProductNotificationsDisabled,
    isMessagesInProductNotificationsDisabled,
  } = useInProductNotificationsState();
  /**
    * User action items take the app's unread notifications count and turn them into actionable items. 
    * Users can click on these items to navigate to the respective pages. 
    * The notifications buttons are grouped as follows:
      - Inbox notifications
      - File notifications
      - Messages notifications
  */
  const userActionItems = React.useMemo<ActionItemType[]>(() => {
    const actions = [];
    if (inboxNotificationsCount && !isInboxInProductNotificationsDisabled) {
      actions.push({
        label: `${formatNotificationCount(
          inboxNotificationsCount,
        )} unread notification${getPlural(inboxNotificationsCount)}`,

        link: INBOX_PAGE.path,
        icon: INBOX_PAGE.icon,
      });
    }

    if (
      routeAccess.allowMessagesPage &&
      messagesNotificationsCount &&
      !isMessagesInProductNotificationsDisabled
    ) {
      actions.push({
        label: `${formatNotificationCount(
          messagesNotificationsCount,
        )} unread message${getPlural(messagesNotificationsCount)}`,
        link: MESSAGES_PAGE.path,
        icon: MESSAGES_PAGE.icon,
      });
    }

    return actions;
  }, [inboxNotificationsCount, messagesNotificationsCount]);

  if (!userActionItems.length) {
    return null;
  }

  return (
    <HomePageSection title="Action items" subTitle="">
      <div className="flex justify-start gap-3">
        {userActionItems.map(({ label, link, icon: IconComponent }) => {
          const Icon = IconComponent;
          return (
            <div key={label}>
              <Button
                variant="contained"
                htmlId={`${label}-btn`}
                color="secondary"
                startIcon={<Icon />}
                onClick={() => {
                  history.push(link);
                }}
              >
                <span>{label}</span>
              </Button>
            </div>
          );
        })}
      </div>
    </HomePageSection>
  );
};
