import { CustomFieldEntity } from 'src/store/clients/types';
import { ApiMethods, ApiTags, appAPI } from '.';

export const clientsApi = appAPI.injectEndpoints({
  endpoints: (build) => ({
    listCustomFields: build.query<CustomFieldEntity['additionalFields'], void>({
      query: () => ({
        path: '/entities/custom-fields',
        method: ApiMethods.get,
        options: {
          queryStringParameters: {},
        },
      }),
      providesTags: [ApiTags.customFields],
      transformResponse: (response: CustomFieldEntity) =>
        response.additionalFields,
    }),
  }),
});

export const { useListCustomFieldsQuery } = clientsApi;
