import React from 'react';
import * as Yup from 'yup';
import { Box } from '@material-ui/core';

import { useSelector } from 'react-redux';
import { FormBodyAPI } from 'src/constants/fieldsTypes';
import { BaseTextField } from 'src/components/TextField';
import BaseTypography from 'src/components/Text/BaseTypography';
import { UserAvatarUploader } from 'src/components/User';
import { AssigneeList } from 'src/components/Assignee/AssigneeList';
import { RootState } from 'src/store';
import * as Colors from 'src/theme/colors';
import { User } from 'src/constants';

const validationScheme = Yup.object().shape({
  fields: Yup.object().shape({
    name: Yup.string().trim().required('Company name is required'),
    avatarImageURL: Yup.string().trim(),
  }),
});

const handleFocus = (
  event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
) => {
  const { target } = event;
  const textLength = target.value.length;
  target.setSelectionRange(0, textLength);
};

export const useEditCompanyForm = () => {
  const FormRenderer: React.FC<FormBodyAPI> = ({
    touched,
    errors,
    handleBlur,
    handleChange,
    setFieldValue,
    values,
  }) => {
    const { activeClients, companies } = useSelector((state: RootState) => ({
      activeClients: state.clients.activeClients,
      companies: state.clients.companies,
    }));

    const [companyAssignees, setCompanyAssignees] = React.useState<
      User[] | null
    >(null);

    React.useEffect(() => {
      if (!companyAssignees) {
        const selectedCompany = companies.find(
          (c) => c.id === values.fields.id,
        );
        if (selectedCompany) {
          const selectedCompanyAssignees = activeClients.filter(
            (u) => u.fields.companyId === selectedCompany.id,
          );
          setCompanyAssignees(selectedCompanyAssignees);
        }
      }
    }, [activeClients, companies, companyAssignees, values]);

    return (
      <Box>
        <Box display="flex">
          <UserAvatarUploader
            htmlId="edit-company-picture-uploader"
            initialLetters={values.fields.name.split(' ')[0]}
            name={values.fields.name || ' '}
            uploadIconSize={43}
            avatarUrl={values.fields.avatarImageURL}
            avatarSize="large"
            shape="square"
            handleChange={(pictureUrl: string) =>
              setFieldValue('fields.avatarImageURL', pictureUrl)
            }
            fallbackColor={values.fields.fallbackColor}
          />
          <Box ml={3}>
            <BaseTextField
              autoFocus
              error={Boolean(touched.fields?.name && errors.fields?.name)}
              fullWidth
              helperText={touched.fields?.name && errors.fields?.name}
              name="fields.name"
              id="newCompanyName"
              onBlur={handleBlur}
              onChange={handleChange}
              variant="outlined"
              label="Company name"
              defaultValue={values.fields.name}
              onFocus={handleFocus}
            />
          </Box>
        </Box>
        <Box mt={2}>
          <BaseTypography
            fontType="15Medium"
            style={{ color: Colors.BlackHeadings }}
          >
            Members
          </BaseTypography>
          <AssigneeList group options={companyAssignees || []} />
        </Box>
      </Box>
    );
  };

  return { validationScheme, FormRenderer };
};
