import * as React from 'react';
import { RegularCardBox } from 'src/components/Cards/RegularCardBox';
import { ActiveState } from 'src/components/HomePage/AnalyticsSection/ActiveState';
import { EmptyState } from 'src/components/HomePage/AnalyticsSection/EmptyState';
import { HomePageSection } from 'src/components/HomePage/HomePageSection';
import { useAppSelector } from 'src/hooks/useStore';

export function AnalyticsSection() {
  const clientUsers = useAppSelector((state) => state.clients.clients);

  return (
    <HomePageSection title="Analytics" subTitle="">
      <RegularCardBox className="p-6">
        {clientUsers.length < 10 ? <EmptyState /> : <ActiveState />}
      </RegularCardBox>
    </HomePageSection>
  );
}
