export const EMPTY_VAL = '__EMPTY__';

export const UPGRADE_TOOLTIP_LABEL = 'Please upgrade your plan';

export * from './crmStringConsts';
export * from './settingsStringConsts';
export * from './landingPageStringConsts';
export * from './helpCenterStringConsts';
export * from './onboardingStringConsts';
export * from './modalsStringConsts';
export * from './filesStringConsts';
export * from './domainStringConsts';
export * from './integrationsStringConsts';
export * from './extensionsStringConsts';
export * from './planStringConsts';
