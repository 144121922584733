import React from 'react';
import { makeStyles, Theme, Tooltip } from '@material-ui/core';
import * as Colors from 'src/theme/colors';
import * as Weights from 'src/theme/weights';

const useStyles = makeStyles((theme: Theme) => ({
  tooltipContainer: {
    width: 400,
    padding: theme.spacing(1),
    '& > *': {
      color: Colors.GraySmall,
    },
  },
  tooltipContent: {
    color: Colors.NonHoverBorder,
    fontSize: '14px',
    fontWeight: Weights.regular,
    lineHeight: '21px',
  },
}));

interface InfoTooltipProps {
  label: string;
  children: React.ReactElement;
}

const InfoTooltip: React.FC<InfoTooltipProps> = ({ label, children }) => {
  const classes = useStyles();
  return (
    <Tooltip
      title={label}
      classes={{
        popper: classes.tooltipContainer,
        tooltip: classes.tooltipContent,
      }}
    >
      {children}
    </Tooltip>
  );
};

export default InfoTooltip;
