import { CognitoUser as AmazonCognitoUser } from 'amazon-cognito-identity-js';
import Stripe from 'stripe';
import {
  CrmTableSettings,
  MessagesSettings,
  PortalSettings,
  User,
} from 'src/constants';
import { Notifications } from 'src/store/notifications/types';
import { Company } from 'src/store/clients/types';
import { Resource } from 'src/store/data/types';
import { ExtensionItem } from 'src/store/dashboard/types';
import { FileChannel } from 'src/store/files/types';

export const CLEAR_USER = 'CLEAR_USER';
export const UPDATE_USER_ID = 'UPDATE_USER_ID';
export const UPDATE_USER = 'UPDATE_USER';
export const UPDATED_USER_ATTRIBUTE = 'UPDATED_USER_ATTRIBUTE';
export const UPDATING_USER_ATTRIBUTE = 'UPDATING_USER_ATTRIBUTE';
export const UPDATING_USER_PAYMENT = 'UPDATING_USER_PAYMENT';
export const UPDATED_USER_PAYMENT = 'UPDATED_USER_PAYMENT';
export const CHANGE_USER_LOADED_STATE = 'CHANGE_USER_LOADED_STATE';
export const UPDATE_VIEW_MODE = 'UDPATE_VIEW_MODE';
export const REMOVE_GET_STARTED = 'REMOVE_GET_STARTED';
export const UPDATING_USER_SUBSCRIPTION = 'UPDATING_USER_SUBSCRIPTION';
export const UPDATE_USER_SUBSCRIPTION_DONE = 'UPDATE_USER_SUBSCRIPTION_DONE';
export const SET_STRIPE_CUSTOMER = 'SET_STRIPE_CUSTOMER';
export const LOAD_USER_ERROR = 'LOAD_USER_ERROR';

export interface CognitoUser extends AmazonCognitoUser {
  attributes: Record<string, string>;
  username: string;
}

export interface Operations {
  operations: Record<string, boolean>;
}

export interface Task {
  autoCheck: boolean;
  category: string;
  description: string;
  done: boolean;
  taskCode: string;
  title: string;
  dismissed?: boolean;
  url?: string;
  // ONly for team member tasks
  teamMembersCount?: number;
  clientMembersCount?: number;
}

export interface UserData {
  groups?: string[];
  permissions: Record<string, Operations>;
  havePlaceholderCompany: boolean;
  userId?: string;
  getstreamId?: string;
  portalId: string;
  notifications: Notifications;
  superUser?: boolean;
  stripeCustomer?: Stripe.Customer;
  stripeSubscriptions?: Stripe.Subscription[];
  stripePaymentMethods?: Stripe.PaymentMethod[];
  internalUsers?: User[];
  users?: User[];
  companies?: Company[];
  isClientUser: boolean;
  portalSettings: PortalSettings;
  messageSettings: MessagesSettings;
  crmTableProperties: CrmTableSettings;
  resources: Resource[];
  extensionItems: ExtensionItem[];
  fileChannels: FileChannel[];
  isTestUser?: boolean;
}

export interface UserState {
  isUnAuth: boolean;
  isClient: boolean;
  updatingAttributes: boolean;
  updatingPayment: boolean;
  updatingSubscription: boolean;
  loaded: boolean;
  data: UserData | null;
  instance: CognitoUser | null;
  viewMode: string;
  id: string;
  loading: boolean;
  loadingError: string | null;
  ref: string;
}

export interface PaymentAttributes {
  'custom:stripeUserId': string;
}

export interface UserAttributes {
  sub?: string;
  email_verified?: string;
  phone_number_verified?: string;
  phone_number?: string;
  email?: string;
  picture?: string;
  givenName?: string;
  familyName?: string;
  zoneinfo?: string;
  address?: string;
}

interface UpdateUserAction {
  type: typeof UPDATE_USER;
  user: {
    isClient: boolean;
    attributes: UserAttributes;
    ref: string;
  };
  userId: string;
  data: UserData;
}

interface UpdateViewModeAction {
  type: typeof UPDATE_VIEW_MODE;
  mode: string;
}

interface UserUserIdAction {
  type: typeof UPDATE_USER_ID;
  id: string;
}

interface ClearUser {
  type: typeof CLEAR_USER;
}

interface UpdateUserAttributesAction {
  type: typeof UPDATED_USER_ATTRIBUTE;
  attributes: Record<string, string>;
}

interface UpdatingUserAttributesAction {
  type: typeof UPDATING_USER_ATTRIBUTE;
}

interface UpdatingUserPaymentAction {
  type: typeof UPDATING_USER_PAYMENT;
}

interface UserLoadedAction {
  type: typeof CHANGE_USER_LOADED_STATE;
  loaded: boolean;
}

interface UpdatedUserPaymentAction {
  type: typeof UPDATED_USER_PAYMENT;
  paymentAttributes: PaymentAttributes;
  error: Error;
}
interface UpdatingUserSubscriptionActionType {
  type: typeof UPDATING_USER_SUBSCRIPTION;
  updating: boolean;
}

interface UpdatedUserSubscriptionActionType {
  type: typeof UPDATE_USER_SUBSCRIPTION_DONE;
  subscription: Stripe.Subscription;
}

interface SetStripeCustomerActionType {
  type: typeof SET_STRIPE_CUSTOMER;
  stripeCustomer: Stripe.Customer;
}

interface SetLoadUserErrorActionType {
  type: typeof LOAD_USER_ERROR;
  error: string;
}

export type UserActionTypes =
  | UpdateUserAction
  | UpdateViewModeAction
  | UserUserIdAction
  | UpdateUserAttributesAction
  | ClearUser
  | UpdatingUserAttributesAction
  | UpdatingUserPaymentAction
  | UpdatedUserPaymentAction
  | UserLoadedAction
  | UpdatingUserSubscriptionActionType
  | UpdatedUserSubscriptionActionType
  | SetStripeCustomerActionType
  | SetLoadUserErrorActionType;
