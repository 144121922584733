import React from 'react';
import { makeStyles, Button, Theme } from '@material-ui/core';
import BaseTypography from 'src/components/Text/BaseTypography';
import { typography13MediumStyle } from 'src/components/Text';
import { GraySmall, BlackHeadings, WebLink } from 'src/theme/colors';

export interface PageCalloutProps {
  title: string;
  description?: string;
  link?: string;
  linkText?: string;
  actionButtonRenderer?: () => React.ReactNode;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    maxWidth: '502px',
  },
  title: {
    color: BlackHeadings,
  },
  description: {
    textAlign: 'left',
    color: GraySmall,
    marginTop: theme.spacing(1.5),
  },
  linkButton: {
    marginTop: theme.spacing(1.5),
    ...typography13MediumStyle,
    color: WebLink,
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
  actionButtonWrapper: {
    marginTop: theme.spacing(4.25),
  },
}));

export const PageCallout: React.FC<PageCalloutProps> = ({
  title,
  description,
  link,
  linkText,
  children,
  actionButtonRenderer,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {children}
      <BaseTypography fontType="24Medium" className={classes.title}>
        {title}
      </BaseTypography>
      {description && (
        <BaseTypography fontType="15Regular" className={classes.description}>
          {description}
        </BaseTypography>
      )}
      {link && linkText && (
        <Button className={classes.linkButton} href={link} target="_blank">
          {linkText}
        </Button>
      )}
      {actionButtonRenderer && (
        <div className={classes.actionButtonWrapper}>
          {actionButtonRenderer()}
        </div>
      )}
    </div>
  );
};
