import { Button, MenuItem, Theme, makeStyles } from '@material-ui/core';
import { FieldArray, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import { Callout } from 'src/components/Callout';
import { InformationCircleIcon } from 'src/components/Icons';
import { FormModal } from 'src/components/Modals';
import RowDivider from 'src/components/RowDivider';
import { AppVisibilityRule } from 'src/components/Settings/AppVisibilityRule';
import BaseTypography, {
  typography13MediumStyle,
} from 'src/components/Text/BaseTypography';
import { BaseTextField } from 'src/components/TextField';
import {
  ModuleSettingsItem,
  VisibilityComparator,
  VisibilityRule,
  VisibilityRuleType,
} from 'src/constants';
import {
  BlackSmall,
  DarkFont,
  GraySmall,
  HoverBackground,
  NonHoverBorder,
} from 'src/theme/colors';
import { ensureUnreachable, generateUniqueId } from 'src/utils/common_utils';
import { isVisibilityType, rulesSchema } from 'src/utils/app_visibility';
import { toFormikValidationSchema } from 'zod-formik-adapter';

const useStyles = makeStyles((theme: Theme) => ({
  descriptionText: {
    color: GraySmall,
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
    padding: theme.spacing(2.5, 3.5, 3.5, 3.5),
  },
  title: {
    marginBottom: theme.spacing(0.75),
  },
  rulesContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2.5),
  },
  calloutContainer: {
    border: `1px solid ${NonHoverBorder}`,
    background: HoverBackground,
    borderRadius: theme.shape.borderRadius,
    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(2),
    },
  },
  calloutIcon: {
    color: GraySmall,
    fontSize: 16,
  },
  calloutInfo: {
    display: 'flex',
    alignItems: 'start',
    gap: theme.spacing(1),
  },
  addRuleBtn: {
    color: BlackSmall,
    border: `1px solid ${NonHoverBorder}`,
    padding: theme.spacing(0.25, 1),
    ...typography13MediumStyle,
  },
}));

export enum VisibilityType {
  VisibleToEveryone = 'visibleToEveryone',
  NoVisibility = 'noVisibility',
  CustomVisibility = 'customVisibility',
}

type Props = {
  onCloseModal: () => void;
  open: boolean;
  moduleVisbilityConfig: VisibilityRule[];
  moduleSettings: ModuleSettingsItem[];
  onVisibilityConfigSaved: (values: AllVisibilityRules) => void;
};

function getDefaultVisibilityRule(): VisibilityRule {
  const uniqueId = generateUniqueId();
  return {
    ruleType: VisibilityRuleType.Client,
    comparator: VisibilityComparator.IncludesAnyOf,
    values: [],
    ruleKey: uniqueId,
  };
}

export type AllVisibilityRules =
  | { visibility: VisibilityType.VisibleToEveryone; visibilityConfig: [] }
  | {
      visibility: VisibilityType.NoVisibility;
      visibilityConfig: [];
    }
  | {
      visibility: VisibilityType.CustomVisibility;
      visibilityConfig: VisibilityRule[];
    };

export const useVisibilityForm = () => {
  const FormRenderer = ({
    values,
    handleBlur,
    handleChange,
    setFieldValue,
  }: FormikProps<AllVisibilityRules>) => {
    const classes = useStyles();

    const handleVisibility = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (isVisibilityType(event.target.value)) {
        switch (event.target.value) {
          case VisibilityType.VisibleToEveryone:
            setFieldValue('visibilityConfig', []);
            break;
          case VisibilityType.NoVisibility:
            setFieldValue('visibilityConfig', [
              {
                ruleType: VisibilityRuleType.NoVisibility,
              },
            ]);
            break;
          case VisibilityType.CustomVisibility:
            setFieldValue('visibilityConfig', [getDefaultVisibilityRule()]);
            break;
          default:
            ensureUnreachable(event.target.value);
        }

        handleChange(event);
      }
    };

    return (
      <div className={classes.root}>
        <Callout hideIcon className={classes.calloutContainer}>
          <div className={classes.calloutInfo}>
            <InformationCircleIcon className={classes.calloutIcon} />
            <BaseTypography className={classes.descriptionText}>
              By default, all clients can see this App. To only show this App to
              a subset of clients, select 'custom visibility' and specify how
              you want to filter which clients can see this app. You can select
              clients directly, companies, or filter by custom field values.
            </BaseTypography>
          </div>
        </Callout>
        <div>
          <div className={classes.title}>
            <BaseTypography fontType="13Medium" textColor={DarkFont}>
              Visibility
            </BaseTypography>
          </div>
          <BaseTextField
            fullWidth
            select
            sizeVariant="medium"
            variant="outlined"
            onChange={handleVisibility}
            onBlur={handleBlur}
            value={values.visibility}
            name="visibility"
            defaultValue={VisibilityType.VisibleToEveryone}
          >
            <MenuItem value={VisibilityType.VisibleToEveryone}>
              Visible to everyone
            </MenuItem>
            <MenuItem value={VisibilityType.NoVisibility}>
              Visible to no clients
            </MenuItem>
            <MenuItem value={VisibilityType.CustomVisibility}>
              Custom visibility
            </MenuItem>
          </BaseTextField>
        </div>
        {values.visibility === VisibilityType.CustomVisibility && (
          <div className={classes.rulesContainer}>
            <RowDivider mb={0} mt={0} />
            <FieldArray name="visibilityConfig">
              {({ push, remove }) => (
                <>
                  {values.visibilityConfig.map((rule, index) => {
                    if (rule.ruleType === VisibilityRuleType.NoVisibility) {
                      return null;
                    }

                    const { ruleKey } = rule;
                    return (
                      <div key={`visibility-rule-${ruleKey}`}>
                        <BaseTypography
                          fontType="13Medium"
                          textColor={DarkFont}
                        >
                          {index === 0 ? 'Show if' : 'OR'}
                        </BaseTypography>
                        <AppVisibilityRule
                          ruleIndex={index}
                          onDeleteRule={() => {
                            remove(index);
                          }}
                        />
                      </div>
                    );
                  })}

                  <div>
                    <Button
                      className={classes.addRuleBtn}
                      onClick={() => {
                        if (values.visibilityConfig.length < 10) {
                          push(getDefaultVisibilityRule());
                        }
                      }}
                    >
                      Add OR rule
                    </Button>
                  </div>
                </>
              )}
            </FieldArray>
          </div>
        )}
      </div>
    );
  };
  return {
    FormRenderer,
    validationScheme: toFormikValidationSchema(rulesSchema),
  };
};

export const AppVisibilityForm: React.FC<Props> = ({
  onCloseModal,
  open,
  moduleVisbilityConfig,
  onVisibilityConfigSaved,
}) => {
  // If a module has a visibility config, it's visibility should be set to custom
  const initialFormValues = useMemo((): AllVisibilityRules => {
    if (moduleVisbilityConfig.length > 0) {
      if (
        moduleVisbilityConfig.at(0)?.ruleType ===
        VisibilityRuleType.NoVisibility
      ) {
        return {
          visibility: VisibilityType.NoVisibility,
          visibilityConfig: [],
        };
      }
      return {
        visibility: VisibilityType.CustomVisibility,
        visibilityConfig: moduleVisbilityConfig.map((rule) => ({
          ...rule,
          ruleKey: generateUniqueId(),
        })),
      };
    }
    return {
      visibility: VisibilityType.VisibleToEveryone,
      visibilityConfig: [],
    };
  }, [moduleVisbilityConfig]);

  const handleClose = () => {
    onCloseModal();
  };

  const handleSaveAppVisibility = async (values: AllVisibilityRules) => {
    onVisibilityConfigSaved(values);
  };

  return (
    <FormModal<AllVisibilityRules>
      key="visibility-modal"
      modalProps={{
        width: 800,
        disableGutters: true,
        open,
      }}
      initialFormValue={initialFormValues}
      title="Visibility"
      handleSave={handleSaveAppVisibility}
      onClose={handleClose}
      useFormHook={useVisibilityForm}
      open={open}
    />
  );
};
