import { unionBy } from 'lodash';
import {
  AlertVariant,
  AuthActionTypes,
  CLEAR_BANNER_DATA,
  CLEAR_PAGE_CHANGES,
  CLEAR_SNACKBAR_ALERT,
  COMPLETE_PROGRESS,
  CONTROL_LOGIN_MODAL,
  PAGE_RELOADING,
  RESET_PROGRESS_VALUE,
  SET_BANNER_DATA,
  SET_CAN_SUBMIT_FORM_PAGE,
  SET_FORM_PAGE_CHANGED,
  SET_PAGE_CHANGE_ERRORS,
  SET_PAGE_CHANGES,
  SET_SECONDARY_SIDEBAR_HIDDEN,
  SET_PROGRESS_VALUE,
  SNACKBAR_ALERT,
  TOGGLE_AUTO_SIGNIN,
  TOGGLE_HELP_CENTER_MODAL,
  TOGGLE_PRIMARY_SIDEBAR_MOBILE,
  TOGGLE_RIGHT_SIDEBAR,
  TOGGLE_SECONDARY_SIDEBAR,
  UiState,
  UN_SET_PAGE_CHANGES,
  UPDATE_SEARCH_VALUE,
  SAVE_BACK_TO_PORTAL_PAGE,
  SET_AGREE_PLAN_CANCEL_TERMS,
} from 'src/store/ui/types';

const initialState: UiState = {
  authType: null,
  nextPath: '',
  alertMessage: '',
  primarySideBarMobile: {
    isOpen: false,
  },
  secondarySideBar: {
    isOpen: true, // this is used to decided if the sidebar gets shown at all
    info: '',
  },
  helpCenterModal: {
    isOpen: false,
  },
  pageChanges: [],
  pageChangeErrors: {},
  isReloading: false,
  isFormPageChanged: false,
  progressValue: 0,
  isProgressComplete: false,
  isAutoSignIn: false,
  rightSideBar: {
    isOpen: false,
  },
  searchValue: '',
  canSubmitFormPage: false,
  backToPortalDestinationPath: '/',
  agreePlanCancelTerms: false,
};

// eslint-disable-next-line default-param-last
const uiReducer = (state = initialState, action: AuthActionTypes) => {
  switch (action.type) {
    case CONTROL_LOGIN_MODAL: {
      const { authType, nextPath } = action.payload;
      return {
        ...state,
        authType,
        nextPath,
      };
    }

    case SNACKBAR_ALERT: {
      const { alertMessage, alertMessageType } = action;
      return {
        ...state,
        alertMessageType: alertMessageType || AlertVariant.ERROR,
        alertMessage,
      };
    }

    case CLEAR_SNACKBAR_ALERT: {
      return {
        ...state,
        alertMessageType: '',
        alertMessage: '',
      };
    }

    case TOGGLE_PRIMARY_SIDEBAR_MOBILE: {
      return {
        ...state,
        primarySideBarMobile: {
          ...state.primarySideBarMobile,
          ...action.payload,
        },
      };
    }

    case TOGGLE_SECONDARY_SIDEBAR: {
      return {
        ...state,
        secondarySideBar: { ...state.secondarySideBar, ...action.payload },
      };
    }

    case SET_SECONDARY_SIDEBAR_HIDDEN: {
      return {
        ...state,
        secondarySideBar: { ...state.secondarySideBar, hidden: action.payload },
      };
    }

    case TOGGLE_RIGHT_SIDEBAR: {
      return {
        ...state,
        rightSideBar: { ...state.rightSideBar, ...action.payload },
      };
    }

    case CLEAR_PAGE_CHANGES: {
      return {
        ...state,
        pageChanges: [],
        isFormPageChanged: false,
      };
    }

    case SET_PAGE_CHANGES: {
      const { payload } = action;
      return {
        ...state,
        pageChanges: unionBy(payload, state.pageChanges, 'name'),
      };
    }

    case UN_SET_PAGE_CHANGES: {
      const { payload } = action;
      return {
        ...state,
        pageChanges: state.pageChanges.filter(
          (pageChange) => !payload.includes(pageChange.name),
        ),
      };
    }

    case SET_FORM_PAGE_CHANGED: {
      const { payload } = action;
      return {
        ...state,
        isFormPageChanged: payload,
      };
    }

    case SET_PAGE_CHANGE_ERRORS: {
      const { payload } = action;
      return {
        ...state,
        pageChangeErrors: payload,
      };
    }

    case PAGE_RELOADING: {
      return {
        ...state,
        isReloading: true,
      };
    }

    case SET_PROGRESS_VALUE: {
      const { payload } = action;
      return {
        ...state,
        progressValue: payload,
        isProgressComplete: false,
      };
    }

    case COMPLETE_PROGRESS: {
      return {
        ...state,
        isProgressComplete: true,
      };
    }

    case RESET_PROGRESS_VALUE: {
      return {
        ...state,
        progressValue: 0,
        isProgressComplete: false,
      };
    }

    case TOGGLE_AUTO_SIGNIN: {
      return {
        ...state,
        isAutoSignIn: action.payload,
      };
    }

    case UPDATE_SEARCH_VALUE: {
      return {
        ...state,
        searchValue: action.payload,
      };
    }

    case SET_BANNER_DATA: {
      return {
        ...state,
        bannerOptions: action.payload,
      };
    }

    case CLEAR_BANNER_DATA: {
      return {
        ...state,
        bannerOptions: undefined,
      };
    }

    case TOGGLE_HELP_CENTER_MODAL: {
      return {
        ...state,
        helpCenterModal: {
          ...state.helpCenterModal,
          isOpen: action.payload,
        },
      };
    }

    case SET_CAN_SUBMIT_FORM_PAGE: {
      return {
        ...state,
        canSubmitFormPage: action.payload,
      };
    }

    case SAVE_BACK_TO_PORTAL_PAGE: {
      return {
        ...state,
        backToPortalDestinationPath: action.payload,
      };
    }

    case SET_AGREE_PLAN_CANCEL_TERMS: {
      return {
        ...state,
        agreePlanCancelTerms: action.payload,
      };
    }
    default:
      return state;
  }
};

export default uiReducer;
