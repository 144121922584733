import { FieldVisibility, TablePropertyFields } from 'src/constants/dataTypes';

export const DefaultInvoicesColumnSettings: TablePropertyFields = {
  tablePropertyFields: [
    {
      id: 'recipient',
      name: 'Recipient',
      disabled: false,
    },
    {
      id: 'total',
      name: 'Amount',
      disabled: false,
    },
    {
      id: 'status',
      name: 'Status',
      disabled: false,
    },
    {
      id: 'number',
      name: 'Invoice number',
      disabled: false,
    },
    {
      id: 'createdAt',
      name: 'Created',
      disabled: false,
    },
    {
      id: 'dueDate',
      name: 'Due',
      disabled: false,
    },
    {
      id: 'payment',
      name: 'Payment',
      disabled: false,
    },
    {
      id: 'type',
      name: 'Type',
      disabled: true,
    },
    {
      id: 'paymentFee',
      name: 'Payment fees',
      disabled: true,
    },
    {
      id: 'clientPaymentFee',
      name: 'Client payment fees',
      disabled: true,
    },
    {
      id: 'currency',
      name: 'Currency',
      disabled: true,
    },
    {
      id: 'memo',
      name: 'Memo',
      disabled: true,
    },
    {
      id: 'taxPercentage',
      name: 'Tax percent',
      disabled: true,
    },
  ] as FieldVisibility[],
};
