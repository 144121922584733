import React from 'react';

import BaseTypography from 'src/components/Text/BaseTypography';
import Button from 'src/components/Button';
import { InfoOutlined } from '@material-ui/icons';
import history from 'src/history';
import { useAppSelector } from 'src/hooks/useStore';
import { Chart } from 'src/components/HomePage/AnalyticsSection/Chart';
import { ChartData } from 'src/types/analytics';

const MAX_CLIENTS = 100;

function getRandomInt(minBoundary: number, maxBoundary: number): number {
  const minInt = Math.ceil(minBoundary);
  const maxInt = Math.floor(maxBoundary);
  return Math.floor(Math.random() * (maxInt - minInt + 1)) + minInt;
}

const today = new Date();

// To display the empty state, we generate a set of randomized
// data points for the last 30 days. Here's where that generation
// is done. A lot of this is just fine tuning to create the UI we
// want, that's the only requirement of this data is to look
// reasonable as a curve.
//
// Honestly, this whole thing could be replaced with a static data
// set or even an SVG. But this seemed fun.
const data: ChartData[] = new Array(30)
  .fill(null)
  .reduce<ChartData[]>((acc, _, i) => {
    // Keep the previous total in memory, we'll use that for the current day total.
    const prevTotal = acc[acc.length - 1]?.total ?? 0;

    // We have to generate a number of added clients.
    let added = 0;

    // This check makes sure we start at 0, it's intended to skiip
    // this block on the first loop.
    if (i > 0) {
      if (i === 1) {
        // The random number we generate can be 0 and that creates
        // the possibility of having no data points on the chart.
        // A simple hack is to make sure clients are added on day 2.
        added = 3;
      } else if (i === 29) {
        // On the last day we want to make sure we reach 100 clients.
        added = 100 - prevTotal;
      } else {
        // Otherwise, let's just generate a random number of clients,
        // but make sure we don't go over the max.
        added = getRandomInt(2, Math.min(12, MAX_CLIENTS - prevTotal));
      }
    }

    // Removed is quite a bit simpler, we just want to make sure we
    // don't go below 0 clients. Added tends to be higher than removed
    // because we want 📈
    const removed = getRandomInt(0, Math.min(6, prevTotal - 1));

    // Generate a date stamp for the day.
    const date = new Date(today);
    date.setDate(today.getDate() - (30 - i));

    acc.push({
      date: date,
      total: prevTotal + added - removed,
    });

    return acc;
  }, []);

export function EmptyState() {
  const clientUsers = useAppSelector((state) => state.clients.clients);

  return (
    <>
      <header className="pb-6 flex flex-row items-center">
        {/* Unfortunately !important is needed to override MUI styles. */}
        <InfoOutlined className="!w-4 !h-4 pr-1.5" />
        <BaseTypography
          fontType="14Regular"
          className="leading-none flex-1 text-gray-500"
        >
          Metrics will show after you create 10 clients ({clientUsers.length}
          /10 created so far)
        </BaseTypography>
        <Button
          onClick={() => history.push('/clients/users')}
          className="px-2 py-1 text-sm border border-solid border-gray-300 rounded"
          htmlId={'analytics-go-to-crm'}
        >
          Go to CRM
        </Button>
      </header>

      <div className="text-gray-300">
        <Chart data={data} />
      </div>
    </>
  );
}
