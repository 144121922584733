import React, { ReactNode } from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import { getIn, useFormikContext } from 'formik';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { NonHoverBorder, red } from 'src/theme/colors';
import { RegularCardBox } from 'src/components/Cards/RegularCardBox';
import RowDivider from 'src/components/RowDivider';
import BaseTypography from 'src/components/Text/BaseTypography';
import {
  BaseTextField,
  ResizableTextField,
  BaseTextFieldProps,
} from 'src/components/TextField';
import { InputsTypes, QuestionAnswer } from 'src/components/FormsV2/formsTypes';
import { MultiChoicesSelectInput } from 'src/components/FormsV2/MultiChoicesSelectInput';
import { QuestionFileDropzone } from 'src/components/FormsV2/QuestionFileDropzone';
import { BaseChip, SeverityLevel } from 'src/components/UI';
import { RootState } from 'src/store';
import { AsteriskIcon } from 'src/components/FormsV2/AsteriskIcon';
import { RichTextDescription } from 'src/components/FormsV2/RichTextDescription';
import { useDebounceInput } from 'src/hooks/useDebounceInput';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    padding: '24px',
    border: `1px solid ${NonHoverBorder}`,
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  titleContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: theme.spacing(1),
  },
  // when question is required and the question is not answered, the question border will be red.
  redBorderCard: {
    border: `1px solid ${red}`,
  },
  // disabled input hover state border override
  disabledInput: {
    '&:hover  fieldset': {
      borderColor: `${NonHoverBorder} !important`,
    },
  },
}));

/**
 * @param questionConfigData: {QuestionType} The question configuration data.
 * @param questionIndex: {number} The question index is used to determine the question field values/errors states in the form.
 */
type QuestionAnswerCardProps = {
  questionAnswerData: QuestionAnswer;
  questionIndex: number;
  formId: string;
};

interface QuestionAnswerCardHeaderProps {
  title: ReactNode;
  description: string;
  type: InputsTypes;
  showRequiredAsterisk: boolean;
  showRequiredError: boolean;
  isQuestionEdited?: boolean;
}

/**
 * This component is responsible of rendering the question answer card header.
 * @param title: {string} The question title.
 * @param description: {string} The question description.
 * @param questionType: {InputsTypes} The question type. Used to determine the font type.
 * @returns
 */
export const QuestionAnswerCardHeader = ({
  title,
  description,
  type: questionType,
  showRequiredAsterisk,
  showRequiredError,
  isQuestionEdited,
}: QuestionAnswerCardHeaderProps) => {
  const classes = useStyles();
  const isTitleCard = questionType === InputsTypes.Title;
  const titleFontType = isTitleCard ? '24Medium' : '15Medium';
  const isClient = useSelector((state: RootState) => state.user.isClient);

  return (
    <div className={classes.header}>
      <div>
        <div className={classes.titleContainer}>
          <BaseTypography fontType={titleFontType}>{title} </BaseTypography>
          {/* show question deleted badge for internal user only  */}
          {!isClient && isQuestionEdited && (
            <BaseChip label="Question Edited" severity={SeverityLevel.high} />
          )}
        </div>
        <RichTextDescription description={description} />
      </div>

      <span>
        {showRequiredError && (
          <BaseTypography
            fontType="13Regular"
            component="span"
            style={{
              color: red,
              marginRight: '4px',
            }}
          >
            Input required
          </BaseTypography>
        )}
        {showRequiredAsterisk && (
          // when the question is required, a red asterisk is displayed
          <AsteriskIcon
            style={{
              fontSize: '12px',
            }}
          />
        )}
      </span>
    </div>
  );
};

const TextInputsTypes = [InputsTypes.ShortAnswer, InputsTypes.LongAnswer];
export const MultiChoicesInputsTypes = [
  InputsTypes.SingleSelect,
  InputsTypes.MultiSelect,
];
const TextInputTypeToComponent = {
  [InputsTypes.ShortAnswer]: BaseTextField,
  [InputsTypes.LongAnswer]: ResizableTextField,
};

type QuestionAnswerInputProps = {
  type: InputsTypes;
  options?: string[]; // used for multi choices input types
  answerType?: 'single' | 'multi'; // used for multi choices input types
  questionId: string;
  readOnly?: boolean;
  identityId?: string;
} & Partial<BaseTextFieldProps>;

const isTextType = (
  type: InputsTypes,
): type is InputsTypes.ShortAnswer | InputsTypes.LongAnswer =>
  TextInputsTypes.includes(type);

/**
 * This component is responsible of rendering the question answer input.
 * Based on the question type different input components are rendered.
 * e.g. for a question of type 'text' a text input component is rendered.
 * @returns {JSX.Element}  The question answer input.
 */
export const QuestionAnswerInput = ({
  name,
  type: questionType,
  value,
  onChange,
  onBlur,
  error,
  helperText,
  options = [],
  questionId,
  readOnly = false,
  identityId,
  formId,
}: QuestionAnswerInputProps & {
  formId?: string;
}) => {
  const inputComponent = React.useMemo(() => {
    // if the question type is a text input type, render the appropriate input component
    // which would be either a BaseTextField or a ResizableTextField.
    if (isTextType(questionType)) {
      return (
        <TextField
          type={questionType}
          value={typeof value === 'string' ? value : ''}
          onChange={onChange ?? (() => {})}
          onBlur={onBlur}
          name={name}
          readOnly={readOnly}
        />
      );
    }

    // if the question type is a multi choices input type (single select or multi select)
    if (MultiChoicesInputsTypes.includes(questionType)) {
      return (
        <MultiChoicesSelectInput
          options={options}
          type={questionType}
          onChange={onChange}
          onBlur={onBlur}
          name={name}
          value={value as string[]}
          readOnly={readOnly}
        />
      );
    }

    if (questionType === InputsTypes.FileUpload) {
      return (
        <QuestionFileDropzone
          questionId={questionId}
          fieldName={name || ''}
          value={value as string | string[]}
          readOnly={readOnly}
          identityId={identityId}
          formId={formId}
        />
      );
    }

    return null;
  }, [questionType, value, error, helperText, readOnly, options]);

  return inputComponent;
};

const TextField = ({
  name,
  value,
  onChange,
  onBlur,
  readOnly,
  type,
}: Required<Pick<QuestionAnswerInputProps, 'onChange'>> &
  Pick<QuestionAnswerInputProps, 'name' | 'onBlur' | 'readOnly'> & {
    value: string;
    type: InputsTypes.LongAnswer | InputsTypes.ShortAnswer;
  }) => {
  const classes = useStyles();
  const InputComponent = TextInputTypeToComponent[type];
  const [val, handleChange] = useDebounceInput(value, onChange);

  return (
    <InputComponent
      value={val}
      fullWidth
      variant="outlined"
      onChange={handleChange}
      onBlur={onBlur}
      name={name}
      sizeVariant="medium"
      placeholder="Click here to input..."
      disabled={readOnly}
      InputProps={{
        readOnly,
        classes: {
          disabled: classes.disabledInput,
        },
      }}
    />
  );
};

/**
 * This component is responsible of rendering the question answers/responses
 * card. This is used in the form response submission page as well as the
 * form response view page.
 * @returns {JSX.Element}  The question answer card.
 */
export const QuestionAnswerCard = ({
  questionAnswerData,
  questionIndex,
  formId,
}: QuestionAnswerCardProps) => {
  const classes = useStyles();
  const { values, handleChange, handleBlur, errors, touched } =
    useFormikContext<{
      questionAnswers: QuestionAnswer[];
    }>();
  const {
    questionId,
    title: questionTitle,
    description,
    type: questionType,
    isRequired,
    options,
  } = questionAnswerData;
  const touch = getIn(
    touched,
    `questionAnswers[${questionIndex}].responseValue`,
  );
  const error = getIn(
    errors,
    `questionAnswers[${questionIndex}].responseValue`,
  );
  const showRequiredError = isRequired && touch && error;
  return (
    <RegularCardBox
      className={clsx(classes.root, {
        [classes.redBorderCard]: showRequiredError,
      })}
    >
      <QuestionAnswerCardHeader
        title={questionTitle}
        description={description}
        type={questionType}
        showRequiredAsterisk={isRequired}
        showRequiredError={showRequiredError}
      />
      {questionType !== InputsTypes.Title && (
        <RowDivider mt={2.5} mb={0} noLine />
      )}
      <QuestionAnswerInput
        questionId={questionId}
        type={questionType}
        value={values.questionAnswers?.[questionIndex]?.responseValue}
        onChange={handleChange}
        onBlur={handleBlur}
        name={`questionAnswers[${questionIndex}].responseValue`}
        options={options} // used for multi/single choices input types
        formId={formId}
      />
    </RegularCardBox>
  );
};
