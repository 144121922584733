import React from 'react';
import { AutocompleteRenderGroupParams } from '@material-ui/lab/Autocomplete';
import ListSubheader from '@material-ui/core/ListSubheader';
import { VariableSizeList, ListChildComponentProps } from 'react-window';
import { makeStyles } from '@material-ui/core';
import { GraySmall } from 'src/theme/colors';
import { typography12MediumStyle } from 'src/components/Text';

const useStyles = makeStyles(() => ({
  listbox: {
    '& .MuiListSubheader-root ': {
      color: GraySmall,
      ...typography12MediumStyle,
    },
    // reduce the height of the options
    '& .MuiAutocomplete-option': {
      minHeight: 'auto',
    },
  },
}));

const LISTBOX_PADDING = 6; // px

function renderRow(props: ListChildComponentProps) {
  const { data, index, style } = props;
  return React.cloneElement(data[index], {
    style: {
      ...style,
      top: (style.top as number) + LISTBOX_PADDING,
    },
  });
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef<HTMLDivElement>((props, ref) => {
  const outerProps = React.useContext(OuterElementContext);
  return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data: any) {
  const ref = React.useRef<VariableSizeList>(null);
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true);
    }
  }, [data]);
  return ref;
}

export const GroupListboxComponent = React.forwardRef<HTMLDivElement>(
  (props, ref) => {
    const { children, ...other } = props;
    const itemData = React.Children.toArray(children);
    const itemCount = itemData.length;
    const itemSize = 34;
    const classes = useStyles();

    const getChildSize = (child: React.ReactNode) => {
      if (React.isValidElement(child) && child.type === ListSubheader) {
        return 34;
      }

      return itemSize;
    };

    const getHeight = () => {
      if (itemCount > 8) {
        return 8 * itemSize;
      }
      return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);
    return (
      <div ref={ref} className={classes.listbox}>
        <OuterElementContext.Provider value={other}>
          <VariableSizeList
            itemData={itemData}
            height={getHeight()}
            width="100%"
            ref={gridRef}
            outerElementType={OuterElementType}
            innerElementType="ul"
            itemSize={(index) => getChildSize(itemData[index])}
            overscanCount={5}
            itemCount={itemCount}
          >
            {renderRow}
          </VariableSizeList>
        </OuterElementContext.Provider>
      </div>
    );
  },
);
export const RenderGroup = (params: AutocompleteRenderGroupParams) => [
  params.group ? (
    <ListSubheader
      key={params.key}
      component="div"
      className="flex items-center"
    >
      {params.group}
    </ListSubheader>
  ) : null,

  params.children,
];
